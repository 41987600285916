<template>
  <div class="flex justify-between mt-5">
    <div
      :class="{ invisible: !showReportPlan }"
      class="plan-table-wrap rounded-[8px]"
    >
      <div class="plan-table-title bg-white border-tr-8">
        <h2>общий план</h2>
      </div>
      <div class="wrap-pt-grid title-padding-pt bg-green-200">
        <p>Месяц</p>
        <!-- <p>Раб. дни</p> -->
        <p>Маржа, план на месяц,<br />руб</p>
        <p>% маржинальности</p>
        <p>Доход, план на месяц,<br />руб</p>
        <p>Средниц чек,<br />руб</p>
        <p>Кол-во оплаченых<br />счетов</p>
        <p>Кол-во выставленных<br />счетов</p>
        <p>Кол-во заявок</p>
        <p>Кол-во лидов</p>
      </div>
      <div class="wrap-pt-grid data-padding-pt bg-white border-br-8">
        <p>{{ dateTo }}</p>
        <!-- <p>17</p> -->
        <p>{{ addSpace(sumOfPlans) }}</p>
        <p>{{ Math.round(this_month_margin) }}%</p>
        <p>{{ addSpace(sumMonthInvoiceDone) }}</p>
        <p>{{ addSpace(this_month_mean) }}</p>
        <p>{{ addSpace(this_month_invoice_done) }}</p>

        <p>{{ addSpace(sum_month_invoice_all) }}</p>
        <p>{{ addSpace(sum_month_bid) }}</p>
        <p>{{ addSpace(sum_month_lead) }}</p>
      </div>
    </div>

    <div class="time-wrap flex flex-col ml-12 w-[260px]">
      <input
        type="month"
        class="month-report-top py-2 pl-8 pr-2 mb-5"
        v-model="dateTo"
      />
      <button
        v-if="dateTo"
        @click="showPlan()"
        class="button-form-report bg-green-500"
      >
        Показать план
      </button>

      <button
        v-if="showReportPlan"
        @click="CopyThisPlan()"
        class="button-form-report bg-blue-400 my-2"
      >
        Скопировать<br />на следующий месяц
      </button>
    </div>
  </div>

  <div v-if="showReportPlan" class="plan-table-wrap mt-8">
    <div class="plan-table-title border-tr-8 bg-white">
      <h2>индивидуальные планы</h2>
    </div>
    <div class="w-full bg-green-200">
      <div class="wrap-plant-grid">
        <div class="wrap-semi-th title-col">
          <p></p>
          <p>Менеджер</p>
          <p>Маржа, план, руб</p>
        </div>

        <div class="wrap-semi-th">
          <div class="top-th">Тахографы</div>
          <div class="bottom-th">
            <p>Маржа, руб</p>
            <p>% MR</p>
            <p>Средний чек, руб</p>
          </div>
        </div>
        <div class="wrap-semi-th">
          <div class="top-th">Мониторинг транспорта</div>
          <div class="bottom-th">
            <p>Маржа, руб</p>
            <p>% MR</p>
            <p>Средний чек, руб</p>
          </div>
        </div>
        <div class="wrap-semi-th">
          <div class="top-th">Прочее</div>
          <div class="bottom-th">
            <p>Маржа, руб</p>
            <p>% MR</p>
            <p>Средний чек, руб</p>
          </div>
        </div>
      </div>

      <div
        v-for="planRow in localPlanReportTable"
        :key="planRow"
        class="wrap-plant-grid"
      >
        <div class="row-ft-data title-col flex bg-white">
          <div class="images-row flex items-center justify-start px-3">
            <img
              @click="delThisRowPlan(planRow)"
              class="cursor-pointer mr-3"
              src="@/assets/delete.svg"
              alt=""
            />
            <img class="invisible" src="@/assets/add.svg" alt="" />
          </div>
          <div class="user-row flex items-center">
            <img
              :class="{ hidden: planRow.approved == 1 }"
              title="План не утвержден!"
              src="@/assets/warning.svg"
              alt=""
            />
            <img
              :class="{ hidden: planRow.approved == 0 }"
              title="План на данного менеджера утвержден"
              src="@/assets/galochka.svg"
              alt=""
            />

            <img v-if="error" src="@/assets/error.svg" alt="" />
            <div class="flex relative group p-1 pl-8">
              <div
                v-if="planRow.profile.name"
                @click="showUserReportMRT(planRow.profile)"
                class="flex justify-between"
              >
                <div class="flex items-center cursor-pointer group">
                  <div
                    class="flex flex-col justify-center overflow-hidden pr-8"
                  >
                    <span
                      class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
                      >{{ planRow.profile?.name }}
                      {{ planRow.profile?.secondname }}</span
                    >
                  </div>
                </div>
              </div>

              <template v-else>
                <div class="flex items-center w-full">
                  <div class="relative min-w-[120px]">
                    <input
                      readonly
                      type="text"
                      v-model.trim="text_manager"
                      class="bg-white h-9 px-3 py-1 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                      @input="findManager()"
                      @click="findManager()"
                    />
                    <hr />
                    <div
                      v-if="managerList.length"
                      class="absolute w-full top-full bg-white left-0 border-2 border-green-800 border-t-0 z-10"
                    >
                      <div
                        v-for="m in managerList"
                        :key="m"
                        @click="setManager(m)"
                        class="px-4 py-1 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                      >
                        <span>{{ m.secondname }} {{ m.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="money-row">
            <p>
              {{ addSpace(planRow.month_plan) }}
            </p>
          </div>
        </div>

        <div class="row-pt-data bg-white">
          <p class="px-2">{{ addSpace(planRow.taho_plan) }}</p>
          <p class="px-2">{{ addSpace(planRow.taho_margin) }}</p>
          <p class="px-2">{{ addSpace(planRow.taho_mean) }}</p>
        </div>

        <div class="row-pt-data bg-white">
          <p class="px-2">{{ addSpace(planRow.monitor_plan) }}</p>
          <p class="px-2">{{ addSpace(planRow.monitor_margin) }}</p>
          <p class="px-2">{{ addSpace(planRow.monitor_mean) }}</p>
        </div>

        <div class="row-pt-data bg-white">
          <p class="px-2">{{ addSpace(planRow.other_marge) }}</p>
          <p class="px-2">{{ addSpace(planRow.other_proc) }}</p>
          <p class="px-2">{{ addSpace(planRow.other_middle) }}</p>
        </div>
      </div>

      <div class="wrap-plant-grid">
        <div class="row-ft-data title-col flex bg-white">
          <div class="images-row flex items-center justify-start px-3">
            <img class="invisible mr-3" src="@/assets/delete.svg" alt="" />
            <img
              class="cursor-pointer"
              @click="addThisRowPlan()"
              src="@/assets/add.svg"
              alt=""
            />
          </div>
          <div class="user-row flex items-center">
            <img class="invisible" src="@/assets/warning.svg" alt="" />
            <img class="hidden" src="@/assets/error.svg" alt="" />
            <div class="flex relative group p-1 pl-8 v-bbgray">
              <div v-if="choosenManager.name" class="flex justify-between">
                <div class="flex items-center cursor-pointer group">
                  <div
                    class="flex flex-col justify-center overflow-hidden pr-8"
                  >
                    <span
                      class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
                      >{{ choosenManager?.name }}
                      {{ choosenManager?.secondname }}</span
                    >
                  </div>
                </div>
                <div
                  class="absolute top-1/2 -translate-y-1/2 right-4 opacity-0 group-hover:opacity-100 transition-all duration-150"
                >
                  <a class="group cursor-pointer" @click="choosenManager = {}">
                    <svg
                      class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a>
                </div>
              </div>

              <template v-else>
                <div class="flex items-center w-full">
                  <div class="relative min-w-[160px]">
                    <input
                      readonly
                      type="text"
                      v-model.trim="text_manager"
                      class="bg-white h-9 px-3 py-1 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                      @input="findManager()"
                      @click="findManager()"
                    />
                    <hr />
                    <div
                      v-if="managerList.length"
                      class="absolute w-full top-full bg-white left-0 border-2 border-green-800 border-t-0 z-10"
                    >
                      <div
                        v-for="m in managerList"
                        :key="m"
                        @click="setManager(m)"
                        class="px-4 py-1 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                      >
                        <span>{{ m.secondname }} {{ m.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="money-row invisible">
            <span></span>
          </div>
        </div>

        <div class="row-pt-data bg-white">
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div class="row-pt-data bg-white">
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div class="row-pt-data bg-white">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div class="wrap-plant-grid">
        <div class="row-ft-data title-col flex bg-white">
          <div
            class="images-row invisible flex items-center justify-start px-3"
          >
            <img class="invisible mr-3" src="@/assets/delete.svg" alt="" />
            <img @click="addThisRowPlan()" src="@/assets/add.svg" alt="" />
          </div>
          <div class="user-row flex invisible items-center">
            <img class="invisible" src="@/assets/warning.svg" alt="" />
            <img class="hidden" src="@/assets/error.svg" alt="" />
            <div class="flex relative group p-1 px-4 v-bbgray">
              <div v-if="choosenManager.name" class="flex justify-between">
                <div class="flex items-center cursor-pointer group">
                  <div
                    class="flex flex-col justify-center overflow-hidden pr-8"
                  >
                    <span
                      class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
                      >{{ choosenManager?.name }}s
                      {{ choosenManager?.secondname }}</span
                    >
                  </div>
                </div>
              </div>

              <template v-else>
                <div class="flex items-center w-full">
                  <div class="relative min-w-[120px]">
                    <input
                      readonly
                      type="text"
                      v-model.trim="text_manager"
                      class="bg-white h-9 px-3 py-1 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                      @input="findManager()"
                      @click="findManager()"
                    />
                    <hr />
                    <div
                      v-if="managerList.length"
                      class="absolute w-full top-full bg-white left-0 border-2 border-green-800 border-t-0 z-10"
                    >
                      <div
                        v-for="m in managerList"
                        :key="m"
                        @click="setManager(m)"
                        class="px-4 py-1 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                      >
                        <span>{{ m.secondname }} {{ m.name }}</span>
                        <span class="text-xs">{{ m.uid }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="money-row invisible">
            <span></span>
          </div>
        </div>

        <div class="row-pt-data bg-white">
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div class="row-pt-data bg-white">
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div class="row-pt-data bg-white">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="showReportMRT"
    id="popnew"
    class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-y-auto overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
  >
    <ReportPlanManagerTable
      :manager_id_prop="choosenManagerMRTId"
      :date_prop="dateTo"
      @closePopup="closePoputMRT()"
    />
  </div>
  <div
    v-if="showReportMRT"
    @click="closePoputMRT()"
    id="overlay"
    class="fixed bg-cyan-900 z-2 w-screen h-screen top-0 left-0 opacity-80"
  ></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ReportPlanManagerTable from "@/components/reports/ReportPlanManagerTable.vue";

export default {
  name: "PlansPage",
  components: {
    // DiffRomb,
    ReportPlanManagerTable,
  },
  data() {
    return {
      dateTo: null,
      dateFrom: null,
      workerStatus: 1,
      choosenManager: {},
      managerList: [],
      localPlanReportTable: [],
      localManagers: [],
      text_manager: "",
      showReportPlan: false,
      warning: false,
      error: false,
      margeMiddle: 0,
      chequeMiddle: 0,
      chequeCount: 0,
      incomePlan: 0,

      sumOfPlans: 0,
      other_numbers: {
        other_marge: 0,
        other_proc: 0,
        other_middle: 0,
      },
      sumMonthInvoiceDone: 0,
      this_month_margin: 0,
      this_month_mean: 0,
      this_month_invoice_done: 0,
      sum_month_invoice_all: 0,
      sum_month_bid: 0,
      sum_month_lead: 0,

      showReportMRT: false,
      choosenManagerMRTId: 0,
    };
  },
  computed: {
    ...mapGetters(["s", "profile", "selectManagers", "planReportTable"]),
  },
  methods: {
    ...mapActions([
      "getPlanReportTable",
      "addRowPlan",
      "changePlan",
      "delRowPlan",
      "copyPlan",
    ]),
    addSpace(strNumber) {
      return String(strNumber).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");
    },
    async CopyThisPlan() {
      if (
        confirm(
          "Вы уверены, что хотите скопировать данный план на следующий месяц?"
        )
      ) {
        this.showReportPlan = false;
        this.localPlanReportTable = {};
        this.sumOfPlans = 0;
        this.margeMiddle = 0;
        this.chequeMiddle = 0;
        this.incomePlan = 0;
        this.chequeCount = 0;
        this.other_numbers = {
          other_marge: 0,
          other_proc: 0,
          other_middle: 0,
        };
        this.sumMonthInvoiceDone = 0;
        this.this_month_margin = 0;
        this.this_month_mean = 0;
        this.this_month_invoice_done = 0;

        await this.copyPlan({ date: this.dateTo });

        console.log("this.planReportTable", this.planReportTable);
        if (this.planReportTable != []) {
          this.localPlanReportTable = Object.assign([], this.planReportTable);
          this.dateFrom = this.localPlanReportTable[0].date_plan;

          for (let i = 0; i < this.localPlanReportTable.length; i++) {
            this.localPlanReportTable[i].other_marge = 0;
            this.localPlanReportTable[i].other_proc = 0;
            this.localPlanReportTable[i].other_middle = 0;
            this.sumMonthInvoiceDone += Number(
              this.localPlanReportTable[i].month_income
            );
            this.this_month_margin += Number(
              this.localPlanReportTable[i].month_margin
            );
            this.this_month_mean += Number(
              this.localPlanReportTable[i].month_mean
            );
            this.this_month_invoice_done += Number(
              this.localPlanReportTable[i].month_invoice_done
            );

            this.sum_month_invoice_all += Number(
              this.localPlanReportTable[i].month_invoice_all
            );
            this.sum_month_bid += Number(
              this.localPlanReportTable[i].month_bid
            );
            this.sum_month_lead += Number(
              this.localPlanReportTable[i].month_lead
            );

            this.localPlanReportTable[i].other_marge +=
              Number(this.localPlanReportTable[i].card_plan) +
              Number(this.localPlanReportTable[i].propusk_plan) +
              Number(this.localPlanReportTable[i].teach_plan) +
              Number(this.localPlanReportTable[i].driver_plan);

            this.localPlanReportTable[i].other_proc +=
              Number(this.localPlanReportTable[i].card_margin) +
              Number(this.localPlanReportTable[i].propusk_margin) +
              Number(this.localPlanReportTable[i].teach_margin) +
              Number(this.localPlanReportTable[i].driver_margin);

            this.localPlanReportTable[i].other_middle +=
              Number(this.localPlanReportTable[i].card_mean) +
              Number(this.localPlanReportTable[i].propusk_mean) +
              Number(this.localPlanReportTable[i].teach_mean) +
              Number(this.localPlanReportTable[i].driver_mean);

            this.localPlanReportTable[i].other_proc = Math.round(
              Number(this.localPlanReportTable[i].other_proc / 4)
            );

            this.localPlanReportTable[i].other_middle = Math.round(
              Number(this.localPlanReportTable[i].other_middle / 4)
            );
          }

          this.this_month_margin = Math.round(
            Number(this.this_month_margin) /
              Number(this.localPlanReportTable.length)
          );
          this.this_month_mean = Math.round(
            Number(this.this_month_mean) /
              Number(this.localPlanReportTable.length)
          );

          for (let i = 0; i < this.localPlanReportTable.length; i++) {
            this.sumOfPlans += Number(this.localPlanReportTable[i].month_plan);
            this.margeMiddle +=
              Number(this.localPlanReportTable[i].taho_margin) +
              Number(this.localPlanReportTable[i].monitor_margin) +
              Number(this.localPlanReportTable[i].other_margin) +
              Number(this.localPlanReportTable[i].card_margin) +
              Number(this.localPlanReportTable[i].propusk_margin) +
              Number(this.localPlanReportTable[i].teach_margin) +
              Number(this.localPlanReportTable[i].driver_margin);

            this.chequeMiddle +=
              Number(this.localPlanReportTable[i].taho_mean) +
              Number(this.localPlanReportTable[i].monitor_mean) +
              Number(this.localPlanReportTable[i].other_mean) +
              Number(this.localPlanReportTable[i].card_mean) +
              Number(this.localPlanReportTable[i].propusk_mean) +
              Number(this.localPlanReportTable[i].teach_mean) +
              Number(this.localPlanReportTable[i].driver_mean);
          }

          this.margeMiddle =
            this.margeMiddle / (this.localPlanReportTable.length * 7);
          this.chequeMiddle =
            this.chequeMiddle / (this.localPlanReportTable.length * 7);

          this.incomePlan = (this.sumOfPlans * 100) / this.margeMiddle;

          this.chequeCount = this.incomePlan / this.chequeMiddle;
          this.showReportPlan = true;
        }
      }
    },
    showUserReportMRT(user) {
      this.choosenManagerMRTId = user.uid;
      this.showReportMRT = true;
    },

    findManager() {
      console.log("findMaster", this.selectManagers);
      this.managerList = this.selectManagers.filter(
        (item) =>
          item.name.toLowerCase().indexOf(this.text_manager.toLowerCase()) !==
          -1
      );
    },
    async showPlan() {
      this.managerList = [];

      this.showReportPlan = false;
      this.localPlanReportTable = {};
      this.sumOfPlans = 0;
      this.margeMiddle = 0;
      this.chequeMiddle = 0;
      this.incomePlan = 0;
      this.chequeCount = 0;
      this.other_numbers = {
        other_marge: 0,
        other_proc: 0,
        other_middle: 0,
      };
      this.sumMonthInvoiceDone = 0;
      this.this_month_margin = 0;
      this.this_month_mean = 0;
      this.this_month_invoice_done = 0;
      this.sum_month_invoice_all = 0;
      this.sum_month_bid = 0;
      this.sum_month_lead = 0;

      let counter_mean_other = 0;
      let counter_margin_other = 0;

      await this.getPlanReportTable({ date: this.dateTo });
      console.log("this.planReportTable", this.planReportTable);
      if (this.planReportTable != []) {
        this.localPlanReportTable = Object.assign([], this.planReportTable);

        for (let i = 0; i < this.localPlanReportTable.length; i++) {
          this.localPlanReportTable[i].other_marge = 0;
          this.localPlanReportTable[i].other_proc = 0;
          this.localPlanReportTable[i].other_middle = 0;
          this.sumMonthInvoiceDone += Number(
            this.localPlanReportTable[i].month_income
          );
          this.this_month_margin += Number(
            this.localPlanReportTable[i].month_margin
          );
          this.this_month_mean += Number(
            this.localPlanReportTable[i].month_mean
          );
          this.this_month_invoice_done += Number(
            this.localPlanReportTable[i].month_invoice_done
          );
          this.sum_month_invoice_all += Number(
            this.localPlanReportTable[i].month_invoice_all
          );
          this.sum_month_bid += Number(this.localPlanReportTable[i].month_bid);
          this.sum_month_lead += Number(
            this.localPlanReportTable[i].month_lead
          );

          this.localPlanReportTable[i].other_marge +=
            Number(this.localPlanReportTable[i].card_plan) +
            Number(this.localPlanReportTable[i].propusk_plan) +
            Number(this.localPlanReportTable[i].teach_plan) +
            Number(this.localPlanReportTable[i].driver_plan);

          this.localPlanReportTable[i].other_proc +=
            Number(this.localPlanReportTable[i].card_margin) +
            Number(this.localPlanReportTable[i].propusk_margin) +
            Number(this.localPlanReportTable[i].teach_margin) +
            Number(this.localPlanReportTable[i].driver_margin);

          this.localPlanReportTable[i].other_middle +=
            Number(this.localPlanReportTable[i].card_mean) +
            Number(this.localPlanReportTable[i].propusk_mean) +
            Number(this.localPlanReportTable[i].teach_mean) +
            Number(this.localPlanReportTable[i].driver_mean);

          if (this.localPlanReportTable[i].card_mean > 0) {
            counter_mean_other++;
          }
          if (this.localPlanReportTable[i].propusk_mean > 0) {
            counter_mean_other++;
          }
          if (this.localPlanReportTable[i].teach_mean > 0) {
            counter_mean_other++;
          }
          if (this.localPlanReportTable[i].driver_mean > 0) {
            counter_mean_other++;
          }

          if (this.localPlanReportTable[i].card_margin > 0) {
            counter_margin_other++;
          }
          if (this.localPlanReportTable[i].propusk_margin > 0) {
            counter_margin_other++;
          }
          if (this.localPlanReportTable[i].teach_margin > 0) {
            counter_margin_other++;
          }
          if (this.localPlanReportTable[i].driver_margin > 0) {
            counter_margin_other++;
          }

          this.localPlanReportTable[i].other_proc = Math.round(
            Number(
              this.localPlanReportTable[i].other_proc / counter_margin_other
            )
          );

          this.localPlanReportTable[i].other_middle = Math.round(
            Number(
              this.localPlanReportTable[i].other_middle / counter_mean_other
            )
          );
        }

        this.this_month_margin = Math.round(
          Number(this.this_month_margin) /
            Number(this.localPlanReportTable.length)
        );
        this.this_month_mean = Math.round(
          Number(this.this_month_mean) /
            Number(this.localPlanReportTable.length)
        );

        for (let i = 0; i < this.localPlanReportTable.length; i++) {
          this.sumOfPlans += Number(this.localPlanReportTable[i].month_plan);
          this.margeMiddle +=
            Number(this.localPlanReportTable[i].taho_margin) +
            Number(this.localPlanReportTable[i].monitor_margin) +
            Number(this.localPlanReportTable[i].other_margin) +
            Number(this.localPlanReportTable[i].card_margin) +
            Number(this.localPlanReportTable[i].propusk_margin) +
            Number(this.localPlanReportTable[i].teach_margin) +
            Number(this.localPlanReportTable[i].driver_margin);

          this.chequeMiddle +=
            Number(this.localPlanReportTable[i].taho_mean) +
            Number(this.localPlanReportTable[i].monitor_mean) +
            Number(this.localPlanReportTable[i].other_mean) +
            Number(this.localPlanReportTable[i].card_mean) +
            Number(this.localPlanReportTable[i].propusk_mean) +
            Number(this.localPlanReportTable[i].teach_mean) +
            Number(this.localPlanReportTable[i].driver_mean);
        }

        this.margeMiddle =
          this.margeMiddle / (this.localPlanReportTable.length * 7);
        this.chequeMiddle =
          this.chequeMiddle / (this.localPlanReportTable.length * 7);

        this.incomePlan = (this.sumOfPlans * 100) / this.margeMiddle;

        this.chequeCount = this.incomePlan / this.chequeMiddle;
        this.showReportPlan = true;
      }
    },
    setManager(manager) {
      this.choosenManager = manager;
    },
    async delThisRowPlan(thisPlanRow) {
      // choosenManager.name
      await this.delRowPlan({
        manager_id: thisPlanRow.manager_id,
        date: this.dateTo,
      });
      this.showPlan();
    },
    async addThisRowPlan() {
      // choosenManager.name
      if (this.choosenManager.name) {
        console.log(this.choosenManager.uid);
        await this.addRowPlan({
          manager_id: this.choosenManager.uid,
          date: this.dateTo,
        });
        this.showPlan();
        this.choosenManager = {};
      } else {
        alert("Сначала выберите менеджера!");
      }
    },
    closePoputMRT() {
      this.choosenManagerMRTId = null;
      this.showReportMRT = false;
      this.showPlan();
    },
    async changeThisPlan(thisPlanRow, changeValue) {
      this.managerList = [];

      // console.log("planRow before change", thisPlanRow);
      console.log("changeValue", changeValue);
      await this.changePlan({ row: thisPlanRow, date: this.dateFrom });

      this.sumOfPlans = 0;
      this.chequeMiddle = 0;
      this.incomePlan = 0;
      this.margeMiddle = 0;
      this.chequeCount = 0;
      this.this_month_margin = 0;

      this.other_numbers = {
        other_marge: 0,
        other_proc: 0,
        other_middle: 0,
      };
      this.sumMonthInvoiceDone = 0;
      this.this_month_margin = 0;
      this.this_month_mean = 0;
      this.this_month_invoice_done = 0;
      this.sum_month_invoice_all = 0;
      this.sum_month_bid = 0;
      this.sum_month_lead = 0;

      for (let i = 0; i < this.localPlanReportTable.length; i++) {
        this.localPlanReportTable[i].other_marge = 0;
        this.localPlanReportTable[i].other_proc = 0;
        this.localPlanReportTable[i].other_middle = 0;

        this.sumMonthInvoiceDone += Number(
          this.localPlanReportTable[i].month_income
        );
        this.this_month_margin += Number(
          this.localPlanReportTable[i].month_margin
        );
        this.this_month_mean += Number(this.localPlanReportTable[i].month_mean);
        this.this_month_invoice_done += Number(
          this.localPlanReportTable[i].month_invoice_done
        );
        this.sum_month_invoice_all += Number(
          this.localPlanReportTable[i].month_invoice_all
        );
        this.sum_month_bid += Number(this.localPlanReportTable[i].month_bid);
        this.sum_month_lead += Number(this.localPlanReportTable[i].month_lead);

        this.localPlanReportTable[i].other_marge +=
          Number(this.localPlanReportTable[i].card_plan) +
          Number(this.localPlanReportTable[i].propusk_plan) +
          Number(this.localPlanReportTable[i].teach_plan) +
          Number(this.localPlanReportTable[i].driver_plan);

        this.localPlanReportTable[i].other_proc +=
          Number(this.localPlanReportTable[i].card_margin) +
          Number(this.localPlanReportTable[i].propusk_margin) +
          Number(this.localPlanReportTable[i].teach_margin) +
          Number(this.localPlanReportTable[i].driver_margin);

        this.localPlanReportTable[i].other_middle +=
          Number(this.localPlanReportTable[i].card_mean) +
          Number(this.localPlanReportTable[i].propusk_mean) +
          Number(this.localPlanReportTable[i].teach_mean) +
          Number(this.localPlanReportTable[i].driver_mean);

        this.localPlanReportTable[i].other_proc = Math.round(
          Number(this.localPlanReportTable[i].other_proc / 4)
        );

        this.localPlanReportTable[i].other_middle = Math.round(
          Number(this.localPlanReportTable[i].other_middle / 4)
        );
      }
      this.this_month_margin = Math.round(
        Number(this.this_month_margin) /
          Number(this.localPlanReportTable.length)
      );
      this.this_month_mean = Math.round(
        Number(this.this_month_mean) / Number(this.localPlanReportTable.length)
      );

      for (let i = 0; i < this.localPlanReportTable.length; i++) {
        this.sumOfPlans +=
          Number(this.localPlanReportTable[i].taho_plan) +
          Number(this.localPlanReportTable[i].monitor_plan) +
          Number(this.localPlanReportTable[i].other_plan);

        this.margeMiddle +=
          Number(this.localPlanReportTable[i].taho_margin) +
          Number(this.localPlanReportTable[i].monitor_margin) +
          Number(this.localPlanReportTable[i].other_margin);

        this.chequeMiddle +=
          Number(this.localPlanReportTable[i].taho_mean) +
          Number(this.localPlanReportTable[i].monitor_mean) +
          Number(this.localPlanReportTable[i].other_mean) +
          Number(this.localPlanReportTable[i].card_mean) +
          Number(this.localPlanReportTable[i].propusk_mean) +
          Number(this.localPlanReportTable[i].teach_mean) +
          Number(this.localPlanReportTable[i].driver_mean);
      }

      this.margeMiddle =
        this.margeMiddle / (this.localPlanReportTable.length * 7);
      this.chequeMiddle =
        this.chequeMiddle / (this.localPlanReportTable.length * 7);
      this.incomePlan = (this.sumOfPlans * 100) / this.margeMiddle;
      this.chequeCount = this.incomePlan / this.chequeMiddle;
    },
  },
  mounted() {
    var currentYear = new Date().getFullYear();
    var currentMonth = new Date().getMonth();
    currentMonth = currentMonth + 1;
    var lastMonth = currentMonth - 1;

    if (lastMonth < 10) {
      this.dateFrom = "" + currentYear + "-0" + lastMonth;
    } else {
      this.dateFrom = "" + currentYear + "-" + lastMonth;
    }
    if (currentMonth < 10) {
      this.dateTo = "" + currentYear + "-0" + currentMonth;
    } else {
      this.dateTo = "" + currentYear + "-" + currentMonth;
    }
  },
};
</script>

<style scoped>
.button-form-report {
  border-radius: 3px;
  border: none;
  color: #fff;
  text-align: center;
  font-size: 13.5px;
  font-weight: 700;
  line-height: 16.5px;
  text-transform: uppercase;
  padding: 10px 38px;
}
.wrap-pt-grid {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
}
.wrap-plant-grid {
  display: grid;
  grid-template-columns: 435px repeat(3, 1fr);
}
.plan-table-title {
  padding: 25px 0;
  color: rgb(38, 45, 41);
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}
.border-br-8 {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.border-tr-8 {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.wrap-pt-grid > * {
  color: rgb(38, 45, 41);
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: capitalize;

  border-right: 1px solid rgb(232, 236, 236);
}
.wrap-pt-grid > *:last-child {
  border-right: none;
}
.wrap-pt-grid > *:nth-child(2) {
  border-right: 1px solid rgb(136, 136, 136);
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: center;
}
.title-padding-pt > * {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.data-padding-pt > * {
  align-items: center;
  padding: 20px 0;
}
.images-row > img {
  height: 13px;
  width: auto;
}
.user-row > img {
  height: 20px;
  width: auto;
  margin-top: 3px;
}
.top-th {
  color: rgb(38, 45, 41);
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0%;
  text-align: center;
  border-right: 1px solid rgb(232, 236, 236);
  border-bottom: 1px solid rgb(232, 236, 236);
  padding: 10px 0;
}
.bottom-th,
.row-pt-data {
  display: flex;
  align-items: center;
}
.bottom-th > p {
  padding: 5px 0;
}
.bottom-th > *,
.row-pt-data > * {
  color: rgb(38, 45, 41);
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0%;
  text-align: center;
  border-right: 1px solid rgb(232, 236, 236);
  width: 33.3%;
}
.title-col {
  border-right: 1px solid rgb(136, 136, 136);
  border-bottom: 1px solid rgb(232, 236, 236);

  display: flex;
  align-items: center;
}
.title-col > *:first-child {
  width: 68px;
}
.title-col > *:nth-child(2) {
  width: 185px;
}
.title-col > *:last-child {
  width: 125px;
}
.title-col > p {
  color: rgb(38, 45, 41);
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: capitalize;
}
.money-row > p {
  color: rgb(38, 45, 41);
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: capitalize;
}
.row-pt-data > span {
  padding-top: 20px;
  padding-bottom: 20px;
}
.row-pt-data > input,
.row-pt-data > p {
  padding-top: 20px;
  padding-bottom: 20px;
  background: rgba(217, 217, 217, 0.3);
}
.data-padding-pt > p:nth-child(n + 3) {
  background: rgba(217, 217, 217, 0.3);
}
.row-pt-data > input {
  text-align: center;
}
</style>
