import API from "../../services/api";
import router from "@/router/router";


export default {
    state: {
        articles:[],
        article: {},
    },

    mutations: {
        filteredArticles: (state, articles) => {
            state.filteredArticles = articles;
        },

        setArticle: (state, article) => {
            state.article = article;
        },
        setArticles: (state, articles) => {
            state.articles = articles;
            state.filteredArticles = articles;
        },
    },

    actions: {
        getAricles(ctx) {
            let articles = JSON.parse(localStorage.getItem("articles"));
            if (articles) {
                ctx.commit("setArticles", articles);
            }               
            API.GET("learning/list").then((res) => {
                console.log("response Learning Base",res)
                localStorage.setItem("articles", JSON.stringify(res.data.articles));
                ctx.commit("setArticles", res.data.articles);
                ctx.dispatch("loadSettings").then(() => {
                    ctx.dispatch("findArticle", ctx.rootState.s);
                });
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        findArticle(ctx, s) {
            let list = ctx.state.articles;

            if (s.findme != "" && s.findme != undefined) {
                list = list.filter((item) => {
                    let id = null;
                    let text = null;
                    let name = null;
                    id = item.id.toString().indexOf(s.findme) !== -1;
                    if (item.text) {
                        text = item.text.toLowerCase().indexOf(s.findme) !== -1;
                    }
                    if (item.name) {
                        name = item.name.toLowerCase().indexOf(s.findme) !== -1;
                    }
                    if (id || text || name) {
                        return true;
                    }
                    return false;
                });
            }

            ctx.state.filteredArticles = list;
            ctx.dispatch("setSettings", s);
        },

        async getArticle(ctx) {
            let article = localStorage.getItem("article");
            if (article) {
                article = JSON.parse(article);
                ctx.commit("setArticle", article);
               await API.GET("learning/one", { id: ctx.state.article.id }).then((res) => {
                    ctx.commit("setArticle", res.data.article);
                    localStorage.setItem("article", JSON.stringify(res.data.article));
                    res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                });
            }
        },

        setArticle(ctx, article) {
            ctx.commit("setArticle", article);
            localStorage.setItem("article", JSON.stringify(article));
            router.push("/learning_base/show_article");
        },

        // saveArticle(ctx, article) {
        //     let formData = new FormData();
        //     formData.append("id", article.id);
        //     formData.append("name", article.name);
        //     formData.append("text", article.text);
            
        //     API.POST("learning/save-art", formData);
        // },


        delArticle(ctx) {
            let formData = new FormData();
            formData.append("id", ctx.state.article.id);
            API.POST("learning/delete", formData).then((res) => {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
            router.push("/learning_base");
        },
    },

    getters: {
        articlesList(state) {
            return state.filteredArticles;
        },

        article(state) {
            return state.article;
        },

    },
};
