<template>
  <div>
    <div
      v-if="s.popup == 'contactedit'"
      id="popnew"
      class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
    >
      <ContactEdit @close="closeContact" />
    </div>
    <div
      v-if="s.popup"
      @click="close"
      id="overlay"
      class="fixed bg-cyan-900 z-2 w-screen h-screen top-0 left-0 opacity-80"
    ></div>

    <template v-if="invoice.id > 0">
      <div class="flex max-xl:flex-col">
        <div class="w-[400px] my-4 max-xl:mx-auto max-xl:max-w-full">
          <div class="bg-white rounded-[4px]">
            <div class="p-4 v-bbgray">
              <div class="flex items-center w-full mb-4 relative">
                <h1 class="font-latobold text-xl text-black">СЧЁТ</h1>
              </div>
              <div class="flex items-center relative">
                <div class="w-16 h-16 relative">
                  <span
                    class="avatar w-full h-full flex items-center justify-center"
                    :class="{
                      'bg-status-1': invoice.status == 1,
                      'bg-status-2': invoice.status == 2,
                      'bg-status-6': invoice.status == 3,
                      'bg-status-11':
                        invoice.status == 4 || invoice.status == 6,
                    }"
                  >
                    <span
                      class="w-8 h-8 flex items-center justify-center first:!fill-white"
                    >
                      <IcoInvoiceStatus :status="parseInt(invoice.status)" />
                    </span>
                  </span>
                </div>
                <div class="flex flex-col ml-4 mr-8">
                  <span class="text-gray-400 text-[10px] uppercase">{{
                    st[invoice.status]
                  }}</span>
                  <span class="font-latobold text-xl text-black">{{
                    invoice.invoice_number
                  }}</span>
                </div>
                <span
                  title="Обработка заявки просрочена"
                  v-if="invoice.violate == 2"
                >
                  <svg class="w-5 h-5 -ml-6 mt-1 mr-4 fill-status-11">
                    <use xlink:href="@/assets/i.svg#status-11"></use>
                  </svg>
                </span>
                <div v-if="invoice?.parent_id" class="flex items-center">
                  <div class="flex flex-col">
                    <p class="text-gray-400 text-[10px] uppercase">
                      Направление
                    </p>
                    <span
                      class="font-latobold text-xl text-gray-700"
                      v-if="invoice.parent_id == 1"
                      >Тахографы</span
                    >
                    <span
                      class="font-latobold text-xl text-gray-700"
                      v-if="invoice.parent_id == 2"
                      >Мониторинг</span
                    >
                    <span
                      class="font-latobold text-xl text-gray-700"
                      v-if="invoice.parent_id == 3"
                      >Карты</span
                    >
                    <span
                      class="font-latobold text-xl text-gray-700"
                      v-if="invoice.parent_id == 4"
                      >Пропуски</span
                    >
                    <span
                      class="font-latobold text-xl text-gray-700"
                      v-if="invoice.parent_id == 5"
                      >Обучение</span
                    >
                    <span
                      class="font-latobold text-xl text-gray-700"
                      v-if="invoice.parent_id == 7"
                      >Водители</span
                    >
                  </div>
                </div>

                <div
                  v-if="invoice.invoice_id"
                  class="absolute top-1 -right-4 h-14 overflow-hidden max-w-[40%] pl-6 pr-4 flex items-center"
                  @click="editinvoiceId(invoice.invoice_id)"
                >
                  <svg class="w-7 h-7 fill-white z-2 mr-2">
                    <use xlink:href="@/assets/i.svg#ico-rating"></use>
                  </svg>
                  <span class="text-white z-20 relative text-2xl font-latobold"
                    >ЗАКАЗ</span
                  >
                  <svg
                    class="absolute top-0 -left-8 h-full"
                    :class="'!fill-status-6'"
                  >
                    <use xlink:href="@/assets/bg.svg#bg-timer"></use>
                  </svg>
                </div>

                <div
                  v-if="timer?.time"
                  class="absolute top-1 -right-4 h-14 overflow-hidden max-w-[50%] pl-6 pr-4 flex items-center"
                >
                  <span
                    class="text-white z-20 relative text-2xl font-latobold"
                    >{{ timer.time }}</span
                  >
                  <svg
                    class="absolute top-0 -left-8 h-full"
                    :class="'!fill-status-' + timer?.color"
                  >
                    <use xlink:href="@/assets/bg.svg#bg-timer"></use>
                  </svg>
                </div>
              </div>
            </div>
            <div class="flex p-5 v-bbgray">
              <div class="flex flex-col w-full mr-2">
                <p class="text-gray-400">НОМЕР СЧЕТА В CRM</p>
                <input
                  type="text"
                  class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                  v-model="invoice.number_crm"
                  :class="{ 'binvoice-rose-300': !invoice.number_crm }"
                  @change="setInvoiceCRMNumber(invoice)"
                />
              </div>
            </div>
            <template
              v-if="['Manager', 'Logist', 'Studio', 'Hosd'].includes(role)"
            >
              <div class="flex p-5 v-bbgray">
                <div class="flex flex-col w-4/5 mr-2">
                  <p class="text-gray-400">ЗАКАЗ 1C</p>
                  <input
                    type="text"
                    class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                    v-model="invoice.code"
                    :class="{ 'binvoice-rose-300': !invoice.code }"
                    @change="setInvoiceCode(invoice)"
                  />
                </div>
                <div class="flex flex-col w-4/5 ml-2">
                  <p class="text-gray-400">СЧЕТ 1C</p>
                  <input
                    type="text"
                    class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                    v-model="invoice.codebill"
                    @change="setInvoiceCodebill(invoice)"
                  />
                </div>
              </div>
              <div class="flex p-5 v-bbgray">
                <div class="flex flex-col w-4/5 mr-2">
                  <p class="text-gray-400">Реализация 1С</p>
                  <input
                    type="text"
                    class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                    v-model="invoice.s_one_realization"
                    :class="{ 'binvoice-rose-300': !invoice.s_one_realization }"
                    @change="
                      setInvoiceField({
                        invoice: invoice,
                        field: 's_one_realization',
                        value: invoice.s_one_realization,
                      })
                    "
                  />
                </div>
                <div class="flex flex-col w-4/5 ml-2">
                  <p class="text-gray-400">Списание 1С</p>
                  <input
                    type="text"
                    class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                    v-model="invoice.s_one_write_off"
                    :class="{ 'binvoice-rose-300': !invoice.s_one_write_off }"
                    @change="
                      setInvoiceField({
                        invoice: invoice,
                        field: 's_one_write_off',
                        value: invoice.s_one_write_off,
                      })
                    "
                  />
                </div>
              </div>
            </template>

            <div class="flex v-bbgray">
              <div class="flex py-5 pl-5 pr-4 items-center w-full">
                <svg class="min-w-5 w-5 h-5 fill-gray-400 rotate-45 mr-2">
                  <use xlink:href="@/assets/i.svg#ico-no"></use>
                </svg>
                <div class="flex flex-col">
                  <span class="text-gray-400 text-[10px] uppercase"
                    >СОЗДАН</span
                  >
                  <span class="font-latobold text-base text-gray-700">{{
                    moment(invoice.created_at).format("DD.MM.YYYY в HH:mm")
                  }}</span>
                </div>
                <span
                  v-if="invoice.pay_status == 1"
                  title="Счет не оплачен"
                  class="min-w-[2rem] w-8 h-8 overflow-hidden relative ml-auto bg-status-7 flex items-center justify-center"
                  ><span class="avatar overflow-hidden"
                    ><svg class="fill-white w-4 h-4">
                      <use
                        xlink:href="/img/i.073dba3d.svg#status-11"
                      ></use></svg></span
                ></span>
                <span
                  v-if="invoice.pay_status == 6"
                  title="Счет успешно оплачен"
                  class="min-w-[2rem] w-8 h-8 overflow-hidden relative ml-auto bg-status-6 flex items-center justify-center"
                  ><span class="avatar overflow-hidden"
                    ><svg class="fill-white w-4 h-4">
                      <use
                        xlink:href="/img/i.073dba3d.svg#ico-ok"
                      ></use></svg></span
                ></span>
              </div>
            </div>
            <div v-if="invoice.start_at" class="flex v-bbgray">
              <div class="flex ml-4 py-3 pl-5 pr-4 items-center">
                <div class="flex flex-col">
                  <span class="text-gray-400 text-[10px] uppercase"
                    >Начато</span
                  >
                  <span class="font-latobold text-base text-gray-700">{{
                    moment(invoice.start_at).format("DD.MM.YYYY в HH:mm")
                  }}</span>
                </div>
              </div>
            </div>
            <div v-if="invoice.end_at" class="flex v-bbgray">
              <div class="flex ml-4 py-3 pl-5 pr-4 items-center">
                <div class="flex flex-col">
                  <span class="text-gray-400 text-[10px] uppercase"
                    >Закончено</span
                  >
                  <span class="font-latobold text-base text-gray-700">{{
                    moment(invoice.end_at).format("DD.MM.YYYY в HH:mm")
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 
          <div class="bg-white invoice-conn mt-4 relative rounded-[4px]">
            <div v-if="invoice.firm_id" class="flex p-5 v-bbgray">
              <div class="flex items-center group relative w-full">
                <div
                  class="flex flex-col justify-center overflow-hidden w-full"
                >
                  <span
                    class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                    >Обещанный платеж</span
                  >
                  <input
                    class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                    type="date"
                    v-model="invoice.promise_date"
                    @change="saveDateInvoice(invoice.promise_date)"
                  />
                </div>
              </div>
            </div>
          </div> -->

          <div class="bg-white invoice-conn mt-4 relative rounded-[4px]">
            <div v-if="invoice.firm_id" class="flex p-5 v-bbgray">
              <div class="flex items-center group relative w-full">
                <div
                  class="flex flex-col justify-center overflow-hidden pr-8 w-full"
                >
                  <span
                    class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                    >Поставщик</span
                  >
                  <span class="font-latobold">{{ invoice.firm_name }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="bg-white invoice-conn mt-4 relative rounded-[4px]">
            <div v-if="invoice.pay" class="flex p-5 v-bbgray">
              <div class="flex items-center group relative w-full">
                <div
                  class="flex flex-col justify-center overflow-hidden pr-8 w-full"
                >
                  <span
                    class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                    >Статус оплаты</span
                  >
                  <span v-if="invoice.pay == 1" class="font-latobold"
                    >Не оплачен</span
                  >
                  <span v-if="invoice.pay == 2" class="font-latobold"
                    >Ждёт гаранта</span
                  >
                  <span v-if="invoice.pay == 3" class="font-latobold"
                    >Гарант есть</span
                  >
                  <span v-if="invoice.pay == 4" class="font-latobold"
                    >Оплачен</span
                  >
                </div>
              </div>
            </div>
          </div> -->

          <div
            v-if="canShowClient"
            class="rounded-[4px] bg-white mt-4 relative invoice-conn"
          >
            <template v-if="invoice.client_id != null">
              <template v-if="invoice.client?.loader">
                <div class="flex">
                  <img src="https://api.tahocrm.ru/images/loader.svg" />
                </div>
              </template>
              <template v-else>
                <div class="flex relative group miw-w-full p-5 v-bbgray">
                  <a
                    class="flex items-center cursor-pointer group max-w-[75%]"
                    @click="showClient(invoice.client)"
                  >
                    <div
                      class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4"
                    >
                      <span class="w-6 h-6 flex-cc">
                        <IcoPic
                          :pic="invoice.client?.picture"
                          :name="invoice.client?.name"
                        />
                      </span>
                    </div>
                    <div
                      class="flex flex-col justify-center overflow-hidden pr-8"
                    >
                      <span
                        class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                        >Клиент</span
                      >
                      <span
                        class="font-latobold text-base cursor-pointer group-hover:text-green-500 transition-colors duration-300"
                        >{{ invoice.client?.name }}</span
                      >
                    </div>
                  </a>

                  <!-- <div
                      v-if="
                        ['Manager', 'Studio' , 'Hosd'].includes(role) &&
                        [1, 2, 3, 10].includes(invoice.status)
                      "
                      class="absolute top-1/2 -translate-y-1/2 right-2 opacity-0 group-hover:opacity-100 transition-all duration-150"
                    >
                      <a class="cursor-pointer" @click="dClient">
                        <svg
                          class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                        >
                          <use xlink:href="@/assets/i.svg#ico-no"></use>
                        </svg>
                      </a>
                    </div> -->
                </div>
              </template>
            </template>

            <div v-else class="p-5">
              <div v-if="canEditClient" class="flex items-center w-full">
                <p class="text-gray-400 leading-none w-full max-w-[100px] mr-6">
                  КЛИЕНТ
                </p>
                <div class="flex-1 relative">
                  <input
                    type="text"
                    v-model.trim="text_client"
                    class="bg-slate-50 h-9 px-3 py-2 w-full box-binvoice v-bgray rounded-sm text-sm text-teal-700"
                    @click="findLeadClient(text_client)"
                    @input="findLeadClient(text_client)"
                  />
                  <div
                    class="absolute w-full top-full bg-white left-0 rounded-b-lg binvoice binvoice-green-500 binvoice-t-0 z-10 hidden"
                    :class="{ '!block': selectLeadClients?.length > 0 }"
                  >
                    <div
                      v-for="cl in selectLeadClients"
                      :key="cl"
                      @click="setLeadClient(cl.id), (text_client = '')"
                      class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                    >
                      <span>{{ cl.name }}</span>
                      <span class="text-xs">{{ cl.iid }}</span>
                    </div>
                  </div>
                  <div
                    v-if="
                      text_client.length > 0 && selectLeadClients?.length <= 0
                    "
                    class="absolute w-full top-full bg-white left-0 rounded-b-lg binvoice binvoice-green-500 binvoice-t-0 z-10 block"
                  >
                    <div
                      class="px-4 py-2 flex justify-between items-center hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                    >
                      <span>НЕ&nbsp;НАЙДЕН</span>
                      <button class="v-btn bg-green-500 hover:bg-green-400">
                        <span
                          class="v-btn_text flex items-center"
                          @click="
                            addLeadClient(text_client), (text_client = '')
                          "
                        >
                          <svg
                            class="fill-white w-16px h-16px mr-2 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linea"
                          >
                            <use xlink:href="/img/i.5cce8585.svg#ico-no"></use>
                          </svg>
                          СОЗДАТЬ
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <p v-else class="text-rose-400 leading-none text-center">
                Клиент не добавлен!
              </p>
            </div>

            <template v-if="invoice.client_id != null">
              <div class="p-5 v-bbgray flex items-center group relative">
                <template v-if="invoice.company_id != null">
                  <template v-if="invoice.company?.loader">
                    <div class="flex">
                      <img src="https://api.tahocrm.ru/images/loader.svg" />
                    </div>
                  </template>
                  <template v-else>
                    <a
                      class="flex cursor-pointer group"
                      @click="showСompanyfromLead(invoice.company)"
                    >
                      <div
                        class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4"
                      >
                        <span class="w-6 h-6 flex-cc">
                          <IcoPic
                            :pic="invoice.company?.picture"
                            :name="invoice.company?.name"
                          />
                        </span>
                      </div>
                      <div
                        class="flex flex-col justify-center overflow-hidden pr-8"
                      >
                        <span
                          class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                          >Компания</span
                        >
                        <span
                          class="font-latobold text-base cursor-pointer group-hover:text-green-500 transition-colors duration-300"
                        >
                          {{ invoice.company?.secondname }}
                          {{ invoice.company?.name }}
                        </span>
                      </div>
                    </a>
                    <!-- <div
                        v-if="
                          ['Manager', 'Studio' , 'Hosd'].includes(role) &&
                          invoice.company_id &&
                          [1, 2, 3, 10, 11, 12, 13].includes(invoice.status)
                        "
                        class="absolute top-1/2 -translate-y-1/2 right-2 opacity-0 group-hover:opacity-100 transition-all duration-150"
                      >
                        <a class="group cursor-pointer" @click="delLeadCompany()">
                          <svg
                            class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                          >
                            <use xlink:href="@/assets/i.svg#ico-no"></use>
                          </svg>
                        </a>
                      </div> -->
                  </template>
                </template>
                <template v-else>
                  <div
                    v-if="
                      ['Manager', 'Logist', 'Studio', 'Hosd', 'Check'].includes(
                        role
                      ) &&
                      selectInvoiceCompanies &&
                      selectInvoiceCompanies.length > 0
                    "
                    class="flex items-center w-full"
                  >
                    <p
                      class="text-gray-400 leading-none w-full max-w-[100px] mr-6"
                    >
                      Выберите компанию
                    </p>
                    <div class="relative w-full">
                      <input
                        type="text"
                        v-model.trim="text_company"
                        class="bg-slate-50 h-9 px-3 py-2 w-full box-binvoice v-bgray rounded-sm text-sm text-teal-700"
                        @input="findCompany()"
                        @click="findCompany()"
                      />
                      <div
                        v-if="companyList && companyList.length > 0"
                        class="absolute w-full top-full bg-white left-0 binvoice-2 binvoice-green-800 binvoice-t-0 z-10"
                      >
                        <div
                          v-for="co in companyList"
                          :key="co"
                          @click="pickCompany(co)"
                          class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                        >
                          <span>{{ co.secondname }} {{ co.name }}</span>
                          <span class="text-xs">{{ co.position }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p v-else class="text-rose-500 text-sm">
                    Компания не выбрана!
                  </p>
                </template>
              </div>

              <div class="p-5 v-bbgray flex items-center group relative">
                <template v-if="invoice.contact_id != null">
                  <template v-if="invoice.contact?.loader">
                    <div class="flex">
                      <img src="https://api.tahocrm.ru/images/loader.svg" />
                    </div>
                  </template>
                  <template v-else>
                    <a
                      class="flex cursor-pointer group"
                      @click="showContactfromLead(invoice.contact)"
                    >
                      <div
                        class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4"
                      >
                        <span class="w-6 h-6 flex-cc">
                          <IcoPic
                            :pic="invoice.contact?.picture"
                            :name="invoice.contact?.name.trim()"
                          />
                        </span>
                      </div>
                      <div
                        class="flex flex-col justify-center overflow-hidden pr-8"
                      >
                        <span
                          class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                          >Контакт</span
                        >
                        <span
                          class="font-latobold text-base cursor-pointer group-hover:text-green-500 transition-colors duration-300"
                          >{{ invoice.contact?.secondname }}
                          {{ invoice.contact?.name }}</span
                        >
                      </div>
                    </a>
                    <template v-if="invoice.contact?.phone">
                      <a
                        class="rounded-full absolute top-1/2 -translate-y-1/2 right-16 w-7 h-7 hover:w-40 overflow-hidden flex items-center bg-green-500 transition-all duration-300"
                        :href="'tel:' + invoice.contact.phone"
                      >
                        <svg class="w-4 h-4 ml-[6px] fill-white">
                          <use xlink:href="@/assets/i.svg#ico-phone"></use>
                        </svg>
                        <span class="absolute left-7 p-1 text-white">{{
                          invoice.contact?.phone
                        }}</span>
                      </a>
                    </template>
                    <!-- <div
                      v-if="
                        ['Manager', 'Studio' , 'Hosd'].includes(role) &&
                        invoice.contact_id &&
                        [1, 2, 3, 10, 11, 12, 13].includes(invoice.status) &&
                        invoice.status == 1
                      "
                      class="absolute top-1/2 -translate-y-1/2 right-2 opacity-0 group-hover:opacity-100 transition-all duration-150"
                    >
                      <a
                        class="group cursor-pointer"
                        @click="delInvoiceContact()"
                      >
                        <svg
                          class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                        >
                          <use xlink:href="@/assets/i.svg#ico-no"></use>
                        </svg>
                      </a>
                    </div> -->
                  </template>
                </template>
                <template v-else>
                  <div
                    v-if="
                      ['Manager', 'Logist', 'Studio', 'Hosd', 'Check'].includes(
                        role
                      ) &&
                      selectInvoiceContacts &&
                      selectInvoiceContacts.length > 0
                    "
                    class="flex items-center w-full"
                  >
                    <p
                      class="text-gray-400 leading-none w-full max-w-[100px] mr-6"
                    >
                      Выберите контакт
                    </p>
                    <div class="relative w-full">
                      <input
                        type="text"
                        v-model.trim="text_contact"
                        class="bg-slate-50 h-9 px-3 py-2 w-full box-binvoice v-bgray rounded-sm text-sm text-teal-700"
                        @input="findContact()"
                        @click="findContact()"
                      />
                      <div
                        v-if="contactList && contactList.length > 0"
                        class="absolute w-full top-full bg-white left-0 binvoice-2 binvoice-green-800 binvoice-t-0 z-10"
                      >
                        <div
                          v-for="co in contactList"
                          :key="co"
                          @click="pickContact(co)"
                          class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                        >
                          <span>{{ co.secondname }} {{ co.name }}</span>
                          <span class="text-xs">{{ co.position }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p v-else class="text-rose-500 text-sm">
                    Контакт не добавлен!
                  </p>
                </template>
              </div>

              <!-- <div class="p-5 v-bbgray">
                  <div v-if="invoice.client?.ts" class="flex items-center">
                    <div class="flex flex-col">
                      <p class="text-gray-400 text-[10px] uppercase">КОЛ-ВО ТС</p>
                      <span class="font-latobold text-base text-gray-700">
                        {{ invoice.client?.ts }}
                      </span>
                    </div>
                  </div>
                  <template v-else>
                    <div class="flex items-center w-full">
                      <p
                        class="text-gray-400 leading-none w-full max-w-[100px] mr-6"
                      >
                        КОЛ-ВО ТС <br />У КЛИЕНТА
                      </p>
  
                      <input
                        class="h-9 px-3 py-2 mt-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                        type="text"
                        v-model="ts"
                        @change="setClientTs(ts)"
                      />
                    </div>
                  </template>
                </div> -->
            </template>
          </div>
          <div
            class="bg-white invoice-conn mt-4 p-5 relative rounded-[4px]"
            v-if="canAddClient"
          >
            <div style="position: relative" v-if="invoice.client_id">
              <a
                @click="editNewClient"
                class="flex items-center cursor-pointer group max-w-[75%]"
              >
                <div
                  class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4"
                >
                  <span class="w-6 h-6 flex-cc">
                    <span
                      class="text-white text-xl w-11 h-11 leading-none rounded-full flex items-center justify-center"
                      >К</span
                    >
                  </span>
                </div>
                <div class="flex flex-col justify-center overflow-hidden pr-8">
                  <span
                    class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                    >Клиент</span
                  >
                  <span
                    class="font-latobold text-base cursor-pointer group-hover:text-green-500 transition-colors duration-300"
                    >{{ lead?.client?.name }}</span
                  >
                </div>
              </a>
              <a
                class="cursor-pointer"
                style="position: absolute; right: 0; top: calc(50% - 8px)"
                @click="dClient"
              >
                <svg
                  class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                >
                  <use xlink:href="@/assets/i.svg#ico-no"></use>
                </svg>
              </a>
            </div>

            <div v-else>
              <button
                @click="addNewClient"
                class="v-btn v-btn--sm bg-green-500 hover:bg-green-400"
              >
                <span class="v-btn_text flex items-center">
                  <svg
                    class="fill-white w-16px h-16px mr-3 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linear"
                  >
                    <use xlink:href="/img/i.cc1a44ec.svg#ico-no"></use>
                  </svg>
                  <span>Добавить клиента</span>
                </span>
              </button>
            </div>
            <div
              v-if="showClientEdit"
              id="popnew"
              class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
            >
              <ClientEdit @close="clientEditClose" />
            </div>
          </div>

          <div class="bg-white invoice-conn mt-4 relative rounded-[4px]">
            <div
              v-if="
                profile.uid == invoice.manager_id ||
                ['Manager', 'Studio', 'Hosd', 'Finance', 'Check'].includes(role)
              "
              class="flex relative group p-5 v-bbgray"
            >
              <div v-if="invoice.manager_id" class="flex justify-between">
                <div class="flex items-center cursor-pointer group">
                  <div
                    class="min-w-[1.5rem] w-11 h-11 rounded-full mr-4 overflow-hidden bg-green-500"
                  >
                    <span class="w-6 h-6">
                      <IcoPic :pic="invoice.mpicture" :name="invoice.mname" />
                    </span>
                  </div>
                  <div
                    class="flex flex-col justify-center overflow-hidden pr-8"
                  >
                    <span
                      class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                      >Менеджер</span
                    >
                    <span
                      class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
                      >{{ invoice.mname }} {{ invoice.msecondname }}</span
                    >
                  </div>
                </div>
                <!-- <div
                    v-if="invoice.manager_id && [1, 3].includes(invoice.status)"
                    class="absolute top-1/2 -translate-y-1/2 right-8 opacity-0 group-hover:opacity-100 transition-all duration-150"
                  >
                    <a class="group cursor-pointer" @click="delLeadManager()">
                      <svg
                        class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                      >
                        <use xlink:href="@/assets/i.svg#ico-no"></use>
                      </svg>
                    </a>
                  </div> -->
              </div>

              <template v-else>
                <div class="flex items-center w-full">
                  <p
                    class="text-gray-400 leading-none w-full max-w-[100px] mr-6"
                  >
                    Менеджер
                  </p>
                  <div class="relative w-full">
                    <input
                      type="text"
                      v-model.trim="text_manager"
                      class="bg-white h-9 px-3 py-2 w-full box-binvoice v-bgray rounded-sm text-sm text-teal-700"
                      @input="findManager()"
                      @click="findManager()"
                    />
                    <div
                      v-if="managerList.length"
                      class="absolute w-full top-full bg-white left-0 binvoice-2 binvoice-green-800 binvoice-t-0 z-10"
                    >
                      <div
                        v-for="m in managerList"
                        :key="m"
                        @click="setLeadManager(m)"
                        class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                      >
                        <span>{{ m.secondname }} {{ m.name }}</span>
                        <span class="text-xs">{{ m.uid }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>

            <div class="flex relative group p-5 v-bbgray">
              <div v-if="invoice.who" class="flex justify-between">
                <div class="flex items-center cursor-pointer group">
                  <div
                    class="min-w-[1.5rem] w-11 h-11 rounded-full mr-4 overflow-hidden bg-green-500"
                  >
                    <span class="w-6 h-6">
                      <IcoPic :pic="invoice.wpicture" :name="invoice.wname" />
                    </span>
                  </div>
                  <div
                    class="flex flex-col justify-center overflow-hidden pr-8"
                  >
                    <span
                      class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                      >СОЗДАТЕЛЬ</span
                    >
                    <span
                      class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
                      >{{ invoice.wname }} {{ invoice.wsecondname }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col ml-4 mr-12 flex-1 mb-4 max-l:mx-0">
          <div
            v-if="
              profile.uid == invoice.manager_id ||
              ([
                'Manager',
                'Logist',
                'Studio',
                'Hosd',
                'Finance',
                'Check',
              ].includes(role) &&
                canShowGar)
            "
            class="flex justify-between border-t-2 border-gray-400 w-[90%] mt-10 mb-1 mx-auto max-m:hidden"
          >
            <div class="flex flex-col items-center -mt-6 -ml-6 relative">
              <span
                @click="toggleGarl('showTouches')"
                class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center"
              >
                <svg class="w-5 h-5 fill-white">
                  <use xlink:href="@/assets/i.svg#nav-clients"></use>
                </svg>
              </span>
              <span
                class="text-[10px] text-gray-400 leading-none uppercase mt-1"
                >КАСАНИЕ</span
              >

              <div
                v-if="showTouches && garland.touches.length > 0"
                class="absolute-list"
              >
                <p class="font-latobold text-base text-gray-700">Касания</p>
                <a
                  v-for="touch in garland.touches"
                  :key="touch"
                  @click="goToTouch(touch.id)"
                  >№{{ touch.id }}</a
                >
              </div>
            </div>
            <div class="flex flex-col items-center -mt-6 relative">
              <span
                @click="toggleGarl('showLeads')"
                class="rounded-full h-11 w-11 bg-gray-400 flex items-center justify-center"
                :class="{
                  '!bg-status-5': garland.leads.length > 0,
                }"
              >
                <svg class="w-5 h-5 fill-white">
                  <use xlink:href="@/assets/i.svg#status-1"></use>
                </svg>
              </span>
              <span
                class="text-[10px] text-gray-400 leading-none uppercase mt-1"
                >ЛИД</span
              >
              <div
                v-if="showLeads && garland.leads.length"
                class="absolute-list"
              >
                <p class="font-latobold text-base text-gray-700">
                  Лиды из касания
                </p>
                <a
                  @click="goToLead(lead.id)"
                  v-for="lead in garland.leads"
                  :key="lead"
                  >№{{ lead.id }}</a
                >
              </div>
            </div>

            <div class="flex flex-col items-center -mt-6 relative">
              <span
                @click="toggleGarl('showZayavka')"
                class="rounded-full h-11 w-11 flex items-center justify-center bg-status-5"
              >
                <svg class="w-5 h-5 fill-white">
                  <use xlink:href="@/assets/i.svg#doc"></use>
                </svg>
              </span>
              <span
                class="text-[10px] text-gray-400 leading-none uppercase mt-1"
                >ЗАЯВКА</span
              >
              <div
                v-if="showZayavka && garland.bids.length"
                class="absolute-list"
              >
                <p class="font-latobold text-base text-gray-700">
                  Заявки из касания
                </p>
                <a
                  v-for="bid in garland.bids"
                  @click="goToBid(bid.id)"
                  :key="bid"
                  >№{{ bid.id }}</a
                >
              </div>
            </div>

            <div class="flex flex-col items-center -mt-6 relative">
              <span
                @click="toggleGarl('showSchet')"
                class="rounded-full h-11 w-11 flex items-center justify-center bg-gray-400"
                :class="{
                  '!bg-status-5': garland.invoices.length,
                  '!bg-status-7': invoice.status == 4,
                }"
              >
                <svg class="w-5 h-5 fill-white">
                  <use xlink:href="@/assets/i.svg#doc"></use>
                </svg>
              </span>
              <span
                class="text-[10px] text-gray-400 leading-none uppercase mt-1"
                >СЧЕТ</span
              >

              <!-- <div
                v-if="showSchet && garland.invoices.length"
                class="absolute-list"
              >
                <p class="font-latobold text-base text-gray-700">
                  Счета из касания
                </p>
                <a v-for="invoice in garland.invoices" :key="invoice"
                  >№{{ bid.id }}</a
                >
              </div> -->
            </div>

            <div class="flex flex-col items-center -mt-6 relative">
              <span
                @click="toggleGarl('showOrderLet')"
                class="rounded-full h-11 w-11 flex items-center justify-center bg-gray-400"
                :class="{
                  '!bg-status-5': garland.orders.length,
                  '!bg-status-7': invoice.status == 4,
                }"
              >
                <svg class="w-5 h-5 fill-white">
                  <use xlink:href="@/assets/i.svg#pay-1"></use>
                </svg>
              </span>
              <span
                class="text-[10px] text-gray-400 leading-none uppercase mt-1"
                >ЗАКАЗ</span
              >
              <div
                v-if="showOrderLet && garland.orders.length"
                class="absolute-list last-list"
              >
                <p class="font-latobold text-base text-gray-700">
                  Заказы из касания
                </p>
                <a
                  v-for="order in garland.orders"
                  @click="goToOrder(order.id)"
                  :key="order"
                  >№{{ order.iid }}</a
                >
              </div>
            </div>

            <div class="flex flex-col items-center -mt-6 -mr-6 relative">
              <span
                @click="toggleGarl('showAnketa')"
                class="rounded-full h-11 w-11 flex items-center justify-center bg-gray-400"
                :class="{
                  '!bg-status-5': garland.anketas.length,
                  '!bg-status-7': invoice.status == 4,
                }"
              >
                <svg class="w-5 h-5 fill-white">
                  <use xlink:href="@/assets/i.svg#pay-2"></use>
                </svg>
              </span>
              <span
                class="text-[10px] text-gray-400 leading-none uppercase mt-1"
                >АНКЕТА</span
              >

              <div
                v-if="showAnketa && garland.anketas.length"
                class="absolute-list"
              >
                <p class="font-latobold text-base text-gray-700">
                  Анкеты из касания
                </p>
                <a v-for="anketa in garland.anketas" :key="anketa"
                  >№{{ anketa.id }}</a
                >
              </div>
            </div>
          </div>

          <!-- <div class="p-5 bg-white rounded-[4px] mt-4">
              
            </div> -->

          <!-- <div class="p-5 bg-white rounded-[4px] mt-4">
                            <div class="flex items-center max-l:flex-wrap">
                                <div class="flex items-center max-l:flex-wrap">
                                    <span class="text-base font-latobold uppercase">Событие в 1С</span><br/>
                                    <div class="flex items-center w-full">
                                        <textarea
                                            class="h-9 px-3 py-2 mt-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                            v-model="invoice.event_one_s"
                                            @change="setEvent1S(invoice.event_one_s)"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div> -->

          <div class="bg-white rounded-[4px] mt-4">
            <template v-if="invoice.price">
              <div
                v-for="p in invoice.price"
                :key="p"
                class="flex p-5 items-center v-bbgray justify-between"
              >
                <template v-if="p.loader">
                  <div class="flex">
                    <img src="https://api.tahocrm.ru/images/loader.svg" />
                  </div>
                </template>
                <template v-else>
                  <template
                    v-if="
                      [
                        'Manager',
                        'Logist',
                        'Finance',
                        'Studio',
                        'Hosd',
                        'Finance',
                      ].includes(role)
                    "
                  >
                    <div class="max-w-[50%] w-full flex items-center">
                      <span
                        class="rounded-full h-11 min-w-[2.75rem] w-11 bg-status-1 flex items-center justify-center mr-4 overflow-hidden"
                      >
                        <svg class="w-5 h-5 fill-white">
                          <use xlink:href="@/assets/i.svg#nav-service"></use>
                        </svg>
                      </span>
                      <span class="text-base font-latobold">{{
                        p.price.name
                      }}</span>
                    </div>

                    <div class="flex text-base font-latobold w-48">
                      <span style="line-height: 30px">{{ p.items }} шт</span>
                    </div>

                    <div class="text-base font-latobold w-24" v-if="p.cost > 0">
                      {{ p.cost }} ₽
                    </div>
                    <div class="text-base font-latobold w-24" v-else>
                      цена неизвестна
                    </div>
                  </template>
                  <template v-else>
                    <div class="flex items-center">
                      <span
                        class="rounded-full h-11 min-w-[2.75rem] w-11 bg-status-1 flex items-center justify-center mr-4 overflow-hidden"
                      >
                        <svg class="w-5 h-5 fill-white">
                          <use xlink:href="@/assets/i.svg#nav-service"></use>
                        </svg>
                      </span>
                      <span class="text-base font-latobold">{{
                        p.price.name
                      }}</span>
                    </div>
                  </template>
                </template>
              </div>
            </template>

            <!-- <template
              v-if="
                ['Manager', 'Logist', 'Finance', 'Studio', 'Hosd', 'Check'].includes(
                  role
                ) && invoice.status == 1
              "
            >
              <div class="p-5 v-bbgray flex items-center">
                <p class="text-gray-400 leading-none max-w-[25%] w-full">
                  Добавить услугу
                </p>
                <div class="flex flex-1 h-9 relative">
                  <input
                    type="text"
                    v-model.trim="text_price"
                    class="w-full bg-white v-bgray"
                    @input="findPrice"
                    @click="findPrice"
                  />
                  <div
                    v-if="priceList.length"
                    class="absolute max-h-[350px] overflow-y-auto w-full top-full bg-white left-0 binvoice-2 binvoice-green-800 binvoice-t-0"
                  >
                    <div
                      v-for="p in priceList"
                      :key="p.id"
                      @click="pickPrice(p)"
                      class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                    >
                      <span>{{ p.name }}</span>
                      <span class="text-xs">{{ p.sum }}₽</span>
                    </div>
                  </div>
                </div>
              </div>
            </template> -->

            <div class="flex p-5 justify-between items-center max-l:flex-wrap">
              <div class="max-w-[50%] w-full flex items-center">
                <span
                  class="rounded-full h-11 min-w-[2.75rem] w-11 font-latobold text-xl text-white bg-status-1 flex items-center justify-center mr-4 overflow-hidden"
                >
                  ₽
                </span>
                <span class="text-base font-latobold">СУММА ЗАЯВКИ</span>
              </div>
              <div class="text-base font-latobold w-48">
                всего услуг - {{ invoice.price_items }} шт.
              </div>
              <div class="text-base font-latobold w-24">
                {{ invoice.sum }} ₽
              </div>
            </div>

            <template v-if="invoice.pay != null">
              <template v-if="invoice.pay == 6">
                <div class="flex p-5 items-center v-bbgray justify-between">
                  <p class="text-gray-400 leading-none max-w-[25%] w-full">
                    Дата оплаты
                  </p>
                  <span
                    v-if="invoice.pay_date"
                    class="text-base font-latobold mr-8"
                  >
                    {{ moment(invoice.pay_date).format("DD.MM.YYYY") }}
                  </span>

                  <span class="text-base font-latobold" v-if="invoice.pay == 1"
                    >Наличные взять</span
                  >
                  <span class="text-base font-latobold" v-if="invoice.pay == 2">
                    Наличные предоплата
                  </span>
                  <span class="text-base font-latobold" v-if="invoice.pay == 4"
                    >Безнал предоплата</span
                  >
                  <span class="text-base font-latobold" v-if="invoice.pay == 7"
                    >С отсрочкой</span
                  >
                </div>
              </template>
              <template v-else>
                <div class="flex p-5 items-center v-bbgray justify-start">
                  <p class="text-gray-400 leading-none max-w-[25%] w-full">
                    Дата ожидаемой оплаты
                  </p>

                  <Datepicker
                    v-if="invoice.pay_wait"
                    v-model="invoice.pay_wait"
                    format="yyyy-MM-dd HH:mm:ss"
                  ></Datepicker>
                  <button
                    class="v-btn mr-auto ml-4 bg-green-500 hover:bg-gray-400"
                  >
                    <span
                      class="v-btn_text"
                      @click="
                        saveDateInvoice({
                          id: invoice.id,
                          pay_wait: invoice.pay_wait,
                        })
                      "
                      >Сохранить</span
                    >
                  </button>

                  <span class="text-base font-latobold" v-if="invoice.pay == 1"
                    >Наличные взять</span
                  >
                  <span class="text-base font-latobold" v-if="invoice.pay == 2">
                    Наличные предоплата
                  </span>
                  <span class="text-base font-latobold" v-if="invoice.pay == 4"
                    >Безнал предоплата</span
                  >
                  <span class="text-base font-latobold" v-if="invoice.pay == 7"
                    >С отсрочкой</span
                  >

                  <a
                    class="group cursor-pointer ml-6"
                    @click="setInvoicePay(0)"
                  >
                    <svg
                      class="fill-gray-400 w-4 h-4 group-hover:fill-rose-500 transition-colors duration-300"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a>
                </div>
              </template>
            </template>
            <template v-else>
              <div class="flex px-5 py-3 items-center max-l:flex-wrap">
                <div class="flex justify-center flex-wrap max-l:w-full">
                  <a
                    class="w-max v-btn v-btn--md bg-green-500 hover:bg-green-400 m-2"
                    :class="{
                      '!bg-gray-500 hover:!bg-gray-400': invoice.pay != 1,
                    }"
                    @click="setInvoicePay(1)"
                  >
                    <span class="v-btn_text flex items-center">
                      <svg class="fill-white w-16px h-16px mr-3">
                        <use xlink:href="@/assets/i.svg#pay-1"></use>
                      </svg>
                      <span>Наличные взять</span>
                    </span>
                  </a>
                  <a
                    class="w-max v-btn v-btn--md bg-green-500 hover:bg-green-400 m-2"
                    :class="{
                      '!bg-gray-500 hover:!bg-gray-400': invoice.pay != 4,
                    }"
                    @click="setInvoicePay(4)"
                  >
                    <span class="v-btn_text flex items-center">
                      <svg class="fill-white w-16px h-16px mr-3">
                        <use xlink:href="@/assets/i.svg#pay-2"></use>
                      </svg>
                      <span>Безнал предоплата</span>
                    </span>
                  </a>
                  <a
                    class="w-max v-btn v-btn--md bg-green-500 hover:bg-green-400 m-2"
                    :class="{
                      '!bg-gray-500 hover:!bg-gray-400': invoice.pay != 2,
                    }"
                    @click="setInvoicePay(2)"
                  >
                    <span class="v-btn_text flex items-center">
                      <svg class="fill-white w-16px h-16px mr-3">
                        <use xlink:href="@/assets/i.svg#pay-1"></use>
                      </svg>
                      <span>Наличные предоплата</span>
                    </span>
                  </a>
                  <a
                    class="w-max v-btn v-btn--md bg-green-500 hover:bg-green-400 m-2"
                    :class="{
                      '!bg-gray-500 hover:!bg-gray-400': invoice.pay != 7,
                    }"
                    @click="setInvoicePay(7)"
                  >
                    <span class="v-btn_text flex items-center">
                      <svg class="fill-white w-16px h-16px mr-3">
                        <use xlink:href="@/assets/i.svg#pay-3"></use>
                      </svg>
                      <span>С отсрочкой</span>
                    </span>
                  </a>
                </div>
              </div>
            </template>
          </div>

          <div class="double-flex">
            <div class="w-[100%] p-5 bg-white rounded-[4px] mt-4">
              <div class="flex items-center max-l:flex-wrap">
                <div class="flex items-center max-l:flex-wrap">
                  <span
                    class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4"
                  >
                    <svg class="fill-white w-5 h-5">
                      <use xlink:href="@/assets/i.svg#ico-timer"></use>
                    </svg>
                  </span>
                  <span class="text-base font-latobold uppercase"
                    >Задачи:
                  </span>
                  <div
                    v-for="item in invoice.tasks"
                    :key="item"
                    class="preview ml15"
                  >
                    <a
                      class="ml-2 text-base font-latobold cursor-pointer hover:text-green-500 transition-colors duration-300"
                      :class="statusClasses[item.status]"
                      @click="showTask(item)"
                      >№{{ item.iid }}</a
                    >
                  </div>
                </div>

                <button
                  class="v-btn v-btn--sm bg-green-500 hover:bg-green-400 ml-auto"
                >
                  <span
                    class="v-btn_text flex items-center"
                    @click="
                      tryAddTask({
                        object: 'invoice',
                        oid: invoice.id,
                        type: 105,
                        department: 1,
                      })
                    "
                  >
                    <svg
                      class="fill-white w-16px h-16px mr-3 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linear"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                    <span>ЗАДАЧА</span>
                  </span>
                </button>
              </div>
            </div>

            <!-- <div class="w-[50%] ml-2 p-5 bg-white rounded-[4px] mt-4">
                <div class="flex items-center max-l:flex-wrap">
                  <div class="flex items-center max-l:flex-wrap">
                    <span
                      class="rounded-full h-11 min-w-[2.75rem] w-11 bg-blue-500 flex items-center justify-center mr-4"
                    >
                      <svg class="fill-white w-5 h-5">
                        <use xlink:href="@/assets/i.svg#ico-phone"></use>
                      </svg>
                    </span>
                    <span class="text-base font-latobold uppercase"
                      >Телефония:
                    </span>
                    <span class="text-base font-latobold uppercase ml-4"
                      >8 800 000 00 00</span
                    >
                  </div>
                </div>
              </div> -->
          </div>

          <div v-if="invoice.pay == 7" class="double-flex">
            <div class="w-[100%] p-5 bg-white rounded-[4px] mt-4">
              <div class="flex items-top max-l:flex-wrap">
                <div class="flex flex-col items-start max-l:flex-wrap">
                  <div class="flex items-center">
                    <span
                      :class="{
                        'bg-red-500': invoice.pay_status == 1,
                        'bg-green-500': invoice.pay_status == 6,
                      }"
                      class="rounded-full h-11 min-w-[2.75rem] w-11 flex items-center justify-center mr-4"
                    >
                      <svg class="fill-white w-5 h-5">
                        <use xlink:href="@/assets/i.svg#ico-timer"></use>
                      </svg>
                    </span>
                    <span class="text-base font-latobold uppercase"
                      >Гарантия оплаты:
                    </span>
                  </div>
                  <div class="flex flex-wrap text-center mt-6">
                    <template v-for="i in invoice.images" :key="i">
                      <IcoImg :img="i.img" :status="i.status" />
                    </template>
                  </div>
                </div>

                <div
                  style="align-items: center"
                  class="flex flex-col justify-center ml-auto"
                >
                  <div>
                    <input
                      :disabled="
                        !['Manager', 'Studio', 'Hosd'].includes(role) ||
                        invoice.pay_status == 6
                      "
                      type="file"
                      id="invoiceGarand1"
                      class="absolute invisible opacity-0"
                      @change="
                        UploadAndEmit({
                          e: $event,
                          item: invoice,
                          id: invoice.id,
                          status: 40,
                        })
                      "
                    />

                    <label
                      for="invoiceGarand1"
                      :class="{
                        'bg-gray-400 hover:bg-gray-500':
                          !['Manager', 'Studio', 'Hosd'].includes(role) ||
                          invoice.pay_status == 6,
                        'bg-emerald-500 hover:bg-emerald-400':
                          ['Manager', 'Studio', 'Hosd'].includes(role) &&
                          invoice.pay_status != 6,
                      }"
                      class="flex items-center text-[12px] text-white font-latobold cursor-pointer rounded-md w-[230px] mb-2 h-10"
                    >
                      <span
                        class="flex items-center justify-center h-10 w-10 mr-4 border-r-[1px] border-white"
                      >
                        <svg class="fill-white w-4 h-4">
                          <use xlink:href="@/assets/i.svg#download"></use>
                        </svg>
                      </span>
                      <span class="mr-4">Загрузить гарантию</span>
                    </label>
                  </div>

                  <!-- <button
                    class="v-btn v-btn--sm bg-orange-500 hover:bg-orange-400 w-[230px] mb-2"
                  >
                    <span
                      class="v-btn_text flex items-center"
                      @click="
                        tryAddTask({
                          object: 'invoice',
                          oid: invoice.id,
                          type: 105,
                          department: 1,
                        })
                      "
                    >
                      <span>Загрузить гарантию</span>
                    </span>
                  </button> -->

                  <button
                    :class="{
                      'bg-gray-400 hover:bg-gray-500':
                        !['Finance', 'Studio', 'Hosd'].includes(role) ||
                        invoice.pay_status == 6,
                      'bg-green-500 hover:bg-green-400':
                        ['Finance', 'Studio', 'Hosd'].includes(role) &&
                        invoice.pay_status != 6,
                    }"
                    class="v-btn v-btn--sm w-[230px] mb-2"
                  >
                    <span
                      v-if="
                        ['Finance', 'Studio', 'Hosd'].includes(role) &&
                        invoice.pay_status != 6
                      "
                      class="v-btn_text flex items-center"
                      @click="AcceptPay()"
                    >
                      <svg
                        class="fill-white w-16px h-16px mr-3 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linear"
                      >
                        <use xlink:href="@/assets/i.svg#ico-no"></use>
                      </svg>
                      <span>Подтвердить</span>
                    </span>
                    <span v-else class="v-btn_text flex items-center">
                      <svg
                        class="fill-white w-16px h-16px mr-3 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linear"
                      >
                        <use xlink:href="@/assets/i.svg#ico-no"></use>
                      </svg>
                      <span>Подтвердить</span>
                    </span>
                  </button>

                  <button
                    :class="{
                      'bg-gray-400 hover:bg-gray-500':
                        !['Finance', 'Studio', 'Hosd'].includes(role) ||
                        invoice.pay_status == 6,
                      'bg-red-500 hover:bg-red-400':
                        ['Finance', 'Studio', 'Hosd'].includes(role) &&
                        invoice.pay_status != 6,
                    }"
                    class="v-btn v-btn--sm w-[230px]"
                  >
                    <span
                      v-if="
                        ['Finance', 'Studio', 'Hosd'].includes(role) &&
                        invoice.pay_status != 6
                      "
                      class="v-btn_text flex items-center"
                      @click="DeclinePay()"
                    >
                      <svg
                        class="fill-white w-16px h-16px mr-3 group-hover:-rotate-45 duration-100 transition ease-linear"
                      >
                        <use xlink:href="@/assets/i.svg#ico-no"></use>
                      </svg>
                      <span>Отказать</span>
                    </span>
                    <span v-else class="v-btn_text flex items-center">
                      <svg
                        class="fill-white w-16px h-16px mr-3 group-hover:-rotate-45 duration-100 transition ease-linear"
                      >
                        <use xlink:href="@/assets/i.svg#ico-no"></use>
                      </svg>
                      <span>Отказать</span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="invoiceOrders?.length > 0" class="bg-white rounded-lg">
            <div class="p-5 flex justify-between items-center v-bbgray">
              <h3 class="text-base font-latobold w-full">
                Заказы {{ invoiceOrders?.length }}
                <span class="ml-6"
                  >(Всего услуг {{ countOrderPrices() }} на сумму
                  {{ sumOrderPrices() }} ₽)</span
                >
              </h3>

              <button
                @click="checkInvoicePay({ invoice_id: invoice.id })"
                class="v-btn v-btn--sm v-btn_text bg-green-500 hover:bg-green-400 ml-auto"
              >
                Проверить оплаты
              </button>
            </div>

            <div class="grid grid-cols-6 px-5 py-2 v-bbgray">
              <p class="text-gray-400 uppercase text-[11px]">Заказ</p>
              <p class="text-gray-400 uppercase text-[11px]">Дата создания</p>
              <p class="text-gray-400 uppercase text-[11px]">Услуг</p>
              <p class="text-gray-400 uppercase text-[11px]">Сумма</p>

              <p class="text-gray-400 uppercase text-[11px]">ТС</p>
              <p class="text-gray-400 uppercase text-[11px]">Компания</p>
            </div>

            <template v-for="order in invoiceOrders" :key="order">
              <div
                class="px-5 py-2 grid grid-cols-6 v-bbgray transition duration-300 cursor-pointer hover:shadow-v1 bg-teal-light odd:bg-white items-center relative"
                :id="'ord' + order.iid"
                :class="'line hvr status-' + order.status"
              >
                <div class="flex items-center" @click="showOrder(order)">
                  <span
                    class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4 text-lg text-white"
                    :class="'!bg-status-' + order.status"
                  >
                    <span class="w-5 h-5">
                      <IcoStatus :status="parseInt(order.status)" />
                    </span>
                  </span>
                  <span class="font-latobold text-base">№{{ order.iid }}</span>
                </div>
                <!-- <div>
                  <div class="w-5 h-5" v-if="order.r_id" title="рекламация">
                    <svg class="w-full h-full ml-2 fill-black">
                      <use xlink:href="@/assets/i.svg#status-19"></use>
                    </svg>
                  </div>
                </div> -->
                <!-- <div>
                  <div v-if="order.category == 1" class="w-5 h-5">
                    <svg class="w-full h-full ml-2">
                      <use xlink:href="@/assets/i.svg#nav-service"></use>
                    </svg>
                  </div>
                  <div v-if="order.category == 2" class="w-5 h-5">
                    <svg class="w-full h-full ml-2">
                      <use xlink:href="@/assets/i.svg#ico-time"></use>
                    </svg>
                  </div>
                </div> -->
                <div>
                  <p class="flex flex-col">
                    <span>{{
                      moment(order.created_at).format("DD.MM.YYYY")
                    }}</span>
                  </p>
                </div>

                <div
                  v-if="order.prices.length > 0"
                  class="v-gray font-latobold text-base"
                >
                  {{ order.prices.length }} шт.
                </div>
                <div v-else class="v-gray font-latobold text-base">
                  нет услуг
                </div>
                <div class="v-gray font-latobold text-base">
                  {{ order.sum }} ₽
                </div>

                <div class="v-gray font-latoblod-serif">
                  <span
                    v-if="order.num"
                    class="text-slate-600 w-auto rounded-sm v-tsnum px-2 py-1 text-base"
                    >{{ order.num }}</span
                  >
                </div>
                <div class="v-gray font-latoblod-serif">
                  <span v-if="order.company" class="text-slate-600">{{
                    order.company
                  }}</span>
                </div>
                <div
                  v-if="
                    parseInt(order.status) == 8 && parseInt(order.qcheck) > 0
                  "
                  class="flex items-center absolute top-1/2 -translate-y-1/2 right-0 h-14 overflow-hidden max-w-[20%] p-3 pl-5"
                >
                  <span
                    class="text-white z-20 relative text-lg font-latobold"
                    id="timer"
                    >{{ order.qcheck }}</span
                  >
                  <svg class="absolute top-0 -left-8 h-full fill-status-6">
                    <use xlink:href="@/assets/bg.svg#bg-timer"></use>
                  </svg>
                </div>
              </div>
            </template>
          </div>

          <div class="p-5 bg-white mt-4 rounded-[4px]">
            <CommentList :id="parseInt(invoice.id)" type="invoice" />
          </div>

          <div
            v-if="otkaz || invoice.status == 4"
            class="p-5 bg-white mt-4 rounded-[4px]"
          >
            <div v-if="invoice.status == 4" class="flex items-center">
              <div
                class="min-w-[1.5rem] w-7 h-7 rounded-full bg-rose-500 flex items-center justify-center mr-4"
              >
                <svg class="w-4 h-4 fill-white">
                  <use xlink:href="@/assets/i.svg#ico-no"></use>
                </svg>
              </div>
              <div>
                <span class="blocktext-xs text-gray-800 font-latobold">{{
                  cancel[invoice.cancel_id]
                }}</span>
                <span
                  v-if="invoice.comment"
                  class="blocktext-xs text-gray-800 font-latobold block"
                  >{{ invoice.comment }}</span
                >
              </div>
            </div>

            <div v-else class="flex flex-col justify-center">
              <h3 class="text-red-500 text-center">Укажите причину отказа</h3>
              <select
                class="w-full px-4 py-2 bg-white v-bgray rounded-md mb-3"
                v-model="cancel_id"
              >
                <option value="0" disabled selected>Выбрать причину</option>
                <option :value="key" v-for="(val, key) in cancel" :key="val">
                  {{ val }}
                </option>
              </select>
              <textarea
                type="text"
                class="w-full px-4 py-2 bg-white v-bgray rounded-md"
                v-model="invoice.comment"
                placeholder="Комментарий"
              ></textarea>
              <button
                v-if="parseInt(cancel_id) > 0"
                class="v-btn v-btn--sm v-btn--sm bg-green-500 hover:bg-green-400 ml-auto mt-3"
                @click="
                  sendInvoiceCancel({
                    id: invoice.id,
                    cancel_id: cancel_id,
                    comment: invoice.comment,
                  })
                "
              >
                <span class="v-btn_text flex items-center">
                  <svg class="fill-white w-16px h-16px mr-3">
                    <use xlink:href="@/assets/i.svg#ico-telegram"></use>
                  </svg>
                  <span>ОТПРАВИТЬ</span>
                </span>
              </button>
            </div>
          </div>

          <div class="flex justify-around mt-4">
            <button
              v-if="![4].includes(invoice.status)"
              @click="tryToCreateOrder(invoice)"
              class="v-btn v-btn--sm bg-emerald-600 hover:bg-emerald-500"
            >
              <span class="v-btn_text flex items-center">
                <svg class="fill-white w-4 h-4 mr-3">
                  <use xlink:href="@/assets/i.svg#nav-flow"></use>
                </svg>
                <span>Создать заказ</span>
              </span>
            </button>
            <template
              v-if="
                ['Manager', 'Studio', 'Hosd'].includes(role) &&
                ![4].includes(invoice.status)
              "
            >
              <!-- <button class="v-btn v-btn--sm bg-emerald-600 hover:bg-emerald-500"> -->
              <button
                class="v-btn v-btn--sm bg-green-600 hover:bg-green-500"
                @click="tryCreateInvoice"
              >
                <span class="v-btn_text flex items-center">
                  <span>Показать счет</span>
                </span>
              </button>
            </template>

            <!-- <template
              v-if="
                ['Manager', 'Studio' , 'Hosd'].includes(role) &&
                ![3].includes(invoice.status)
              "
            >
              <button class="v-btn v-btn--sm bg-orange-600 hover:bg-orange-500">
                <span class="v-btn_text flex items-center">
                  <span>Отправить счет</span>
                </span>
              </button>
            </template> -->

            <template
              v-if="
                ['Manager', 'Studio', 'Hosd'].includes(role) &&
                ![4].includes(invoice.status) &&
                !otkaz
              "
            >
              <button
                class="v-btn v-btn--sm bg-gray-400 hover:bg-gray-300"
                @click="tryCansel"
              >
                <span class="v-btn_text flex items-center">
                  <svg class="fill-white w-4 h-4 mr-3">
                    <use xlink:href="@/assets/i.svg#ico-no"></use>
                  </svg>
                  <span>Отказ</span>
                </span>
              </button>
            </template>

            <!-- <template
                v-if="
                  ['Manager', 'Studio' , 'Hosd'].includes(role) &&
                  ![8, 11].includes(invoice.status) &&
                  !otkaz
                "
              >
                <button
                  class="v-btn v-btn--sm bg-rose-500 hover:bg-rose-400"
                  @click="tryDelLead"
                >
                  <span class="v-btn_text flex items-center">
                    <svg class="fill-white w-4 h-4 mr-3">
                      <use xlink:href="@/assets/i.svg#ico-delete"></use>
                    </svg>
                    <span>Удалить</span>
                  </span>
                </button>
              </template> -->
          </div>
        </div>
      </div>

      <div
        v-if="s.popup == 'task'"
        id="popnew"
        class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-max overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
      >
        <TaskEdit @close="close" />
      </div>

      <div
        v-if="s.popup"
        @click="close"
        id="overlay"
        class="fixed bg-cyan-900 z-2 w-screen h-screen top-0 left-0 opacity-80"
      ></div>

      <div
        v-if="s.popup == 'price'"
        id="popnew"
        class="w-2/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
      >
        <a
          class="flex items-center bg-gray-500 px-4 py-2 absolute top-0 right-0 rounded-close cursor-pointer hover:bg-gray-400 transition-colors duration-150"
          @click="s.popup = false"
        >
          <span class="text-white mr-2">Закрыть</span>
          <svg class="fill-white w-4 h-4">
            <use xlink:href="@/assets/i.svg#ico-no"></use>
          </svg>
        </a>
        <div class="flex text-center my-8 flex-col px-4">
          <h3 class="text-lg text-black">Изменить стоимость услуги</h3>

          <div class="flex flex-col">
            <div class="flex flex-col items-center mt-6">
              <span class="text-gray-400 leading-none">Текущая стоимость</span>
              <span class="text-black text-lg"
                >{{ editPrice.cost }}&nbsp;&#8381;</span
              >
            </div>

            <div class="flex justify-around mt-4">
              <div class="flex flex-col items-center">
                <span class="text-gray-400 leading-none">Минимальная</span>
                <span class="text-rose-500 text-sm"
                  >{{ editPrice.price.minprice }} &nbsp;&#8381;</span
                >
              </div>
              <div class="flex flex-col items-center">
                <span class="text-gray-400 leading-none">Розничная</span>
                <span class="text-green-500 text-lg"
                  >{{ editPrice.price.sum }} &nbsp;&#8381;</span
                >
              </div>
              <div class="flex flex-col items-center">
                <span class="text-gray-400 leading-none">максимальная</span>
                <span class="text-sky-500 text-lg"
                  >{{ editPrice.price.maxprice }} &nbsp;&#8381;</span
                >
              </div>
            </div>

            <div class="flex flex-col my-5 items-center">
              <input
                type="text"
                class="w-1/2 bg-slate-50 h-9 px-3 py-2 v-bgray rounded-sm text-sm text-teal-700 mr-4"
                v-model="new_price"
                placeholder="Новая цена"
              />
            </div>
            <button
              class="w-max v-btn bg-green-500 hover:bg-green-400 my-2 mx-auto"
              @click="
                newInvoicePrice({
                  price_id: editPrice.id,
                  sum: new_price,
                }),
                  (new_price = null),
                  (s.popup = false)
              "
            >
              <span class="v-btn_text text-white">Подтвердить</span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import ClientEdit from "../components/client/ClientEdit";
import ContactEdit from "../components/client/ContactEdit";
import CommentList from "../components/CommentList.vue";
import IcoPic from "../components/ui/IcoPic.vue";
import IcoInvoiceStatus from "../components/ui/IcoInvoiceStatus.vue";

import Datepicker from "@vuepic/vue-datepicker";

const com = {
  5: true, //Звонок
  6: true, //Письмо
  7: true, //Пришел в офис
  8: true, //Заявка через ЛК агента СРМ
  13: true, // "Заявка с сайта",
};
const leadsByOther = {
  1: {
    //ПЕРВИЧНЫЙ
    1: {
      //ВХОДЯЩИЙ
      1: {
        //Интернет
        0: com /*{
                        1: true,
                        2: true,
                    }*/,
      },
      2: {
        //Рекомендация
        0: com /*{
                        4: true,
                        5: true,
                    }*/,
      },
      3: {
        //Агент
        0: com /*{
                        5: true,
                        6: true,
                        7: true,
                    }*/,
      },
    },
    2: {
      //ИСХОДЯЩИЙ
      4: {
        //Обзвон новой базы
        0: com /*{
                        5: true,
                        9: true,
                    }*/,
      },
    },
  },
  2: {
    //ВТОРИЧНЫЙ
    1: {
      //ВХОДЯЩИЙ
      21: {
        //Вторичная клиентская база
        /*1: {//Наименование клиента
                        5: true, //Звонок
                        6: true, //Письмо
                        7: true, //Пришел в офис
                        8: true, //Заявка через ЛК агента СРМ
                    },
                    6: {//Обзвон клиентов по контролю качества
                        12: true, //Заявка из отзыва с рейтингом
                    }*/
        0: com,
      },
      22: {
        //Рассылка
        /*2: {//Ниатменование рассылки
                        5: true, //Звонок
                        6: true, //Письмо
                        8: true, //Заявка через ЛК агента СРМ
                    },
                    6: {//Обзвон клиентов по контролю качества
                        12: true, //Заявка из отзыва с рейтингом
                    }*/
        0: com,
      },
      /* 23: {//Агент-сотрудник
                    3: {//ФИО агента-сотрудника
                        8: true, //Заявка через ЛК агента СРМ
                    },
                    6: {//Обзвон клиентов по контролю качества
                        12: true, //Заявка из отзыва с рейтингом
                    }
                }*/
    },
    2: {
      //ИСХОДЯЩИЙ
      21: {
        //Вторичная клиентская база
        4: {
          //Пролонгация заказа, Задача "Пролонгация"
          //5: true, //Звонок
          ...com,
        },
        5: {
          //Обзвон неактивных клиентов
          //5: true, //Звонок
          ...com,
        },
        6: {
          //Обзвон клиентов по контролю качества
          //12: true, //Заявка из отзыва с рейтингом
          ...com,
        },
      },
    },
  },
};

function extract(o, k, ...p) {
  var v = o[k];
  if (!v) {
    var s = new Set();
    Object.values(o).forEach((x) => {
      if (p.length) {
        extract(x, ...p).forEach((a) => s.add(a));
      } else {
        Object.keys(x).forEach((a) => s.add(a));
      }
    });
    return [...s];
  }
  if (p.length) return extract(v, ...p);
  else return Object.keys(v);
}
const statusClasses = {
  1: "new_lead_task",
  2: "robot_lead_task",
  3: "appoint_lead_task",
  4: "started_lead_task",
  5: "done_lead_task",
  6: "finished_lead_task",
  19: "violate_lead_task",
};
export default {
  name: "LeadEdit",
  components: {
    ContactEdit,
    CommentList,
    IcoPic,
    IcoInvoiceStatus,
    ClientEdit,
    Datepicker,
  },

  data() {
    return {
      showTouches: false,
      showLeads: false,
      showZayavka: false,
      showSchet: false,
      showAnketa: false,
      showinvoice: false,
      showOrderLet: false,
      new_price: "",

      moment: moment,
      direction: { 1: "ВХОДЯЩИЙ", 2: "ИСХОДЯЩИЙ" },
      type: { 1: "НОВЫЙ", 2: "ВТОРИЧНЫЙ" },
      detail: {
        101: 'Задача "Как дела"',
        102: 'Задача "Пролонгация"',
        103: "Контроль качества",
        104: "Агент-сотрудник",
      },
      channel: {
        1: "Интернет",
        2: "Рекомендация",
        3: "Агент",
        4: "Обзвон новой базы",
        15: "Обзвон новой базы",
        21: "Вторичная клиентская база",
        22: "Рассылка",
        23: "Агент-сотрудник",
        //31: "Вторичная клиентская база",
      },
      cancel: {
        101: "Нецелевой счет: другой регион",
        102: "Нецелевой счет: б/у оборудование",
        103: "Нецелевой счет: экономически нецелесообразно",
        104: "Нецелевой счет: карты тахографа",
        105: "Нецелевой счет: консультация",
        106: "Нецелевой счет: недозвон",
        201: "Целевой счет: дорого",
        202: "Целевой счет: другое",
        //203: "другое",
      },
      communications: {
        1: "Интернет контекст звонок",
        2: "Интернет контекст заявка с сайта",
        3: "Интернет СЕО звонок",
        4: "Интернет СЕО заявка с сайта",
        5: "Звонок",
        6: "Письмо",
        7: "Пришел в офис",
        8: "Заявка через ЛК агента СРМ",
        9: "Рассылка/Звонок",
        10: "Заявка через ЛК клиента в СРМ",
        11: "Заявка через ЛК агента-сотрудника",
        12: "Заявка из отзыва с рейтингом",
        13: "Заявка с сайта",
      },
      channelDetailType: {
        1: "Наименование клиента",
        2: "Ниатменование рассылки",
        3: "ФИО агента-сотрудника",
        4: 'Пролонгация заказа, Задача "Пролонгация"',
        5: "Обзвон неактивных клиентов",
        6: "Обзвон клиентов по контролю качества",
        7: "Интернет контекст",
        8: "Интернет СЕО",
        9: "Наименование клиента",
        10: "Наименование агента",
        11: "Вид базы",
      },
      b: {
        1: "Логистический бизнес (перевозки)",
        2: "Строительный бизнес, спецтехника",
        3: "Среднегабаритная перевозка (городские перевозки)",
        4: "Собственная доставка (свой транспортный отдел)",
        5: "Другое",
      },
      business: "",
      business_dop: "",
      ts: "",
      sum: "",
      otkaz: false,
      showCreateInvoice: false,
      cancel_id: 0,
      text_client: "",
      text_company: "",
      text_contact: "",
      text_price: "",
      text_comment: "",
      text_manager: "",
      explaintext: "",
      editPrice: {},
      timer: { time: null, color: null },
      companyList: [],
      contactList: [],
      priceList: [],
      managerList: [],
      st: {
        1: "Новый",
        2: "Выставлен",
        3: "Оплачен",
        4: "ОТКАЗ",
      },
      newClient: {},
      showClientEdit: false,
      statusClasses: statusClasses,
      canShowGar: false,
    };
  },

  computed: {
    ...mapGetters([
      "s",
      "profile",
      "role",
      "garland",
      "roles",
      "invoice",
      "prices_bid",
      "firms",
      "selectInvoiceClients",
      "selectInvoiceCompanies",
      "selectInvoiceContacts",
      "selectManagers",
      "invoiceOrders",
    ]),
    availableChannels() {
      return extract(leadsByOther, this.invoice.type, this.invoice.direction);
    },

    availableChannelDetails() {
      return extract(
        leadsByOther,
        this.invoice.type,
        this.invoice.direction,
        this.invoice.channel
      );
    },

    availableCommunicationTypes() {
      return extract(
        leadsByOther,
        this.invoice.type,
        this.invoice.direction,
        this.invoice.channel,
        this.invoice.channel_detail_type
      );
    },
    canEditClient() {
      return ["Manager", "Logist", "Studio", "Check"].includes(this.role);
    },
    canShowClient() {
      //   return [this.canEditClient, this.invoice.direction, this.invoice.type == 2].every(
      //     Boolean
      //   );
      return true;
    },
    canAddClient() {
      //   return [this.canEditClient, this.invoice.direction, this.invoice.type == 1].every(
      //     Boolean
      //   );
      return [
        this.canEditClient,
        this.invoice.direction,
        this.invoice.type == 1,
      ].every(Boolean);
    },
    canShowChannel() {
      return [
        this.invoice.direction && this.invoice.direction != 0,
        this.invoice.type && this.invoice.type != 0,
      ].every(Boolean);
    },
    canShowChannelDetailType() {
      return 21 == this.invoice.channel && 2 == this.invoice.direction;
    },
    canShowChannelDetailValue() {
      return ["4", "5", "6"].includes(this.invoice.channel_detail_type);
    },
    canShowCommunicationType() {
      return (
        [1, 2, 3, 4, 15, 21, 22, 23].includes(this.invoice.channel) &&
        this.invoice.direction == 1
      );
    },
    canCloseInvoice() {
      var set = new Set(this.invoice.tasks.map((x) => x.status));
      return ![2, 3, 4].some((x) => set.has(x));
    },
  },

  methods: {
    ...mapActions([
      "getInvoice",
      "setInvoice",
      "getGarladnsInvoice",
      "kindOfLead",
      "findLeadClient",
      "setInvoiceClient",
      "delInvoiceClient",
      "addInvoiceClient",
      "clearLeadContactsCars",
      "setInvoiceCompany",
      "delInvoiceCompany",
      "setInvoiceContact",
      "delInvoiceContact",
      "updContact",
      "delInvoice",
      "setClient",
      "setClientContact",
      "showClient",
      "showContact",
      "setInvoiceManager",
      "delInvoiceManager",
      "setSettings",
      "addTask",
      "showTask",
      "sendCancel",
      "addinvoice",
      "editinvoiceId",
      "setClientBusiness",
      "changeBPL",
      "setInvoiceChannel",
      "setInvoiceDirection",
      "setInvoiceType",
      "setInvoiceDetail",
      "setClientTs",
      "setInvoiceSum",
      "setEvent1S",
      "setInvoiceChannelDetailType",
      "setInvoiceChannelDetailValue",
      "setInvoiceCommunicationType",
      "addClientByLead",
      "goToTouchFromLead",
      "goToLeadFromTouch",
      "createInvoiceFromLead",
      "getFirms",
      "setFormForInvoice",
      "delInvoiceFirm",
      "getInvoicePrices",

      "sendInvoiceCancel",
      "setInvoicePrice",
      "delInvoicePrice",
      "newInvoicePrice",
      "setCountInvoicePrice",
      "createInvoiceFromInvoice",
      "startInvoice",
      "goToBidNow",
      "saveDateInvoice",
      "setInvoicePay",
      "createInvoiceFromInvoice",
      "setInvoiceField",
      "setInvoiceCode",
      "UploadInvoice",
      "AcceptPay",
      "DeclinePay",
      "getInvoiceOrders",
      "showOrder",
      "setInvoiceCodebill",
      "setInvoiceCRMNumber",
      "CreateOrderFromInvoice",
      "goToOrderNow",
      "checkInvoicePay",
    ]),
    async UploadAndEmit(data) {
      let result = await this.UploadInvoice(data);
      console.log("Загрузили", result);
    },
    startInvoiceFunc() {
      this.startInvoice();
    },

    tryToCreateOrder(invoice) {
      if (invoice.status > 1) {
        this.CreateOrderFromInvoice(invoice);
      } else {
        alert("Для создания заказа, выберите метод оплаты!");
      }
    },

    showCreateInvoiceBlock() {
      if (
        (this.invoice.taho == false || this.invoice.taho == null) &&
        (this.invoice.monitor == false || this.invoice.monitor == null) &&
        (this.invoice.card == false || this.invoice.card == null) &&
        (this.invoice.propusk == false || this.invoice.propusk == null) &&
        (this.invoice.teach == false || this.invoice.teach == null)
      ) {
        alert("Нельзя создать заявку, так как не выбран ее тип");
        return 0;
      }
      if (this.canCloseInvoice) {
        this.showCreateInvoice = !this.showCreateInvoice;
      } else {
        alert("Нельзя создать заявку если задачи не завершены");
        return 0;
      }
    },

    countOrderPrices() {
      let countPrices = 0;
      for (let i = 0; i < this.invoiceOrders.length; i++) {
        countPrices += this.invoiceOrders[i].prices.length;
      }
      return countPrices;
    },
    sumOrderPrices() {
      let sumOrderPrices = 0;
      for (let i = 0; i < this.invoiceOrders.length; i++) {
        sumOrderPrices += this.invoiceOrders[i].sum;
      }
      return sumOrderPrices;
    },
    // createInvoice(parent_id) {
    //   this.s.popup = null;
    //   this.createInvoiceFromLead(parent_id);
    // },

    dClient() {
      this.contactList = null;
      this.tsList = null;
      return this.delInvoiceClient();
    },

    close() {
      this.s.popup = null;
      this.getInvoice();
    },

    closeContact() {
      this.getInvoice();
      this.s.popup = null;
    },

    // КОМПАНИЯ
    findCompany() {
      // console.log("findCompany: ", this.text_company);
      if (this.text_company != "") {
        this.companyList = this.selectInvoiceCompanies.filter((item) => {
          console.log("item", item);
          let name = null;
          let full = null;
          if (item.name != null) {
            name =
              item.name
                .toLowerCase()
                .indexOf(this.text_company.toLowerCase()) !== -1;
          }
          if (item.full != null) {
            full =
              item.full
                .toLowerCase()
                .indexOf(this.text_company.toLowerCase()) !== -1;
          }
          if (name || full) {
            return true;
          }
          return false;
        });
      } else {
        this.companyList = this.selectInvoiceCompanies;
      }
    },

    pickCompany(item) {
      this.setInvoiceCompany(item);
      this.text_contact = "";
    },

    // КОНТАКТ
    findContact() {
      // console.log("findContact: ", this.text_contact);
      if (this.text_contact != "") {
        this.contactList = this.selectInvoiceContacts.filter((item) => {
          console.log("item", item);
          let name = null;
          let secondname = null;
          if (item.name != null) {
            name =
              item.name
                .toLowerCase()
                .indexOf(this.text_contact.toLowerCase()) !== -1;
          }
          if (item.secondname != null) {
            secondname =
              item.secondname
                .toLowerCase()
                .indexOf(this.text_contact.toLowerCase()) !== -1;
          }
          if (name || secondname) {
            return true;
          }
          return false;
        });
      } else {
        this.contactList = this.selectInvoiceContacts;
      }
    },

    findPrice() {
      // console.log("category_id ", this.cats);
      // this.priceList = this.selectPrices.filter(
      //     (item) => this.cats.includes(parseInt(item.category_id)) && item.name.toLowerCase().indexOf(this.text_price.toLowerCase()) !== -1
      // );
      this.priceList = this.prices_invoice.filter(
        (item) =>
          item.name.toLowerCase().indexOf(this.text_price.toLowerCase()) !== -1
      );
    },

    pickContact(item) {
      this.setInvoiceContact(item);
      this.text_contact = "";
    },
    toggleGarl(garlShow) {
      if (garlShow == "showTouches") {
        this.showTouches = !this.showTouches;
        this.showLeads = false;
        this.showZayavka = false;
        this.showSchet = false;
        this.showAnketa = false;
        this.showOrderLet = false;
        this.showinvoice = false;
      }
      if (garlShow == "showLeads") {
        this.showTouches = false;
        this.showLeads = !this.showLeads;
        this.showZayavka = false;
        this.showSchet = false;
        this.showAnketa = false;
        this.showOrderLet = false;
        this.showinvoice = false;
      }
      if (garlShow == "showZayavka") {
        this.showTouches = false;
        this.showZayavka = !this.showZayavka;
        this.showSchet = false;
        this.showAnketa = false;
        this.showLeads = false;
        this.showOrderLet = false;
        this.showinvoice = false;
      }
      if (garlShow == "showSchet") {
        this.showTouches = false;
        this.showSchet = !this.showSchet;
        this.showAnketa = false;
        this.showinvoice = false;
        this.showLeads = false;
        this.showOrderLet = false;
        this.showZayavka = false;
      }
      if (garlShow == "showAnketa") {
        this.showTouches = false;
        this.showAnketa = !this.showAnketa;
        this.showLeads = false;
        this.showZayavka = false;
        this.showSchet = false;
        this.showinvoice = false;
        this.showOrderLet = false;
      }
      if (garlShow == "showinvoice") {
        this.showTouches = false;
        this.showinvoice = !this.showinvoice;
        this.showLeads = false;
        this.showZayavka = false;
        this.showSchet = false;
        this.showAnketa = false;
        this.showOrderLet = false;
      }
      if (garlShow == "showOrderLet") {
        this.showTouches = false;
        this.showinvoice = false;
        this.showLeads = false;
        this.showZayavka = false;
        this.showSchet = false;
        this.showAnketa = false;
        this.showOrderLet = !this.showOrderLet;
      }
    },
    showContactfromLead(contact) {
      this.s.back = "/invoice/edit";
      this.setSettings(this.s);
      this.showContact(contact);
    },

    findManager() {
      this.managerList = this.selectManagers.filter(
        (item) =>
          item.name.toLowerCase().indexOf(this.text_manager.toLowerCase()) !==
          -1
      );
    },

    pickPrice(item) {
      this.setInvoicePrice(item);
      this.priceList = "";
      this.text_price = "";
      if (item.act == 1) {
        this.anketa = 1;
      }
      if (item.act == 2) {
        this.anketa = 2;
      }
    },

    // invoice() {
    //   let formData = new FormData();
    //   formData.append("invoice", this.invoice.id);
    //   formData.append("client_id", this.invoice.client_id);
    //   this.addinvoice(formData);
    // },

    Tmr() {
      if (this.invoice.timer) {
        let date = moment(this.invoice.timer);
        let now = moment() - moment().utcOffset() * 60000 + 180 * 60000; // временная зона
        this.timer.time = moment.utc(date - now).format("HH:mm:ss");

        let t = date - now;

        if (t > 0) {
          this.timer.color = "1";
        } else {
          this.timer.color = "7";
          t = t * -1;
        }
        let hours = Math.floor(t / (1000 * 60 * 60));
        if (hours < 10) {
          hours = "0" + hours;
        }
        let minutes = Math.floor((t / (1000 * 60)) % 60);
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        let seconds = Math.floor((t / 1000) % 60);
        if (seconds < 10) {
          seconds = "0" + seconds;
        }
        this.timer.time = hours + ":" + minutes + ":" + seconds;
      } else {
        this.timer.time = null;
      }
    },

    extract: extract,
    async trySetLeadDirection(value) {
      if (value == 0) {
        await this.setClient(0);
        await this.trySetLeadChannel(0);
        await this.dClient();
      }
      await this.setLeadDirection(value);
      var channels = extract(
        leadsByOther,
        this.invoice.type,
        this.invoice.direction
      );
      if (channels.length === 1) await this.trySetLeadChannel(channels[0]);
      if (value == 2) await this.setLeadCommunicationType(5);
    },
    async trySetLeadType(value) {
      if (value == 0) {
        await this.setClient(0);
        //await this.trySetLeadChannel(0);
        await this.trySetLeadDirection(0);
      }
      await this.setInvoiceType(value);
    },
    async trySetLeadChannel(value) {
      if (value == 0) {
        console.log(
          "Сбрасываем",
          this.invoice.channel_detail_type,
          this.invoice.channel_detail_value
        );
        -(await this.trySetLeadChannelDetailType(0));
      }
      await this.setInvoiceChannel(value);
      if (this.invoice.direction == 2)
        await this.setInvoiceCommunicationType(5);
    },
    //async trySetLeadChannel(value){},
    async trySetLeadChannelDetailType(value) {
      if (value == 0) {
        console.log("Сам сброс");
        this.invoice.communication_type = 0;
        this.invoice.channel_detail_value = "";
        var rt = await this.setLeadCommunicationType(0);
        var rv = await this.setLeadChannelDetailValue("");
        console.log("Результаты: ", rt.data, rv.data);
      }
      await this.setLeadChannelDetailType(value);
    },
    async addNewClient() {
      var client = await this.addClientByLead();
      console.log("Новый клиент", client);
      await this.setLeadClient(client.id);
      await this.setClient(client);
      this.showClientEdit = true;
    },
    async editNewClient() {
      await this.setClient(this.invoice.client);
      this.showClientEdit = true;
    },
    async clientEditClose() {
      await this.getInvoice();
      this.showClientEdit = false;
    },
    async trySetFirm(id) {
      await this.setFormForInvoice(id);
    },
    async tryDelInvoiceFirm(id) {
      await this.delInvoiceFirm(id);
    },

    tryCreateInvoice() {
      if (this.canCloseInvoice) return this.createInvoiceFromInvoice();
      else alert("Нельзя создать счет если задачи не завершены");
    },
    tryCansel() {
      if (this.canCloseInvoice) this.otkaz = true;
      else alert("Нельзя оформить отказ если задачи не завершены");
    },
    // tryDelLead() {
    //   if (this.canCloseInvoice) return this.delLead();
    //   else alert("Нельзя удалить лид если задачи не завершены");
    // },
    tryAddTask(data) {
      if (this.canCloseInvoice) return this.addTask(data);
      else alert("В лиде должна быть только одна незавершенная задача");
    },
    goToTouch(touch) {
      this.goToTouchFromLead(touch);
    },
    goToLead(lead) {
      this.goToLeadFromTouch(lead);
    },
    goToBid(bid) {
      this.goToBidNow(bid);
    },
    goToOrder(order) {
      this.goToOrderNow(order);
    },
  },

  mounted() {
    this.getInvoice();
    this.getGarladnsInvoice();
    this.getInvoiceOrders();

    window.setInterval(() => {
      this.Tmr();
      if (this.garland != {}) {
        this.canShowGar = true;
      }
    }, 1000);
    // this.getInvoicePrices();

    window.DEBUG_LEAD = this;
  },
};
</script>
<style>
.new_lead_task {
  color: gray;
}
.robot_lead_task {
  color: blue;
}
.appoint_lead_task {
  color: green;
}
.started_lead_task {
  color: darkviolet;
}
.done_lead_task {
  color: brown;
}
.finished_lead_task {
  color: olive;
}
.violate_lead_task {
  color: red;
}
</style>
