<template>
  <div
    v-if="s.popup == 'edit'"
    class="w-3/4 absolute z-20 bg-white rounded-lg top-4 bottom-4"
  >
    <ContactEdit @close="close" />
  </div>
  <div
    v-if="s.popup"
    @click="close"
    id="overlay"
    class="fixed bg-cyan-900 z-2 w-screen h-screen top-0 left-0 opacity-80"
  ></div>

  <template v-if="ClientContacts">
    <div class="bg-white rounded-lg mt-6">
      <div class="p-5 flex justify-between items-center v-bbgray">
        <h3 class="font-latobold text-base">
          Контакты {{ ClientContacts.length }}
        </h3>
      </div>

      <div class="grid grid-cols-[1fr_12rem_8rem_8rem_4rem] px-5 py-2 v-bbgray">
        <p class="text-gray-400 uppercase text-[11px]">ФИО</p>
        <p class="text-gray-400 uppercase text-[11px]">ОТВЕТСТВЕННЫЙ</p>
        <p class="text-gray-400 uppercase text-[11px]">АКТИВАЦИЯ</p>
        <p class="text-gray-400 uppercase text-[11px]">КАБИНЕТ</p>
        <p class="text-gray-400 uppercase text-[11px]"></p>
      </div>
      <div
        v-for="item in ClientContacts"
        :key="item"
        class="grid grid-cols-[1fr_12rem_8rem_8rem_4rem] px-4 py-2 relative group items-center v-bbgray transition duration-300 cursor-pointer hover:shadow-v1 bg-teal-light odd:bg-white"
      >
        <div @click="editContact(item), (this.s.popup = 'edit')">
          <div class="flex items-center group cursor-pointer">
            <div
              class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4 text-lg text-white"
            >
              {{ item.name?.slice(0, 1) }}
            </div>
            <span
              class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
              >{{ item.secondname }} {{ item.name }}</span
            >
          </div>
        </div>

        <div>{{ r[item.role] }}</div>

        <div class="flex">
          <template v-if="item.act == 1">
            <template v-if="item.mhd == 1">
              <svg class="w-5 h-5 fill-green-500 mr-2">
                <use xlink:href="@/assets/i.svg#ico-ok"></use>
              </svg>
              <label for="mhd">МЧД</label>
            </template>
            <template v-else>
              <svg class="w-5 h-5 fill-orange-500 mr-2">
                <use xlink:href="@/assets/i.svg#ico-ok"></use>
              </svg>
              <label for="mhd">АКТИВАЦИЯ</label>
            </template>
          </template>
        </div>

        <div class="text-center">
          <svg v-if="item.lk == 1" class="w-5 h-5 fill-green-500">
            <use xlink:href="@/assets/i.svg#ico-ok"></use>
          </svg>
        </div>
      </div>
    </div>
  </template>

  <div v-else class="loader opacity1 mt30">
    <div><img src="https://api.tahocrm.ru/images/loader.svg" width="44" /></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ContactEdit from "../../components/client/ContactEdit";

export default {
  name: "MyContact",

  components: { ContactEdit },

  data() {
    return {
      r: {
        1: "за тахограф",
        2: "за мониторинг",
        3: "за пропуск",
        4: "за транспорт (все)",
        5: "за карты",
        6: "бухгалтерия",
      },
    };
  },

  computed: {
    ...mapGetters([
      "s",
      "profile",
      "role",
      "roles",
      "client",
      "ClientContacts",
    ]),
  },

  methods: {
    ...mapActions([
      "getClientContacts",
      "showContact",
      "editContact",
      "clearContact",
      "delContact",
      "setSettings",
      "goBack",
    ]),

    close() {
      this.clearContact();
      this.goBack();
    },
  },

  mounted() {
    this.getClientContacts();
  },
};
</script>
