<template>
  <!-- ВЕРХНЯЯ ПАНЕЛЬ -->
  <div class="flex-wrap-report my-5">
    <!-- <input class="date-report" type="date" v-model="dateFrom"/>
                  <input class="date-report" type="date" /> -->
    <!-- <input type="month" class="month-report-top mr-5" v-model="dateFrom" /> -->
    <input type="month" class="month-report-top mr-5" v-model="dateTo" />
    <button @click="formDatShit()" class="button-form-report">
      Сформировать
    </button>

    <div v-if="showRPS" class="flex ml-auto">
      <select
        style="min-width: 240px"
        class="h-9 px-3 ml-auto bg-slate-50"
        @change="changeDirection()"
        v-model="direction"
      >
        <option value="0" disabled>Направление</option>
        <option :value="d.name" v-for="d in directions" :key="d">
          {{ d.name }}
        </option>
      </select>
    </div>
  </div>

  <div
    v-if="showRPS"
    class="flex flex-wrap-report justify-between items-center"
  >
    <div
      class="flex bg-white w-fit py-3 px-8 relative rounded-[8px] items-center"
    >
      <p class="todayDateLabel">сегодня</p>
      <p
        v-if="reportPlanStats.date_data.current_date.substr(5, 2) == '01'"
        class="todayDate ml-16"
      >
        {{ reportPlanStats.date_data.current_date.substr(8, 2) }} января
      </p>
      <p
        v-if="reportPlanStats.date_data.current_date.substr(5, 2) == '02'"
        class="todayDate ml-16"
      >
        {{ reportPlanStats.date_data.current_date.substr(8, 2) }} февраля
      </p>
      <p
        v-if="reportPlanStats.date_data.current_date.substr(5, 2) == '03'"
        class="todayDate ml-16"
      >
        {{ reportPlanStats.date_data.current_date.substr(8, 2) }} марта
      </p>
      <p
        v-if="reportPlanStats.date_data.current_date.substr(5, 2) == '04'"
        class="todayDate ml-16"
      >
        {{ reportPlanStats.date_data.current_date.substr(8, 2) }} апреля
      </p>
      <p
        v-if="reportPlanStats.date_data.current_date.substr(5, 2) == '05'"
        class="todayDate ml-16"
      >
        {{ reportPlanStats.date_data.current_date.substr(8, 2) }} мая
      </p>
      <p
        v-if="reportPlanStats.date_data.current_date.substr(5, 2) == '06'"
        class="todayDate ml-16"
      >
        {{ reportPlanStats.date_data.current_date.substr(8, 2) }} июня
      </p>
      <p
        v-if="reportPlanStats.date_data.current_date.substr(5, 2) == '07'"
        class="todayDate ml-16"
      >
        {{ reportPlanStats.date_data.current_date.substr(8, 2) }} июля
      </p>
      <p
        v-if="reportPlanStats.date_data.current_date.substr(5, 2) == '08'"
        class="todayDate ml-16"
      >
        {{ reportPlanStats.date_data.current_date.substr(8, 2) }} августа
      </p>
      <p
        v-if="reportPlanStats.date_data.current_date.substr(5, 2) == '09'"
        class="todayDate ml-16"
      >
        {{ reportPlanStats.date_data.current_date.substr(8, 2) }} сентября
      </p>
      <p
        v-if="reportPlanStats.date_data.current_date.substr(5, 2) == '10'"
        class="todayDate ml-16"
      >
        {{ reportPlanStats.date_data.current_date.substr(8, 2) }} октября
      </p>
      <p
        v-if="reportPlanStats.date_data.current_date.substr(5, 2) == '11'"
        class="todayDate ml-16"
      >
        {{ reportPlanStats.date_data.current_date.substr(8, 2) }} ноября
      </p>
      <p
        v-if="reportPlanStats.date_data.current_date.substr(5, 2) == '12'"
        class="todayDate ml-16"
      >
        {{ reportPlanStats.date_data.current_date.substr(8, 2) }} декабря
      </p>
      <!-- <p class="workDays">
          <span>{{ reportPlanStats.date_data.passed_working_days }}</span> раб. дн
          из
          <span>{{ reportPlanStats.date_data.count_working_days }}</span>
        </p> -->
    </div>
  </div>
  <!-- ВЕРХНЯЯ ПАНЕЛЬ -->

  <!-- ПРОГРЕССБАРЫ -->
  <div v-if="showRPS" class="flex mt-4">
    <div
      class="bg-white flex flex-row w-1/3 py-4 mr-4 mt-4 relative rounded-[8px] justify-center items-center"
    >
      <div class="relative">
        <div class="line100"></div>

        <div
          :class="{
            yellow:
              (reportPlanStats.plan_facts_data.current_margin_fact /
                reportPlanStats.plan_facts_data.current_margin_plan) *
                100 <
                100 &&
              (reportPlanStats.plan_facts_data.current_margin_fact /
                reportPlanStats.plan_facts_data.current_margin_plan) *
                100 >=
                90,
            red:
              (reportPlanStats.plan_facts_data.current_margin_fact /
                reportPlanStats.plan_facts_data.current_margin_plan) *
                100 <
              90,
            green:
              (reportPlanStats.plan_facts_data.current_margin_fact /
                reportPlanStats.plan_facts_data.current_margin_plan) *
                100 >=
              100,
          }"
          class=""
          role="progressbar"
          :aria-valuenow="
            (reportPlanStats.plan_facts_data.current_margin_fact /
              reportPlanStats.plan_facts_data.current_margin_plan) *
            100
          "
          aria-valuemin="0"
          aria-valuemax="100"
          :style="
            '--value:' +
            (reportPlanStats.plan_facts_data.current_margin_fact /
              reportPlanStats.plan_facts_data.current_margin_plan) *
              100
          "
        ></div>
      </div>

      <div class="">
        <p class="numberToday pt-6">
          {{ addSpace(reportPlanStats.plan_facts_data.current_margin_fact) }}
        </p>
        <p class="numberLabelToday pt-2">маржа: факт на сегодня</p>

        <p class="planText pt-6">
          план на сегодня:
          <span>{{
            addSpace(reportPlanStats.plan_facts_data.current_margin_plan)
          }}</span>
        </p>
        <p class="planText pt-2">
          остаток плана на сегодня:
          <span>{{
            addSpace(
              reportPlanStats.plan_facts_data.current_margin_plan -
                reportPlanStats.plan_facts_data.current_margin_fact
            )
          }}</span>
        </p>
        <p></p>
      </div>
    </div>
    <div
      class="bg-white flex flex-row w-1/3 py-4 mr-4 mt-4 relative rounded-[8px] justify-center items-center"
    >
      <div class="">
        <div class="relative">
          <div class="line100"></div>

          <div
            :class="{
              yellow:
                (reportPlanStats.plan_facts_data.current_income_fact /
                  reportPlanStats.plan_facts_data.current_income_plan) *
                  100 <
                  100 &&
                (reportPlanStats.plan_facts_data.current_income_fact /
                  reportPlanStats.plan_facts_data.current_income_plan) *
                  100 >=
                  90,
              red:
                (reportPlanStats.plan_facts_data.current_income_fact /
                  reportPlanStats.plan_facts_data.current_income_plan) *
                  100 <
                90,
              green:
                (reportPlanStats.plan_facts_data.current_income_fact /
                  reportPlanStats.plan_facts_data.current_income_plan) *
                  100 >=
                100,
            }"
            class=""
            role="progressbar"
            :aria-valuenow="
              (reportPlanStats.plan_facts_data.current_income_fact /
                reportPlanStats.plan_facts_data.current_income_plan) *
              100
            "
            aria-valuemin="0"
            aria-valuemax="100"
            :style="
              '--value:' +
              (reportPlanStats.plan_facts_data.current_income_fact /
                reportPlanStats.plan_facts_data.current_income_plan) *
                100
            "
          ></div>
        </div>
      </div>

      <div class="">
        <p class="numberToday pt-6">
          {{ addSpace(reportPlanStats.plan_facts_data.current_income_fact) }}
        </p>
        <p class="numberLabelToday pt-2">доход: факт на сегодня</p>

        <p class="planText pt-6">
          план на сегодня:
          <span>{{
            addSpace(reportPlanStats.plan_facts_data.current_income_plan)
          }}</span>
        </p>
        <p class="planText pt-2">
          остаток плана на сегодня:
          <span>{{
            addSpace(
              reportPlanStats.plan_facts_data.current_income_plan -
                reportPlanStats.plan_facts_data.current_income_fact
            )
          }}</span>
        </p>
        <p></p>
      </div>
    </div>
    <div
      class="bg-white flex flex-row w-1/3 py-4 mt-4 relative rounded-[8px] justify-center items-center"
    >
      <div class="">
        <div class="relative">
          <div class="line100"></div>

          <div
            :class="{
              yellow:
                (reportPlanStats.plan_facts_data.current_margin_promise /
                  reportPlanStats.plan_facts_data.current_all_margin) *
                  100 <
                  100 &&
                (reportPlanStats.plan_facts_data.current_margin_promise /
                  reportPlanStats.plan_facts_data.current_all_margin) *
                  100 >=
                  90,
              red:
                (reportPlanStats.plan_facts_data.current_margin_promise /
                  reportPlanStats.plan_facts_data.current_all_margin) *
                  100 <
                90,
              green:
                (reportPlanStats.plan_facts_data.current_margin_promise /
                  reportPlanStats.plan_facts_data.current_all_margin) *
                  100 >=
                100,
            }"
            class=""
            role="progressbar"
            :aria-valuenow="
              (reportPlanStats.plan_facts_data.current_margin_promise /
                reportPlanStats.plan_facts_data.current_all_margin) *
              100
            "
            aria-valuemin="0"
            aria-valuemax="100"
            :style="
              '--value:' +
              (reportPlanStats.plan_facts_data.current_margin_promise /
                reportPlanStats.plan_facts_data.current_all_margin) *
                100
            "
          ></div>
        </div>
      </div>

      <div class="">
        <p class="numberToday pt-6">
          {{ addSpace(reportPlanStats.plan_facts_data.current_margin_promise) }}
        </p>
        <p class="numberLabelToday pt-2">маржа, ожидаем оплат</p>

        <p class="planText pt-6">
          план на месяц:
          <span>{{
            addSpace(reportPlanStats.plan_facts_data.current_all_margin)
          }}</span>
        </p>
        <p class="planText pt-2">
          остаток плана на месяц:
          <span>{{
            addSpace(
              reportPlanStats.plan_facts_data.current_all_margin -
                reportPlanStats.plan_facts_data.current_margin_fact
            )
          }}</span>
        </p>
        <p></p>
      </div>
    </div>
  </div>
  <!-- ПРОГРЕССБАРЫ -->

  <!-- ТАБЛИЦЫ С ОФИГЕВШИМИ ПРОГРЕССБАРАМИ -->
  <div v-if="showRPS" class="flex">
    <div
      class="bg-white flex flex-col w-1/3 mr-4 mt-4 relative justify-center items-center rounded-[8px]"
    >
      <div class="plan-table-wrap w-full h-full bg-white z-10 relative">
        <div class="w-full bg-green-200 flex justify-between z-10 relative">
          <div class="wrap-semi-th title-col ml-4 w-[15%]">
            <p>Выполнение<br />плана %</p>
          </div>

          <div class="wrap-semi-th title-col w-[20%]">
            <p>Менеджер</p>
          </div>

          <div class="wrap-semi-th title-col ml-6 w-[30%]">
            <p>Визуализация<br />выполнения</p>
          </div>

          <div class="wrap-semi-th title-col w-[20%]">
            <p>Выполненный план, P</p>
          </div>
        </div>
        <div
          v-for="manager in PFD"
          :key="manager"
          class="flex items-center w-full py-2 !h-[50px] z-10 relative"
        >
          <p class="min-w-[55px] percent-number mr-6 ml-6">
            {{
              planProcent(
                manager.current_day_margin_fact,
                manager.current_day_margin_plan
              )
            }}%
          </p>

          <div class="progress-name">
            <div class="avatar mr-4"></div>
            <p>
              {{ manager.manager.secondname }}<br />
              {{ manager.manager.name }}
            </p>
          </div>

          <div class="progress-horisontal mr-12">
            <div class="progress-block-inner"></div>
            <div
              class="progress-block-outer"
              role="sausage"
              :style="
                '--value:' +
                (manager.current_day_margin_fact /
                  manager.current_day_margin_plan) *
                  100
              "
              :class="{
                yellow:
                  Math.round(
                    (manager.current_day_margin_fact /
                      manager.current_day_margin_plan) *
                      100
                  ) < 100 &&
                  Math.round(
                    (manager.current_day_margin_fact /
                      manager.current_day_margin_plan) *
                      100
                  ) >= 90,
                red:
                  Math.round(
                    (manager.current_day_margin_fact /
                      manager.current_day_margin_plan) *
                      100
                  ) < 90,
                green:
                  Math.round(
                    (manager.current_day_margin_fact /
                      manager.current_day_margin_plan) *
                      100
                  ) >= 100,
              }"
            ></div>
          </div>

          <p class="number-progress mr-4 min-w-[70px] text-right">
            {{ addSpace(manager.current_day_margin_fact) }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="bg-white flex flex-col w-2/3 pt-0 mt-4 relative justify-top items-center rounded-[8px]"
    >
      <div class="plan-table-wrap w-full bg-white pt-0 z-10 relative">
        <div class="w-full bg-green-200">
          <div class="wrap-plant-grid">
            <div class="wrap-semi-th title-col">
              <p>Менеджер</p>
            </div>

            <div class="wrap-semi-th title-col">
              <p>план на месяц,<br />маржа</p>
            </div>

            <div class="wrap-semi-th title-col">
              <p>
                Осталось получить<br />
                до конца недели, маржа
              </p>
            </div>

            <div class="wrap-semi-th title-col">
              <p>маржинальность,<br />факт</p>
            </div>

            <div class="wrap-semi-th title-col">
              <p>оплата ожидается,<br />маржа</p>
            </div>

            <div class="wrap-semi-th title-col">
              <p>планируемый %<br />выполнения плана<br />по марже, месяц</p>
            </div>
          </div>
        </div>

        <div v-for="manager in PFD" :key="manager" class="w-full bg-white">
          <div
            @click="openManager(manager.manager)"
            class="wrap-plant-grid z-10 relative cursor-pointer hover:bg-slate-200 transition-all"
          >
            <div class="wrap-semi-th title-col">
              <div class="progress-name min-w-[200px] pl-6">
                <div class="avatar ml-4 mr-4"></div>
                <p>
                  {{ manager.manager.secondname }}<br />
                  {{ manager.manager.name }}
                </p>
              </div>
            </div>

            <div class="wrap-semi-th">
              <p class="number-progress">{{ addSpace(manager.month_plan) }}</p>
            </div>

            <div class="wrap-semi-th">
              <p class="number-progress">{{ addSpace(manager.wait_week) }}</p>
            </div>

            <div class="wrap-semi-th">
              <p
                v-if="manager.margin_fact != '%0'"
                class="number-progress"
                :class="{
                  'text-green-500': manager.margin_fact >= 35,
                  'text-yellow-500':
                    manager.margin_fact < 35 && manager.margin_fact >= 30,
                  'text-rose-500': manager.margin_fact < 30,
                }"
              >
                {{ manager.margin_fact }}%
              </p>
              <p v-else class="number-progress text-rose-500">0%</p>
            </div>

            <div class="wrap-semi-th">
              <div class="progress-pay-wrap">
                <div
                  class="progress-pay"
                  role="graysausage"
                  :style="'--value:' + manager.wait_done"
                ></div>
                <p class="number-progress px-4 !text-left">
                  {{ addSpace(manager.wait_month) }}
                </p>
              </div>
            </div>

            <div class="wrap-semi-th">
              <p
                v-if="manager.wait_done != '%0'"
                class="number-progress"
                :class="{
                  'text-green-500': manager.wait_done >= 100,
                  'text-yellow-500':
                    manager.wait_done < 100 && manager.wait_done >= 90,
                  'text-rose-500': manager.wait_done < 90,
                }"
              >
                {{ manager.wait_done }}%
              </p>
              <p v-else class="number-progress text-rose-500">0%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ТАБЛИЦЫ С ОФИГЕВШИМИ ПРОГРЕССБАРАМИ -->

  <div class="flex justify-between mt-5">
    <div
      class="flex"
      v-if="formingChart && loadedChart && reportGraphFact && showRPS"
    >
      <input
        type="month"
        class="month-report-top mr-5"
        v-model="newDateChart"
      />
      <button @click="reformDatShit()" class="button-form-report">
        Пересчитать график
      </button>
    </div>

    <div
      v-if="formingChart && loadedChart && reportGraphFact && showRPS"
      class="orders-tabs ml-auto px-8 bg-white rounded-lg flex flex-wrap justify-around items-center"
    >
      <a
        :class="{ checkedTab: incomeTab }"
        class="link py-3 px-5 uppercase"
        @click="
          margeTab = false;
          incomeTab = true;
        "
        >Доходы</a
      >
      <a
        :class="{ checkedTab: margeTab }"
        class="link py-3 px-5 uppercase"
        @click="
          margeTab = true;
          incomeTab = false;
        "
        >Маржа</a
      >
    </div>
  </div>

  <div
    class="wrap-chart my-5 pb-5"
    v-if="
      formingChart && loadedChart && reportGraphFact && showRPS && incomeTab
    "
  >
    <div v-if="reportGraphFact" class="chart-lol pt-5">
      <ChartFactIncome class="" :data="reportGraphFact" />
    </div>
    <div v-else>
      <h2 style="text-align: center; font-size: 25px; font-weight: 700">
        Нет данных для показа
      </h2>
    </div>
  </div>

  <div
    class="wrap-chart my-5 pb-5"
    v-if="formingChart && loadedChart && reportGraphFact && showRPS && margeTab"
  >
    <div v-if="reportGraphFact" class="chart-lol pt-5">
      <ChartFactMarge class="" :data="reportGraphFact" />
    </div>
    <div v-else>
      <h2 style="text-align: center; font-size: 25px; font-weight: 700">
        Нет данных для показа
      </h2>
    </div>
  </div>

  <div
    v-if="showReportPSM"
    id="popnew"
    class="w-[95%] bg-slate-200 px-5 fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-y-auto overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
  >
    <ReportPlanStatsManager
      :manager_id="managerToOpen"
      :dateSearch="dateTo"
      :manager_name="managerNameToShow"
      @closePopup="closePopupPSM()"
    />
  </div>
  <div
    v-if="showReportPSM"
    @click="closePopupPSM()"
    id="overlay"
    class="fixed bg-cyan-900 z-2 w-screen h-screen top-0 left-0 opacity-80"
  ></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ReportPlanStatsManager from "@/components/reports/ReportPlanStatsManager.vue";
import ChartFactIncome from "@/components/report/ChartFactIncome.vue";
import ChartFactMarge from "@/components/report/ChartFactMarge.vue";

export default {
  name: "FactsPage",
  components: {
    // DiffRomb,
    ReportPlanStatsManager,
    ChartFactIncome,
    ChartFactMarge,
  },
  data() {
    return {
      forming: false,
      loadedStages: false,
      formingChart: false,
      loadedChart: false,

      incomeTab: true,
      margeTab: false,

      managerToOpen: 0,
      managerNameToShow: "",

      newDateChart: null,
      dateFrom: null,
      dateTo: null,
      loaded: null,
      chartData: null,
      direction: "0",
      valueProgress: 85,
      showRPS: false,
      showReportPSM: false,
      PFD: {},
      directions: [
        {
          id: 1,
          name: "Общее",
        },
        {
          id: 2,
          name: "Тахографы",
        },
        {
          id: 3,
          name: "Мониторинги",
        },
        {
          id: 4,
          name: "Карты",
        },
        {
          id: 5,
          name: "Пропуска",
        },
        {
          id: 6,
          name: "Обучение",
        },
        {
          id: 7,
          name: "Водители",
        },
      ],
    };
  },

  computed: {
    ...mapGetters([
      "WorkerList",
      "statisticShow",
      "reportPlanStats",
      "reportGraphFact",
    ]),
  },

  methods: {
    ...mapActions(["getReportPlanStats", "getGraphFact", "regetGraphFact"]),

    planProcent(mf, mp) {
      let procent = 0;
      procent = Math.round((Number(mf) / Number(mp)) * 100);

      if (isNaN(procent)) {
        procent = 0;
      }
      if (procent == Infinity) {
        procent = 0;
      }
      return procent;
    },

    openThisMRPS() {
      this.$emit("openMRPS");
    },
    addSpace(strNumber) {
      return String(strNumber).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");
    },
    openManager(managerInfo) {
      this.managerNameToShow = managerInfo.secondname + " " + managerInfo.name;
      this.managerToOpen = Number(managerInfo.uid);
      this.showReportPSM = true;

      console.log("manager info of " + this.managerNameToShow, managerInfo);
    },
    closePopupPSM() {
      this.managerNameToShow = "";
      this.managerToOpen = 0;
      this.showReportPSM = false;
    },
    async reformDatShit() {
      await this.regetGraphFact({ date: this.newDateChart });

      this.formingChart = true;
      this.loadedChart = false;
      window.DEBUG_REPORT = this;
      //this.getStatShow();
      //this.getStatAll();
      // this.getWorkers();
      // this.chartData = (
      //   await API.GET("report/report-chart", {
      //     dateFrom: this.dateFrom,
      //     dateTo: this.dateTo,
      //   })
      // ).data;
      this.loadedChart = true;
    },
    async formDatChart() {
      await this.getGraphFact();

      this.formingChart = true;
      this.loadedChart = false;
      window.DEBUG_REPORT = this;
      //this.getStatShow();
      //this.getStatAll();
      // this.getWorkers();
      // this.chartData = (
      //   await API.GET("report/report-chart", {
      //     dateFrom: this.dateFrom,
      //     dateTo: this.dateTo,
      //   })
      // ).data;
      this.loadedChart = true;
    },
    async formDatShit() {
      await this.getReportPlanStats({ date: this.dateTo });
      await this.formDatChart();

      this.showRPS = true;
      this.PFD = this.reportPlanStats.plan_facts_data.all_managers_data;
    },

    changeDirection() {
      if (this.direction == "Общее") {
        this.PFD = this.reportPlanStats.plan_facts_data.all_managers_data;
      }
      if (this.direction == "Тахографы") {
        this.PFD = this.reportPlanStats.plan_facts_data.taho_managers_data;
      }
      if (this.direction == "Мониторинги") {
        this.PFD = this.reportPlanStats.plan_facts_data.monitor_managers_data;
      }
      if (this.direction == "Карты") {
        this.PFD = this.reportPlanStats.plan_facts_data.card_managers_data;
      }
      if (this.direction == "Пропуска") {
        this.PFD = this.reportPlanStats.plan_facts_data.pass_managers_data;
      }
      if (this.direction == "Обучение") {
        this.PFD = this.reportPlanStats.plan_facts_data.teach_managers_data;
      }
      if (this.direction == "Водители") {
        this.PFD = this.reportPlanStats.plan_facts_data.driver_managers_data;
      }
    },
  },

  async mounted() {
    var currentYear = new Date().getFullYear();
    var currentMonth = new Date().getMonth();
    currentMonth = currentMonth + 1;
    var lastMonth = currentMonth - 1;

    if (currentMonth < 10) {
      this.dateFrom = "" + currentYear + "-0" + lastMonth;
    } else {
      this.dateFrom = "" + currentYear + "-" + lastMonth;
    }
    if (currentMonth < 10) {
      this.dateTo = "" + currentYear + "-0" + currentMonth;
      this.newDateChart = "" + currentYear + "-0" + currentMonth;
    } else {
      this.dateTo = "" + currentYear + "-" + currentMonth;
      this.newDateChart = "" + currentYear + "-" + currentMonth;
    }
  },
};
</script>

<style scoped>
.flex-wrap-report {
  width: 100%;
  display: flex;
  align-items: center;
}
.date-report {
  background: #f8fafc;
  color: #262d29;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 9px 13px;
  margin-right: 15px;
}

.orders-tabs a {
  transition: 0.4s ease;
  color: #9ca3c0;
  text-align: center;
  font-size: 13.5px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.orders-tabs a:first-child {
  margin-right: 10px;
}

.checkedTab {
  border-radius: 3px;
  background: #22c55e;
  color: #fff !important;
}
.button-form-report {
  border-radius: 3px;
  background: #22c55e;
  border: none;
  color: #fff;
  text-align: center;
  font-size: 13.5px;
  font-weight: 700;
  line-height: 13.5px;
  text-transform: uppercase;
  padding: 12px 88px;
}
.wrap-chart {
  width: 100%;
}
.chart-lol {
  border-radius: 5px;
  background: #f8fafc;
  box-shadow: -2px -2px 10px 0px rgba(0, 0, 0, 0.03);
}
.chart-lol p {
  color: #262d29;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.month-report-top {
  padding: 10px 15px !important;
  border-radius: 10px;
}

.todayDateLabel {
  color: rgb(125, 125, 125);

  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
}
.todayDate {
  color: rgb(38, 45, 41);

  font-size: 23px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}
.workDays {
  color: rgb(125, 125, 125);

  font-size: 14px;
  line-height: 34px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
}
.workDays > span {
  font-size: 24px;
}

.titleReport {
  color: rgb(38, 45, 41);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
}

@property --percentage {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}

[role="progressbar"] {
  --percentage: var(--value);
  --primary: #369;
  --secondary: rgb(232, 232, 232);
  --size: 220px;
  animation: progress 2s 0.5s forwards;
  width: var(--size);
  aspect-ratio: 2 / 1;
  border-radius: 50% / 100% 100% 0 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.green[role="progressbar"] {
  --primary: rgb(34, 197, 94);
}
.yellow[role="progressbar"] {
  --primary: rgb(254, 171, 26);
}
.red[role="progressbar"] {
  --primary: rgb(250, 52, 76);
}

.green[role="sausage"] {
  --primaryS: rgb(34, 197, 94);
}
.yellow[role="sausage"] {
  --primaryS: rgb(254, 171, 26);
}
.red[role="sausage"] {
  --primaryS: rgb(250, 52, 76);
}
.progress-block-outer[role="sausage"] {
  width: calc(var(--value) * 1%);
  background: var(--primaryS);
  max-width: 150px;
  overflow-x: hidden;
}

[role="progressbar"]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: conic-gradient(
    from 0.75turn at 50% 100%,
    var(--primary) calc((var(--percentage) - 25) * 1% / 2),
    var(--secondary) calc((var(--percentage) - 25) * 1% / 2 + 0.1%)
  );
  mask: radial-gradient(at 50% 100%, white 55%, transparent 55.5%);
  mask-mode: alpha;
  -webkit-mask: radial-gradient(at 50% 100%, #0000 55%, #000 55.5%);
  -webkit-mask-mode: alpha;
}

[role="progressbar"]::after {
  counter-reset: percentage calc(var(--value));
  content: counter(percentage) "%";
  font-family: Helvetica, Arial, sans-serif;
  font-size: 45px;
  color: var(--primary);
  line-height: 80%;
  font-weight: bolder;
}

.planText {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
  color: rgb(171, 171, 171);
}
.planText > span {
  color: rgb(80, 80, 80);
}

.titleReport {
  color: rgb(38, 45, 41);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
}
.numberToday {
  color: rgb(142, 142, 142);
  font-size: 36.57px;
  font-weight: 900;
  line-height: 44px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}
.numberLabelToday {
  color: rgb(38, 45, 41);

  font-size: 12.8px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}
.line100 {
  position: absolute;
  border: 1.09px solid rgb(0, 0, 0);
  width: 25px;
  top: 39px;
  right: 29px;
  z-index: 5;
  display: block;
  transform: rotate(-45deg);
}

.percent-number {
  color: rgb(38, 45, 41);
  font-size: 15.54px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0%;
  text-align: right;
  text-transform: uppercase;
}
.progress-name {
  color: rgb(38, 45, 41);
  font-size: 10.06px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
.avatar {
  background: rgb(204, 204, 204);
  width: 33px;
  height: 33px;
  border-radius: 100px;
}
.progress-horisontal {
  position: relative;
  width: 130px;
  height: 30px;
}
.progress-block-inner {
  border-radius: 4.57px;
  background: rgb(232, 232, 232);
  position: absolute;
  width: 100%;
  z-index: 2;
  height: 30px;

  top: 0%;
  left: 0;
}
.progress-block-outer {
  border-radius: 4.57px;
  position: absolute;
  z-index: 5;
  width: 120%;
  height: 15px;

  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.number-progress {
  font-size: 15.54px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0%;
  text-transform: uppercase;
}
.progress-horisontal {
  margin-left: auto;
}

.wrap-pt-grid {
  display: grid;
  grid-template-columns: 20% repeat(8, 10%);
  border-bottom: 1px solid rgb(232, 236, 236);
}
.wrap-plant-grid {
  display: grid;
  grid-template-columns: 200px repeat(5, 1fr);
}
.plan-table-title {
  padding-top: 25px;
  padding-bottom: 25px;
  color: rgb(38, 45, 41);
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}

.wrap-pt-grid > * {
  color: rgb(38, 45, 41);
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: capitalize;

  border-right: 1px solid rgb(232, 236, 236);
}
.wrap-pt-grid > *:last-child {
  border-right: none;
}
.wrap-pt-grid > *:nth-child(2) {
  border-left: 1px solid rgb(136, 136, 136);
}
.wrap-pt-grid > *:nth-child(3) {
  border-right: 1px solid rgb(136, 136, 136);
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: center;
}
.title-col > p {
  color: rgb(38, 45, 41);
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: capitalize;
}
.wrap-semi-th {
  display: flex;
  align-items: center;
  justify-content: center;
}
.title-col {
  height: 50px;
}
.progress-pay-wrap {
  height: 30px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
.progress-pay {
  position: absolute;
  height: 30px;
  width: calc(var(--value) * 1%);
  border-radius: 8px;
  z-index: 2;
  background: #dfdfdf;
  left: 0;
  top: 0;
  max-width: 100%;
  overflow-x: hidden;
}
.progress-pay[role="sausage"] {
}
.progress-pay-wrap > .number-progress {
  z-index: 4;
  width: 100%;
  text-align: center;
}
</style>
