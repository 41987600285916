<template>
  <div class="pb-4 w-full flex flex-wrap items-center">
    <h2 class="mr-15px h-9 min-w-[2.25rem] border border-teal-700 rounded-lg p-2 text-center my-4">
      <span class="w-full text-lg leading-none text-teal-700">Создание новой статьи</span>
    </h2>
  </div>
  <div class="flex flex-col justify-center items-center text-center">
    <label class="text-gray-400 uppercase" for="noteName">Введите название</label>
    <input class="text-center uppercase w-[50%] mt-2" type="text" id="noteName" v-model="noteHeader" />
  </div>
  <div class="mt-4">
    <div ref="editor" class="bg-white border-1px border-solid border-black" style="height: 60vh;"></div>
  </div>
  <button class="v-btn bg-green-500 hover:bg-green-400 transition-colors duration-150 mt-4" @click="sendNote">
    <svg class="fill-white w-4 h-4 mr-3 rotate-45">
      <use xlink:href="@/assets/i.svg#ico-no"></use>
    </svg>
    <span class="v-btn_text">Сохранить</span>
  </button>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import API from "../services/api";

export default {
  name: "LearningBaseAddArticle",

  data() {
    return {
      noteHeader: undefined,
    }
  },

  computed: {
    ...mapGetters([
      "s",
      "profile",
      "role",
      "roles",
    ]),
  },

  methods: {
    ...mapActions([
    ]),
    sendNote() {
      if(this.noteHeader == undefined){
        alert("Введите название статьи")
        return
      }
      
      let formData = new FormData();
        formData.append("name", this.noteHeader);
        formData.append("text", this.quill.root.innerHTML);

      API.POST("learning/save-art", formData)
      window.open("/learning_base")
    },
  },

  mounted() {
    this.quill = new Quill(this.$refs.editor, {
      theme: 'snow',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote'],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
          [{ 'color': [] }, { 'background': [] }], 
          ['image', 'link'],
          ['clean']  
        ]
      }
    });
  }
}
</script>

<style scoped>
.editor-container {
  max-width: 800px;
  margin: 20px auto;
}

.ql-editor {
  min-height: 300px;
}
</style>