<template>
    <div class="flex flex-wrap-report items-center mt-5 mb-8">
      <div>
        <input
          type="month"
          class="month-report-top rounded-[8px] py-4 mr-5"
          v-model="dateFrom"
        />
      </div>
      <div
        class="bg-white flex w-fit pr-4 mr-5 relative rounded-[8px] items-center"
      >
        <div class="flex relative group p-1 px-4 v-bbgray min-w-[375px]">
          <div v-if="choosenManager.name" class="flex justify-between">
            <div class="flex items-center cursor-pointer group">
              <div
                class="min-w-[1.5rem] w-11 h-11 rounded-full mr-4 text-clip overflow-hidden bg-green-500"
              >
                <span class="w-6 h-6">
                  <IcoPic
                    :pic="choosenManager?.picture"
                    :name="choosenManager?.name"
                  />
                </span>
              </div>
              <div class="flex flex-col justify-center text-clip overflow-hidden pr-8">
                <span
                  class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                  >Менеджер</span
                >
                <span
                  class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
                  >{{ choosenManager?.name }}
                  {{ choosenManager?.secondname }}</span
                >
              </div>
            </div>
            <div
              class="absolute top-1/2 -translate-y-1/2 right-4 opacity-0 group-hover:opacity-100 transition-all duration-150"
            >
              <a class="group cursor-pointer" @click="choosenManager = {}">
                <svg
                  class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                >
                  <use xlink:href="@/assets/i.svg#ico-no"></use>
                </svg>
              </a>
            </div>
          </div>
  
          <template v-else>
            <div class="flex items-center w-full">
              <p
                class="text-gray-400 leading-none w-full min-w-[100px] max-w-[100px] mr-6"
              >
                Менеджер
              </p>
              <div class="relative min-w-[250px]">
                <input
                  type="text"
                  v-model.trim="text_manager"
                  class="bg-white h-9 px-3 py-1 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                  @input="findManager()"
                  @click="findManager()"
                />
                <hr />
                <div
                  v-if="managerList.length"
                  class="absolute w-full top-full bg-white left-0 bor align-middleder-2 border-green-800 border-t-0 z-10"
                >
                  <div
                    v-for="m in managerList"
                    :key="m"
                    @click="setManager(m)"
                    class="px-4 py-1 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                  >
                    <span>{{ m.secondname }} {{ m.name }}</span>
                    <span class="text-xs">{{ m.uid }}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <button
        @click="formActivity()"
        v-if="choosenManager.name"
        class="button-form-report rounded-[8px]"
      >
        Сформировать
      </button>
    </div>
  
    <div v-if="showPlanList" class="flex justify-center items-center bg-white py-2">
        <p v-if="this.plan.noti == 'отчет сохраненный'" class="uppercase text-green-500 font-[700]">{{ this.plan.noti }}</p>
        <p v-if="this.plan.noti == 'отчет в динамике'" class="uppercase text-blue-400 font-[700]">{{ this.plan.noti }}</p>
        <p v-if="this.plan.noti == 'отчет в динамике с прошлым месяцем'" class="uppercase text-[#D5AAEB] font-[700]">{{ this.plan.noti }}</p>
    </div>
    <div v-if="loading" class="flex justify-center items-center mb-[40px]">
      <img class="w-[60px] h-[60px]" src="@/assets/loading-clients.gif" alt="" />
    </div>
    <div
      v-if="showPlanList"
      class="flex flex-col flex-wrap-report justify-between mb-[40px]"
    >
        <div>
            <div class="wrap-pt-grid bg-green-400 w-full">
              <div class=" table-cell"><span>№</span></div>
                <div class=" table-cell"><span>Клиент</span></div>
                <div class=" table-cell"><span>Кол-во ТС</span></div>
                <div class="table-cell"><span>Маржа-Счета</span></div>
                <div class=" table-cell"><span>Доход ЛИД</span></div>
                <div class=" table-cell"><span>Маржа ЛИД</span></div>
                <div class=" table-cell"><span>Маржа-Пролонгация Счет</span></div>
                <div class=" table-cell"><span>Маржа-Пролонгация ЛИД</span></div>
                <div class=" table-cell"><span>Маржа Плановая на следующий месяц</span></div>
                <div class=" table-cell"><span>Маржа Фактическая на текущий месяц</span></div>
                <div class=" table-cell"><span>Отклонения от плана</span></div>
            </div>
        </div> 
        <div>
            <div class="wrap-pt-grid  bg-green-200 w-full">
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span>Клиенты активные</span></div>
                <div class=" table-cell"><span></span></div>
                <div class="table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
            </div>
            <div class="wrap-pt-grid  bg-[#edede3] w-full">
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span>Категория более 50 ТС</span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
              </div>
            <div class="wrap-pt-grid  bg-white w-full" v-for='item in this.plan.client_active_5' :key="item">
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{item.count}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]" @click="goToClient(item)"><span>{{ item.full_name }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.countcars != null? item.countcars : "" }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_margin_invoice.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class="table-cell" @click="item.ids_margin_invoice.length != 0? openThisInvoiceList(item.ids_margin_invoice): undefined"><span>{{ item.margin_invoice != null? item.margin_invoice : ""  }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.income_lead != null? item.income_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.margin_lead != null? item.margin_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_invoce.length != 0,
                 'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_invoce.length !=0 ? openThisInvoiceList(item.ids_prolon_invoce) : undefined"><span>{{ item.margin_prolon_invoice != null? item.margin_prolon_invoice : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_lead.length !=0 ? openThisLeadList(item.ids_prolon_lead) : undefined"><span>{{ item.margin_prolon_lead != null? item.margin_prolon_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_plan != null? item.all_margin_plan : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_fact != null? item.all_margin_fact : ""}}</span></div>
                <div v-if="item.percent_plan_fact == 0" class=" table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-else-if="item.percent_plan_fact <=80" class="bg-red-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=81 && item.percent_plan_fact <=89" class="bg-orange-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=90 && item.percent_plan_fact <=99" class="bg-yellow-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >= 100" class="bg-green-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
              </div>
            <div class="wrap-pt-grid  bg-[#edede3] w-full">
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span>Категория  31-50 ТС</span></div>
                <div class=" table-cell"><span></span></div>
                <div class="table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
            </div>
            <div class="wrap-pt-grid  bg-white w-full" v-for='item in this.plan.client_active_4' :key="item">
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{item.count}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]" @click="goToClient(item)"><span>{{ item.full_name }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.countcars != null? item.countcars : "" }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_margin_invoice.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class="table-cell" @click="item.ids_margin_invoice.length != 0? openThisInvoiceList(item.ids_margin_invoice): undefined"><span>{{ item.margin_invoice != null? item.margin_invoice : ""  }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.income_lead != null? item.income_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.margin_lead != null? item.margin_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_invoce.length != 0,
                 'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_invoce.length !=0 ? openThisInvoiceList(item.ids_prolon_invoce) : undefined"><span>{{ item.margin_prolon_invoice != null? item.margin_prolon_invoice : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_lead.length !=0 ? openThisLeadList(item.ids_prolon_lead) : undefined"><span>{{ item.margin_prolon_lead != null? item.margin_prolon_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_plan != null? item.all_margin_plan : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_fact != null? item.all_margin_fact : ""}}</span></div>
                <div v-if="item.percent_plan_fact == 0" class=" table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-else-if="item.percent_plan_fact <=80" class="bg-red-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=81 && item.percent_plan_fact <=89" class="bg-orange-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=90 && item.percent_plan_fact <=99" class="bg-yellow-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >= 100" class="bg-green-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div> 
            </div>
            <div class="wrap-pt-grid  bg-[#edede3] w-full">
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span>Категория  11-30 ТС</span></div>
                <div class=" table-cell"><span></span></div>
                <div class="table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
            </div>
            <div class="wrap-pt-grid  bg-white w-full" v-for='item in this.plan.client_active_3' :key="item">
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{item.count}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]" @click="goToClient(item)"><span>{{ item.full_name }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.countcars != null? item.countcars : "" }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_margin_invoice.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class="table-cell" @click="item.ids_margin_invoice.length != 0? openThisInvoiceList(item.ids_margin_invoice): undefined"><span>{{ item.margin_invoice != null? item.margin_invoice : ""  }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.income_lead != null? item.income_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.margin_lead != null? item.margin_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_invoce.length != 0,
                 'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_invoce.length !=0 ? openThisInvoiceList(item.ids_prolon_invoce) : undefined"><span>{{ item.margin_prolon_invoice != null? item.margin_prolon_invoice : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_lead.length !=0 ? openThisLeadList(item.ids_prolon_lead) : undefined"><span>{{ item.margin_prolon_lead != null? item.margin_prolon_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_plan != null? item.all_margin_plan : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_fact != null? item.all_margin_fact : ""}}</span></div>
                <div v-if="item.percent_plan_fact == 0" class=" table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-else-if="item.percent_plan_fact <=80" class="bg-red-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=81 && item.percent_plan_fact <=89" class="bg-orange-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=90 && item.percent_plan_fact <=99" class="bg-yellow-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >= 100" class="bg-green-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
            </div>
            <div class="wrap-pt-grid  bg-[#edede3] w-full">
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span>Категория  2-10 ТС</span></div>
                <div class=" table-cell"><span></span></div>
                <div class="table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
            </div>
            <div class="wrap-pt-grid  bg-white w-full" v-for='item in this.plan.client_active_2' :key="item">
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{item.count}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]" @click="goToClient(item)"><span>{{ item.full_name }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.countcars != null? item.countcars : "" }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_margin_invoice.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class="table-cell" @click="item.ids_margin_invoice.length != 0? openThisInvoiceList(item.ids_margin_invoice): undefined"><span>{{ item.margin_invoice != null? item.margin_invoice : ""  }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.income_lead != null? item.income_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.margin_lead != null? item.margin_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_invoce.length != 0,
                 'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_invoce.length !=0 ? openThisInvoiceList(item.ids_prolon_invoce) : undefined"><span>{{ item.margin_prolon_invoice != null? item.margin_prolon_invoice : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_lead.length !=0 ? openThisLeadList(item.ids_prolon_lead) : undefined"><span>{{ item.margin_prolon_lead != null? item.margin_prolon_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_plan != null? item.all_margin_plan : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_fact != null? item.all_margin_fact : ""}}</span></div>
                <div v-if="item.percent_plan_fact == 0" class=" table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-else-if="item.percent_plan_fact <=80" class="bg-red-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=81 && item.percent_plan_fact <=89" class="bg-orange-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=90 && item.percent_plan_fact <=99" class="bg-yellow-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >= 100" class="bg-green-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
            </div>
        </div>
        <div>
            <div class="wrap-pt-grid  bg-red-200 w-full">
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span>Клиенты пассивые</span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
            </div>
            <div class="wrap-pt-grid  bg-[#edede3] w-full">
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span>Категория более 50 ТС</span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
            </div>
            <div class="wrap-pt-grid  bg-white w-full" v-for='item in this.plan.client_passive_5' :key="item">
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{item.count}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]" @click="goToClient(item)"><span>{{ item.full_name }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.countcars != null? item.countcars : "" }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_margin_invoice.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class="table-cell" @click="item.ids_margin_invoice.length != 0? openThisInvoiceList(item.ids_margin_invoice): undefined"><span>{{ item.margin_invoice != null? item.margin_invoice : ""  }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.income_lead != null? item.income_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.margin_lead != null? item.margin_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_invoce.length != 0,
                 'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_invoce.length !=0 ? openThisInvoiceList(item.ids_prolon_invoce) : undefined"><span>{{ item.margin_prolon_invoice != null? item.margin_prolon_invoice : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_lead.length !=0 ? openThisLeadList(item.ids_prolon_lead) : undefined"><span>{{ item.margin_prolon_lead != null? item.margin_prolon_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_plan != null? item.all_margin_plan : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_fact != null? item.all_margin_fact : ""}}</span></div>
                <div v-if="item.percent_plan_fact == 0" class=" table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-else-if="item.percent_plan_fact <=80" class="bg-red-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=81 && item.percent_plan_fact <=89" class="bg-orange-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=90 && item.percent_plan_fact <=99" class="bg-yellow-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >= 100" class="bg-green-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
            </div>
            <div class="wrap-pt-grid  bg-[#edede3] w-full">
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span>Категория  31-50 ТС</span></div>
                <div class=" table-cell"><span></span></div>
                <div class="table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
            </div>
            <div class="wrap-pt-grid  bg-white w-full" v-for='item in this.plan.client_passive_4' :key="item">
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{item.count}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]" @click="goToClient(item)"><span>{{ item.full_name }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.countcars != null? item.countcars : "" }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_margin_invoice.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class="table-cell" @click="item.ids_margin_invoice.length != 0? openThisInvoiceList(item.ids_margin_invoice): undefined"><span>{{ item.margin_invoice != null? item.margin_invoice : ""  }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.income_lead != null? item.income_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.margin_lead != null? item.margin_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_invoce.length != 0,
                 'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_invoce.length !=0 ? openThisInvoiceList(item.ids_prolon_invoce) : undefined"><span>{{ item.margin_prolon_invoice != null? item.margin_prolon_invoice : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_lead.length !=0 ? openThisLeadList(item.ids_prolon_lead) : undefined"><span>{{ item.margin_prolon_lead != null? item.margin_prolon_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_plan != null? item.all_margin_plan : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_fact != null? item.all_margin_fact : ""}}</span></div>
                <div v-if="item.percent_plan_fact == 0" class=" table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-else-if="item.percent_plan_fact <=80" class="bg-red-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=81 && item.percent_plan_fact <=89" class="bg-orange-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=90 && item.percent_plan_fact <=99" class="bg-yellow-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >= 100" class="bg-green-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
            </div>
            <div class="wrap-pt-grid  bg-[#edede3] w-full">
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span>Категория  11-30 ТС</span></div>
                <div class=" table-cell"><span></span></div>
                <div class="table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
            </div>
            <div class="wrap-pt-grid  bg-white w-full" v-for='item in this.plan.client_passive_3' :key="item">
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{item.count}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]" @click="goToClient(item)"><span>{{ item.full_name }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.countcars != null? item.countcars : "" }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_margin_invoice.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class="table-cell" @click="item.ids_margin_invoice.length != 0? openThisInvoiceList(item.ids_margin_invoice): undefined"><span>{{ item.margin_invoice != null? item.margin_invoice : ""  }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.income_lead != null? item.income_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.margin_lead != null? item.margin_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_invoce.length != 0,
                 'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_invoce.length !=0 ? openThisInvoiceList(item.ids_prolon_invoce) : undefined"><span>{{ item.margin_prolon_invoice != null? item.margin_prolon_invoice : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_lead.length !=0 ? openThisLeadList(item.ids_prolon_lead) : undefined"><span>{{ item.margin_prolon_lead != null? item.margin_prolon_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_plan != null? item.all_margin_plan : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_fact != null? item.all_margin_fact : ""}}</span></div>
                <div v-if="item.percent_plan_fact == 0" class=" table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-else-if="item.percent_plan_fact <=80" class="bg-red-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=81 && item.percent_plan_fact <=89" class="bg-orange-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=90 && item.percent_plan_fact <=99" class="bg-yellow-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >= 100" class="bg-green-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
            </div>
            <div class="wrap-pt-grid  bg-[#edede3] w-full">
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span>Категория  2-10 ТС</span></div>
                <div class=" table-cell"><span></span></div>
                <div class="table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
            </div>
            <div class="wrap-pt-grid  bg-white w-full" v-for='item in this.plan.client_passive_2' :key="item">
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{item.count}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]" @click="goToClient(item)"><span>{{ item.full_name }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.countcars != null? item.countcars : "" }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_margin_invoice.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class="table-cell" @click="item.ids_margin_invoice.length != 0? openThisInvoiceList(item.ids_margin_invoice): undefined"><span>{{ item.margin_invoice != null? item.margin_invoice : ""  }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.income_lead != null? item.income_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.margin_lead != null? item.margin_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_invoce.length != 0,
                 'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_invoce.length !=0 ? openThisInvoiceList(item.ids_prolon_invoce) : undefined"><span>{{ item.margin_prolon_invoice != null? item.margin_prolon_invoice : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_lead.length !=0 ? openThisLeadList(item.ids_prolon_lead) : undefined"><span>{{ item.margin_prolon_lead != null? item.margin_prolon_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_plan != null? item.all_margin_plan : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_fact != null? item.all_margin_fact : ""}}</span></div>
                <div v-if="item.percent_plan_fact == 0" class=" table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-else-if="item.percent_plan_fact <=80" class="bg-red-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=81 && item.percent_plan_fact <=89" class="bg-orange-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=90 && item.percent_plan_fact <=99" class="bg-yellow-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >= 100" class="bg-green-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
            </div>
        </div>     
        <div>
            <div class="wrap-pt-grid  bg-yellow-200 w-full">
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span>Клиенты возвращенные</span></div>
                <div class=" table-cell"><span></span></div>
                <div class="table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
            </div>
            <div class="wrap-pt-grid  bg-[#edede3] w-full">
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span>Категория более 50 ТС</span></div>
                <div class=" table-cell"><span></span></div>
                <div class="table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
            </div>
            <div class="wrap-pt-grid  bg-white w-full" v-for='item in this.plan.client_back_5' :key="item">
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{item.count}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]" @click="goToClient(item)"><span>{{ item.full_name }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.countcars != null? item.countcars : "" }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_margin_invoice.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class="table-cell" @click="item.ids_margin_invoice.length != 0? openThisInvoiceList(item.ids_margin_invoice): undefined"><span>{{ item.margin_invoice != null? item.margin_invoice : ""  }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.income_lead != null? item.income_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.margin_lead != null? item.margin_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_invoce.length != 0,
                 'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_invoce.length !=0 ? openThisInvoiceList(item.ids_prolon_invoce) : undefined"><span>{{ item.margin_prolon_invoice != null? item.margin_prolon_invoice : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_lead.length !=0 ? openThisLeadList(item.ids_prolon_lead) : undefined"><span>{{ item.margin_prolon_lead != null? item.margin_prolon_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_plan != null? item.all_margin_plan : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_fact != null? item.all_margin_fact : ""}}</span></div>
                <div v-if="item.percent_plan_fact == 0" class=" table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-else-if="item.percent_plan_fact <=80" class="bg-red-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=81 && item.percent_plan_fact <=89" class="bg-orange-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=90 && item.percent_plan_fact <=99" class="bg-yellow-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >= 100" class="bg-green-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
            </div>
            <div class="wrap-pt-grid  bg-[#edede3] w-full">
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span>Категория  31-50 ТС</span></div>
                <div class=" table-cell"><span></span></div>
                <div class="table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
            </div>
            <div class="wrap-pt-grid  bg-white w-full" v-for='item in this.plan.client_back_4' :key="item">
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{item.count}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]" @click="goToClient(item)"><span>{{ item.full_name }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.countcars != null? item.countcars : "" }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_margin_invoice.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class="table-cell" @click="item.ids_margin_invoice.length != 0? openThisInvoiceList(item.ids_margin_invoice): undefined"><span>{{ item.margin_invoice != null? item.margin_invoice : ""  }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.income_lead != null? item.income_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.margin_lead != null? item.margin_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_invoce.length != 0,
                 'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_invoce.length !=0 ? openThisInvoiceList(item.ids_prolon_invoce) : undefined"><span>{{ item.margin_prolon_invoice != null? item.margin_prolon_invoice : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_lead.length !=0 ? openThisLeadList(item.ids_prolon_lead) : undefined"><span>{{ item.margin_prolon_lead != null? item.margin_prolon_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_plan != null? item.all_margin_plan : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_fact != null? item.all_margin_fact : ""}}</span></div>
                <div v-if="item.percent_plan_fact == 0" class=" table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-else-if="item.percent_plan_fact <=80" class="bg-red-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=81 && item.percent_plan_fact <=89" class="bg-orange-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=90 && item.percent_plan_fact <=99" class="bg-yellow-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >= 100" class="bg-green-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
            </div>
            <div class="wrap-pt-grid  bg-[#edede3] w-full">
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span>Категория  11-30 ТС</span></div>
                <div class=" table-cell"><span></span></div>
                <div class="table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
            </div>
            <div class="wrap-pt-grid  bg-white w-full" v-for='item in this.plan.client_back_3' :key="item">
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{item.count}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]" @click="goToClient(item)"><span>{{ item.full_name }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.countcars != null? item.countcars : "" }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_margin_invoice.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class="table-cell" @click="item.ids_margin_invoice.length != 0? openThisInvoiceList(item.ids_margin_invoice): undefined"><span>{{ item.margin_invoice != null? item.margin_invoice : ""  }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.income_lead != null? item.income_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.margin_lead != null? item.margin_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_invoce.length != 0,
                 'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_invoce.length !=0 ? openThisInvoiceList(item.ids_prolon_invoce) : undefined"><span>{{ item.margin_prolon_invoice != null? item.margin_prolon_invoice : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_lead.length !=0 ? openThisLeadList(item.ids_prolon_lead) : undefined"><span>{{ item.margin_prolon_lead != null? item.margin_prolon_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_plan != null? item.all_margin_plan : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_fact != null? item.all_margin_fact : ""}}</span></div>
                <div v-if="item.percent_plan_fact == 0" class=" table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-else-if="item.percent_plan_fact <=80" class="bg-red-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=81 && item.percent_plan_fact <=89" class="bg-orange-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=90 && item.percent_plan_fact <=99" class="bg-yellow-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >= 100" class="bg-green-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
            </div>
            <div class="wrap-pt-grid  bg-[#edede3] w-full">
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span>Категория  2-10 ТС</span></div>
                <div class=" table-cell"><span></span></div>
                <div class="table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
            </div>
            <div class="wrap-pt-grid  bg-white w-full" v-for='item in this.plan.client_back_2' :key="item">
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{item.count}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]" @click="goToClient(item)"><span>{{ item.full_name }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.countcars != null? item.countcars : "" }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_margin_invoice.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class="table-cell" @click="item.ids_margin_invoice.length != 0? openThisInvoiceList(item.ids_margin_invoice): undefined"><span>{{ item.margin_invoice != null? item.margin_invoice : ""  }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.income_lead != null? item.income_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.margin_lead != null? item.margin_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_invoce.length != 0,
                 'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_invoce.length !=0 ? openThisInvoiceList(item.ids_prolon_invoce) : undefined"><span>{{ item.margin_prolon_invoice != null? item.margin_prolon_invoice : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_lead.length !=0 ? openThisLeadList(item.ids_prolon_lead) : undefined"><span>{{ item.margin_prolon_lead != null? item.margin_prolon_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_plan != null? item.all_margin_plan : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_fact != null? item.all_margin_fact : ""}}</span></div>
                <div v-if="item.percent_plan_fact == 0" class=" table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-else-if="item.percent_plan_fact <=80" class="bg-red-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=81 && item.percent_plan_fact <=89" class="bg-orange-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=90 && item.percent_plan_fact <=99" class="bg-yellow-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >= 100" class="bg-green-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
            </div>
        </div>   
        <div>
            <div class="wrap-pt-grid  bg-blue-200 w-full">
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span>Клиенты без оплаты</span></div>
                <div class=" table-cell"><span></span></div>
                <div class="table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
            </div>
            <div class="wrap-pt-grid  bg-[#edede3] w-full">
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span>Категория более 50 ТС</span></div>
                <div class=" table-cell"><span></span></div>
                <div class="table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
            </div>
            <div class="wrap-pt-grid  bg-white w-full" v-for='item in this.plan.client_nopay_5' :key="item">
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{item.count}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]" @click="goToClient(item)"><span>{{ item.full_name }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.countcars != null? item.countcars : "" }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_margin_invoice.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class="table-cell" @click="item.ids_margin_invoice.length != 0? openThisInvoiceList(item.ids_margin_invoice): undefined"><span>{{ item.margin_invoice != null? item.margin_invoice : ""  }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.income_lead != null? item.income_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.margin_lead != null? item.margin_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_invoce.length != 0,
                 'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_invoce.length !=0 ? openThisInvoiceList(item.ids_prolon_invoce) : undefined"><span>{{ item.margin_prolon_invoice != null? item.margin_prolon_invoice : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_lead.length !=0 ? openThisLeadList(item.ids_prolon_lead) : undefined"><span>{{ item.margin_prolon_lead != null? item.margin_prolon_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_plan != null? item.all_margin_plan : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_fact != null? item.all_margin_fact : ""}}</span></div>
                <div v-if="item.percent_plan_fact == 0" class=" table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-else-if="item.percent_plan_fact <=80" class="bg-red-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=81 && item.percent_plan_fact <=89" class="bg-orange-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=90 && item.percent_plan_fact <=99" class="bg-yellow-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >= 100" class="bg-green-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
            </div>
            <div class="wrap-pt-grid  bg-[#edede3] w-full">
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span>Категория  31-50 ТС</span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
            </div>
            <div class="wrap-pt-grid  bg-white w-full" v-for='item in this.plan.client_nopay_4' :key="item">
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{item.count}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]" @click="goToClient(item)"><span>{{ item.full_name }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.countcars != null? item.countcars : "" }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_margin_invoice.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class="table-cell" @click="item.ids_margin_invoice.length != 0? openThisInvoiceList(item.ids_margin_invoice): undefined"><span>{{ item.margin_invoice != null? item.margin_invoice : ""  }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.income_lead != null? item.income_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.margin_lead != null? item.margin_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_invoce.length != 0,
                 'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_invoce.length !=0 ? openThisInvoiceList(item.ids_prolon_invoce) : undefined"><span>{{ item.margin_prolon_invoice != null? item.margin_prolon_invoice : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_lead.length !=0 ? openThisLeadList(item.ids_prolon_lead) : undefined"><span>{{ item.margin_prolon_lead != null? item.margin_prolon_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_plan != null? item.all_margin_plan : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_fact != null? item.all_margin_fact : ""}}</span></div>
                <div v-if="item.percent_plan_fact == 0" class=" table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-else-if="item.percent_plan_fact <=80" class="bg-red-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=81 && item.percent_plan_fact <=89" class="bg-orange-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=90 && item.percent_plan_fact <=99" class="bg-yellow-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >= 100" class="bg-green-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
            </div>
            <div class="wrap-pt-grid  bg-[#edede3] w-full">
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span>Категория  11-30 ТС</span></div>
                <div class=" table-cell"><span></span></div>
                <div class="table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
            </div>
            <div class="wrap-pt-grid  bg-white w-full" v-for='item in this.plan.client_nopay_3' :key="item">
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{item.count}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]" @click="goToClient(item)"><span>{{ item.full_name }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.countcars != null? item.countcars : "" }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_margin_invoice.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class="table-cell" @click="item.ids_margin_invoice.length != 0? openThisInvoiceList(item.ids_margin_invoice): undefined"><span>{{ item.margin_invoice != null? item.margin_invoice : ""  }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.income_lead != null? item.income_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.margin_lead != null? item.margin_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_invoce.length != 0,
                 'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_invoce.length !=0 ? openThisInvoiceList(item.ids_prolon_invoce) : undefined"><span>{{ item.margin_prolon_invoice != null? item.margin_prolon_invoice : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_lead.length !=0 ? openThisLeadList(item.ids_prolon_lead) : undefined"><span>{{ item.margin_prolon_lead != null? item.margin_prolon_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_plan != null? item.all_margin_plan : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_fact != null? item.all_margin_fact : ""}}</span></div>
                <div v-if="item.percent_plan_fact == 0" class=" table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-else-if="item.percent_plan_fact <=80" class="bg-red-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=81 && item.percent_plan_fact <=89" class="bg-orange-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=90 && item.percent_plan_fact <=99" class="bg-yellow-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >= 100" class="bg-green-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
            </div>
            <div class="wrap-pt-grid  bg-[#edede3] w-full">
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span>Категория  2-10 ТС</span></div>
                <div class=" table-cell"><span></span></div>
                <div class="table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
            </div>
            <div class="wrap-pt-grid  bg-white w-full" v-for='item in this.plan.client_nopay_2' :key="item">
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{item.count}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]" @click="goToClient(item)"><span>{{ item.full_name }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.countcars != null? item.countcars : "" }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_margin_invoice.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class="table-cell" @click="item.ids_margin_invoice.length != 0? openThisInvoiceList(item.ids_margin_invoice): undefined"><span>{{ item.margin_invoice != null? item.margin_invoice : ""  }}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.income_lead != null? item.income_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_lead.length !=0 ? openThisLeadList(item.ids_lead) : undefined"><span>{{ item.margin_lead != null? item.margin_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_invoce.length != 0,
                 'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_invoce.length !=0 ? openThisInvoiceList(item.ids_prolon_invoce) : undefined"><span>{{ item.margin_prolon_invoice != null? item.margin_prolon_invoice : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'cursor-pointer transition duration-150 ease-in hover:bg-[#F28001]': item.ids_prolon_lead.length != 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell" @click="item.ids_prolon_lead.length !=0 ? openThisLeadList(item.ids_prolon_lead) : undefined"><span>{{ item.margin_prolon_lead != null? item.margin_prolon_lead : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_plan != null? item.all_margin_plan : ""}}</span></div>
                <div :class="{'bg-[#ffd7d7]': item.margin_invoice == 0 && item.income_lead == 0 && item.margin_lead == 0 && item.margin_prolon_invoice == 0 && item.margin_prolon_lead == 0 && item.all_margin_plan == 0 && item.all_margin_fact == 0,
                  'bg-[#cbfce5]': item.margin_invoice != 0 || item.income_lead != 0 || item.margin_lead != 0 || item.margin_prolon_invoice != 0 || item.margin_prolon_lead != 0 || item.all_margin_plan != 0 || item.all_margin_fact != 0,
                }" class=" table-cell"><span>{{ item.all_margin_fact != null? item.all_margin_fact : ""}}</span></div>
                <div v-if="item.percent_plan_fact == 0" class=" table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-else-if="item.percent_plan_fact <=80" class="bg-red-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=81 && item.percent_plan_fact <=89" class="bg-orange-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >=90 && item.percent_plan_fact <=99" class="bg-yellow-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
                <div v-if="item.percent_plan_fact >= 100" class="bg-green-400  table-cell"><span>{{ item.percent_plan_fact != null? `${item.percent_plan_fact}%` : ""}}</span></div>
            </div>
            <div class="wrap-pt-grid  bg-white w-full" >
                <div class="bg-[#F6A7DC]  table-cell"><span></span></div>
                <div class="bg-[#F6A7DC]  table-cell"><span>ИТОГО</span></div>
                <div class="bg-[#F6A7DC]  table-cell"><span></span></div>
                <div class="bg-[#F6A7DC] table-cell"><span>Cумма маржи по счетам</span></div>
                <div class="bg-[#F6A7DC]  table-cell"><span>Сумма доходов по ЛИДу</span></div>
                <div class="bg-[#F6A7DC]  table-cell"><span>Сумма маржи по ЛИДам</span></div>
                <div class="bg-[#F6A7DC]  table-cell"><span>Сумма маржи пролонгаций счетов</span></div>
                <div class="bg-[#F6A7DC]  table-cell"><span>Сумма маржи пролонгаций ЛИДов</span></div>
                <div class="bg-[#F6A7DC]  table-cell"><span>Сумма плановой маржи</span></div>
                <div class="bg-[#F6A7DC]  table-cell"><span>Сумма фактической маржи</span></div>
                <div class="bg-[#F6A7DC]  table-cell"><span>Среднее отклонение от плана</span></div>
            </div>
            <div class="wrap-pt-grid  bg-white w-full" >
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class=" table-cell"><span></span></div>
                <div class="table-cell"><span>{{ this.marginInvoiceSum }}</span></div>
                <div class=" table-cell"><span>{{ this.inCUMLeadSum }}</span></div>
                <div class=" table-cell"><span>{{ this.marginLeadSum }}</span></div>
                <div class=" table-cell"><span>{{ this.prolonInvoiceMarginSum }}</span></div>
                <div class=" table-cell"><span>{{ this.prolonLeadMarginSum }}</span></div>
                <div class=" table-cell"><span>{{ this.allMarginPlanSum }}</span></div>
                <div class=" table-cell"><span>{{ this.allMarginFactSum }}</span></div>
                <div v-if="this.percentPlanFactSum == 0" class=" table-cell"><span>{{ `${this.percentPlanFactSum}%` }}</span></div>
                <div v-else-if="this.percentPlanFactSum <=80" class="bg-red-400  table-cell"><span>{{ `${this.percentPlanFactSum}%` }}</span></div>
                <div v-if="this.percentPlanFactSum >=81 && this.percentPlanFactSum <=89" class="bg-orange-400  table-cell"><span>{{ `${this.percentPlanFactSum}%` }}</span></div>
                <div v-if="this.percentPlanFactSum >=90 && this.percentPlanFactSum <=99" class="bg-yellow-400  table-cell"><span>{{ `${this.percentPlanFactSum}%` }}</span></div>
                <div v-if="this.percentPlanFactSum >= 100" class="bg-green-400  table-cell"><span>{{ `${this.percentPlanFactSum}%` }}</span></div>
            </div>
        </div>  
    </div> 
    <div
      v-if="showLeadsModal"
      id="popnew2"
      class="w-10/12 bg-slate-200 fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-y-auto overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
    >
      <LeadsListModal :LeadList="factLeadList" />
    </div>
    <div
      v-if="showInvoicesModal"
      id="popnew4"
      class="w-10/12 bg-slate-200 fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-y-auto overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
    >
      <InvoiceListModal :InvoiceList="factInvoiceList" />
    </div>
    <div
    v-if="
      showLeadsModal || showInvoicesModal
    "
    @click="
      showLeadsModal = false;
      showInvoicesModal = false;
    "
    id="overlay"
    class="fixed bg-cyan-800 z-10 w-full h-full top-0 left-0 opacity-80"
  ></div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import { toRaw } from "vue";
  import router from "@/router/router";


  import LeadsListModal from "@/components/report/LeadsListModal.vue";
  import InvoiceListModal from "@/components/report/InvoiceListModal.vue";

  export default {
    name: "PlanOrgiaChertey",

    components: {
    LeadsListModal,
    InvoiceListModal,
  },
    data() {
      return {
        text_manager: "",
        choosenManager: {},
        managerList: [],
        showPlanList: false,
        dateFrom: null,
        plan: [],
        orgiaITOGO: [],
        marginInvoiceSum: 0, 
        inCUMLeadSum:0,
        marginLeadSum: 0,
        allMarginPlanSum: 0,
        allMarginFactSum: 0,
        percentPlanFactSum: 0,
        meanPercent: 0,
        orgiaSum: 0,
        prolonInvoiceMarginSum: 0,
        prolonLeadMarginSum: 0,
        showLeadsModal: false,
        showInvoicesModal: false,
        loading: false
      };
    },
  
    computed: {
      ...mapGetters(["s", "profile", "selectManagers", "planOrgia", "factLeadList", "factInvoiceList",]),
    },
    methods: {
      ...mapActions(["getManagerPlanOrgia","getClientforPlan","getFactLeadList", "getFactInvoiceList"]),
      findManager() {
        console.log("findMaster", this.selectManagers);
        this.managerList = this.selectManagers.filter(
          (item) =>
            item.name.toLowerCase().indexOf(this.text_manager.toLowerCase()) !==
            -1
        );
      },
      async openThisInvoiceList(idList) {
      await this.getFactInvoiceList({ ids: Object.assign([], idList) });
      setTimeout(() => {
        this.showInvoicesModal = true;
      }, 1100);
    },
      openThisLeadList(idList) {
      this.getFactLeadList({ ids: Object.assign([], idList) });

      setTimeout(() => {
        this.showLeadsModal = true;
      }, 1100);
    },
      goToClient(data){
        this.getClientforPlan(data)
        router.push("/client/view")
      },
      calacITOGO(){
        for(let item of this.orgiaITOGO){
          if(item != undefined){
            this.orgiaSum += 1
          }
          if(item.margin_invoice!= undefined){
            this.marginInvoiceSum += Number(item.margin_invoice)
          }
          if(item.income_lead!= undefined){
            this.inCUMLeadSum += Number(item.income_lead)
          }
          if(item.margin_lead!= undefined){
            this.marginLeadSum += Number(item.margin_lead)
          }
          if(item.margin_prolon_invoice!= undefined){
            this.prolonInvoiceMarginSum += Number(item.margin_prolon_invoice)
          }
          if(item.margin_prolon_lead!= undefined){
            this.prolonLeadMarginSum += Number(item.margin_prolon_lead)
          }
          if(item.all_margin_plan!= undefined){
            this.allMarginPlanSum += Number(item.all_margin_plan)
          }
          if(item.all_margin_fact!= undefined){
            this.allMarginFactSum += Number(item.all_margin_fact)
          }
          if(item.percent_plan_fact!= undefined){
            this.meanPercent += Number(item.percent_plan_fact)
          }
          this.percentPlanFactSum = Math.round(this.meanPercent / this.orgiaSum)
        }
        console.log("marginInvoiceSum",this.marginInvoiceSum)
      },

      setManager(manager) {
        this.choosenManager = manager;
      },
      async formActivity() {
        this.showPlanList = false;
        this.loading = true
        await this.getManagerPlanOrgia({
          manager_id: this.choosenManager.uid,
          date: this.dateFrom
        });
        this.plan = toRaw(this.planOrgia)
        this.orgiaITOGO = [
        ...Object.values(this.plan.client_active_2 || {}),
        ...Object.values(this.plan.client_active_3 || {}),
        ...Object.values(this.plan.client_active_4 || {}),
        ...Object.values(this.plan.client_active_5 || {}),
        ...Object.values(this.plan.client_back_2 || {}),
        ...Object.values(this.plan.client_back_3 || {}),
        ...Object.values(this.plan.client_back_4 || {}),
        ...Object.values(this.plan.client_back_5 || {}),
        ...Object.values(this.plan.client_passive_2 || {}),
        ...Object.values(this.plan.client_passive_3 || {}),
        ...Object.values(this.plan.client_passive_4 || {}),
        ...Object.values(this.plan.client_passive_5 || {}),
        ...Object.values(this.plan.client_nopay_2 || {}),
        ...Object.values(this.plan.client_nopay_3 || {}),
        ...Object.values(this.plan.client_nopay_4 || {}),
        ...Object.values(this.plan.client_nopay_5 || {}),
];

        this.calacITOGO()
        this.loading = false
        this.showPlanList = true;
      },
    },
    async mounted() {
        var currentYear = new Date().getFullYear();
    var lastMonth = new Date().getMonth();
    var currentMonth = lastMonth + 1;

    if (currentMonth < 10) {
      this.dateFrom = "" + currentYear + "-0" + currentMonth;
    } else {
      this.dateFrom = "" + currentYear + "-" + currentMonth;
    }
    }
  };
  </script>
  
  <style scoped>
  .month-report-top {
    padding: 12px 15px;
    border-radius: 4px;
  }

  .button-form-report {
    border-radius: 8px;
    background: #22c55e;
    border: none;
    color: #fff;
    text-align: center;
    font-size: 13.5px;
    font-weight: 700;
    line-height: 13.5px;
    text-transform: uppercase;
    padding: 15px 88px;
  }
  .wrap-pt-grid {
  display: grid;
  grid-template-columns: 40px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.table-cell{ 
text-align: center;
border-width: 1px;
text-wrap: wrap;
vertical-align: middle;
overflow-wrap: break-word;
text-overflow: clip; 
overflow: hidden;
padding-top: 5px;
padding-bottom: 5px;
}
  </style>