<template>
  <div v-if="showProlon" class="w-full flex flex-wrap items-center">
    <h2
      class="mr-15px h-9 min-w-[2.25rem] border border-teal-700 rounded-lg p-2 text-center my-4"
    >
      <span class="w-full text-lg leading-none text-teal-700">
        {{ listBirthdays?.length }}
      </span>
    </h2>

    <!-- <div class="flex-2_0 w-1/6 my-4">
      <input
        type="text"
        class="h-9 w-full pl-3 py-2 text-sm bg-slate-50"
        placeholder="Название"
        v-model="s.findme"
        @input="findBday(s)"
      />
    </div> -->

    <div class="flex py-4">
      <button class="v-btn bg-gray-500 hover:bg-gray-400 mr-4" v-if="s.clear">
        <span class="v-btn_text" @click="Clear()">Сброс</span>
      </button>
      <!-- <template v-if="['Manager', 'Logist', 'Studio', 'Hosd', 'Check'].includes(role)">
                    <button class="v-btn bg-green-500 hover:bg-green-400">
                        <span class="v-btn_text flex items-center" @click="addOrder(null)">
                            <svg class="fill-white w-16px h-16px mr-3 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linear">
                                <use xlink:href="@/assets/i.svg#ico-no"></use>
                            </svg>
                            Создать
                        </span>
                    </button>
                </template> -->
    </div>
  </div>

  <select
    v-if="showProlon"
    v-model="birthdaysPaginator.itemsInPage"
    style="padding: 7px"
  >
    <option value="10">10</option>
    <option value="20">20</option>
    <option value="50">50</option>
    <option value="100">100</option>
  </select>
  <Paginator
    v-if="showProlon"
    :count="pageCount"
    v-model="birthdaysPaginator.page"
  />

  <div
    v-if="gotListBDays?.length > 0 && showProlon"
    class="w-full bg-white v-bgray2 mb-8 overflow-hidden rounded-xl relative overflow-x-auto"
  >
    <div
      style="grid-template-columns: repeat(22, 1fr)"
      class="grid grid-cols-15 h-10 v-bbgray px-4 uppercase items-center"
    >
      <div
        class="ml-4 font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-5"
      >
        Фио
      </div>
      <div
        class="ml-2 font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-2"
      >
        Дата рождения
      </div>
      <div
        class="ml-2 font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-5"
      >
        Клиент
      </div>
      <div
        class="ml-2 font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-4"
      >
        Роль в карточке
      </div>
      <div
        class="ml-2 font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-2"
      >
        Количество тс
      </div>
      <div
        class="ml-2 font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-4"
      >
        Ответственный менеджер
      </div>
    </div>
    <template v-for="item in PaginatedBirthdayList" :key="item">
      <a
        style="grid-template-columns: repeat(22, 1fr)"
        class="grid grid-cols-order px-4 py-2 gap-2 v-bbgray bg-teal-light odd:bg-white items-center transition duration-300 cursor-pointer hover:shadow-v1 max-2xl:gr id-cols-order_m max-2xl:auto-rows-[50px]"
        :class="'status-' + item.status"
      >
        <div
          class="flex justify-start items-center overflow-hidden pr-4 col-span-5"
        >
          <span
            class="text-white w-11 h-11 mr-2 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px] bg-status-1"
          >
            {{ item.cname?.slice(0, 1) }}
          </span>
          <span class="text-base font-semibold break-all max-h-10 leading-5">
            {{ item.csecondname }} {{ item.cname }} {{ item.cmiddlename }}
          </span>
        </div>
        <div
          class="flex justify-start items-center overflow-hidden pr-4 h-full col-span-2"
        >
          <div class="flex items-center mb-1">
            <!-- <div class="text-black text-sm">
              {{ normalBday(item.cbirthday) }}
            </div> -->
            <input
              type="date"
              v-model="item.cbirthday"
              class="py-2 px-3 ml-2"
              style="border-radius: 5px"
              @change="saveNewBday(item)"
              name=""
              id=""
            />
          </div>
        </div>
        <div
          class="flex justify-start items-center overflow-hidden pr-4 col-span-5"
        >
          <span
            class="text-white w-11 h-11 mr-2 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px] bg-green-500"
          >
            {{ item.full?.slice(0, 1) }}
          </span>
          <span class="text-base font-semibold break-all max-h-10 leading-5">
            {{ item.full }}
          </span>
        </div>
        <div
          class="flex justify-start items-center overflow-hidden pr-4 col-span-4"
        >
          <span class="text-base font-semibold break-all max-h-10 leading-5">
            {{ roletext[item.crole - 1] }}
          </span>
        </div>
        <div
          class="flex justify-start items-center overflow-hidden pr-4 col-span-2"
        >
          <span class="text-base font-semibold break-all max-h-10 leading-5">
            {{ item.countcars }} шт.
          </span>
        </div>
        <div
          class="flex justify-start items-center overflow-hidden pr-4 col-span-4"
        >
          <span
            v-if="!item.msecondname"
            class="w-11 h-11 bg-slate-200 flex justify-center rounded-full items-center overflow-hidden mr-2"
          >
            <svg class="w-4 h-4 fill-white">
              <use xlink:href="@/assets/i.svg#ico-avatar"></use>
            </svg>
          </span>
          <div v-else class="min-w-[1.5rem] rounded-full mr-3 overflow-hidden">
            <IcoPic :pic="item.mpicture" :name="item.mname" />
          </div>
          <span class="text-base font-semibold break-all max-h-10 leading-5">
            {{ item.msecondname }} {{ item.mname }}
          </span>
        </div>
      </a>
    </template>
  </div>
  <div v-else-if="s.loader" class="absolute top-60 left-0 flex-cc w-full">
    <img src="https://api.tahocrm.ru/images/loader.svg" />
  </div>
  <div
    v-else
    class="top-50 left-0 flex-cc w-full text-slate-300 text-2xl text-center"
  >
    Нет данных
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Paginator from "@/components/ui/Paginator";

export default {
  name: "ProlonList",

  components: { Paginator },

  data() {
    return {
      showProlon: false,
      moment: moment,
      delay: { 1: "СРОЧНО", 2: "МЕНЕДЖЕР", 3: "КЛИЕНТ" },
      checked: [],
      massCheckValue: false,
      action: "",
      gotListBDays: [],

      roletext: [
        "ответственный за тахограф",
        "ответственный за мониторинг",
        "ответственный за пропуск",
        "ответственный за транспорт (все)",
        "ответственный за карты",
        "бухгалтерия",
        "получение СМС",
      ],
    };
  },

  computed: {
    ...mapGetters([
      "s",
      "profile",
      "role",
      "roles",
      "listBirthdays",
      "birthdaysPaginator",
    ]),

    page() {
      return this.birthdaysPaginator.page;
    },

    itemsInPage() {
      return this.birthdaysPaginator.itemsInPage;
    },
    pageCount() {
      return Math.ceil((this.gotListBDays?.length ?? 0) / this.itemsInPage);
    },
    PaginatedBirthdayList() {
      return this.gotListBDays.slice(
        this.page * this.itemsInPage,
        (this.page + 1) * this.itemsInPage
      );
    },
  },

  methods: {
    ...mapActions([
      "getBirthdays",
      "addOrder",
      "setOrder",
      "showOrder",
      "findOrder",
      "statusOrder",
      "find",
      "setSettings",
      "clearSettings",
      "fillOrders",
      "filterPayNoAnket",
      "GroupOrdersToAgregator",
      "setNewBday",
    ]),
    normalBday(bDay) {
      return bDay;
    },
    async saveNewBday(client) {
      await this.setNewBday({ id: client.cid, bday: client.cbirthday });
    },
    sortByClosestBirthday() {
      const now = new Date(); // Текущая дата
      const currentYear = now.getFullYear();
      //   console.log("this.gotListBDays", this.gotListBDays[0].cbirthday);
      this.gotListBDays.sort((a, b) => {
        // Преобразуем дни рождения в формат текущего года
        const aBirthday = this.getNextBirthday(a.cbirthday, currentYear, now);
        const bBirthday = this.getNextBirthday(b.cbirthday, currentYear, now);

        // Сравниваем даты
        return aBirthday - bBirthday;
      });
    },
    getNextBirthday(birthday, year, now) {
      // Создаём дату дня рождения для текущего года
      const birthdayThisYear = new Date(`${year}-${birthday.slice(5)}`);

      // Если день рождения уже прошёл, добавляем 1 год
      if (birthdayThisYear < now) {
        birthdayThisYear.setFullYear(year + 1);
      }

      return birthdayThisYear.getTime(); // Возвращаем в миллисекундах для сравнен
    },

    SelectAllPage() {
      console.log(this.massCheckValue);
      if (this.massCheckValue) {
        this.checked.push(...this.PaginatedBirthdayList.map(({ id }) => id));
      } else {
        let ids = new Set(this.PaginatedBirthdayList.map(({ id }) => id));
        this.checked = this.checked.filter((id) => !ids.has(id));
      }
    },
  },

  async mounted() {
    await this.getBirthdays();
    this.showProlon = true;

    this.gotListBDays = Object.assign([], this.listBirthdays);

    this.gotListBDays.forEach((user) => {
      if (user.cbirthday == null) {
        console.log(
          "Клиент с пустым днём рождения: ",
          user.cname + " " + user.cmiddlename + " " + user.csecondname
        );
      }
    });
    this.sortByClosestBirthday();
  },
};
</script>
