<template>
    <div class="pb-4 w-full flex flex-wrap items-center">
        <h2 class="mr-15px h-9 min-w-[2.25rem] border border-teal-700 rounded-lg p-2 text-center my-4">
            <span class="w-full text-lg leading-none text-teal-700">
                {{ this.ClientDoubles?.length }}
            </span>
        </h2>
    </div>

    <template v-if="ClientDoubles?.length > 0">
        <template v-for="item in ClientDoubles" :key="item">
            <div class="w-full bg-white v-bgray2 mb-4 overflow-hidden rounded-xl relative overflow-x-auto">
                <div class="flex px-4 py-2 v-bbgray items-center bg-teal-light odd:bg-white">
                    <div class="w-20"><span class="bg-cyan-500 text-white p-1 rounded">КЛИЕНТ</span></div>
                    <div class="w-16">{{ item.client.iid }}</div>

                    <a class="flex w-3/4 items-center cursor-pointer group" @click="showClient(item.client)">
                        <div
                            class="text-white w-11 h-11 mr-4 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px] bg-status-6"
                        >
                            <IcoPic :pic="item.client.picture" :name="item.client.full" />
                        </div>

                        <span class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300">{{ item.client.full }}</span>
                    </a>
                    <a class="v-btn bg-sky-500 hover:bg-sky-400 place-self-end" @click="checkedDouble(item.client)">
                        <span class="v-btn_text">ПРОВЕРЕН</span>
                    </a>
                </div>

                <div v-for="double in item.doubles" :key="double" class="flex px-4 py-2 v-bbgray items-center bg-teal-light odd:bg-white">
                    <div class="w-20"><span class="bg-yellow-500 text-white p-1 rounded">ДУБЛЬ</span></div>
                    <div class="w-16">{{ double.iid }}</div>

                    <a class="flex w-3/4 items-center cursor-pointer group" @click="showClient(double)">
                        <div
                            class="text-white w-11 h-11 mr-4 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px] bg-status-6"
                        >
                            <IcoPic :pic="double.picture" :name="double.full" />
                        </div>

                        <span class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300">{{ double.full }}</span>

                        <div class="w-20">
                            <span v-if="double.del > 0" class="text-red-500 mx-2">УДАЛЕН</span>
                        </div>
                    </a>
                    <!-- <a class="v-btn bg-sky-500 hover:bg-sky-400 place-self-end" @click="checkedDouble(double)">
                        <span class="v-btn_text">ПРОВЕРЕН</span>
                    </a> -->
                </div>
            </div>
        </template>
    </template>
    <div v-else-if="s.loader" class="absolute top-32 left-0 flex-cc w-full"><img src="https://api.tahocrm.ru/images/loader.svg" /></div>
    <div v-else class=" top-32 left-0 flex-cc w-full text-slate-300 text-2xl text-center">Нет данных</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "ClientDouble",

    computed: {
        ...mapGetters(["s", "role", "profile", "ClientDoubles", "roles", "ClientCounter", "selectManagers", "selectMasters"]),
    },

    methods: {
        ...mapActions(["getClientDoubles", "showClient", "clearSettings", "checkedDouble"]),
    },

    mounted() {
        this.getClientDoubles();
    },
};
</script>
