import { createRouter, createWebHistory } from "vue-router";
import authService from "../services/auth";

import MainPage from "@/pages/MainPage";
import ClaimList from "@/pages/Claim";
import ClaimView from "@/pages/ClaimView";
import ClientArhive from "@/pages/ClientArhive";
import ClientMap from "@/pages/ClientMap";
import ClientView from "@/pages/ClientView";
import CarList from "@/pages/Car";
import CarReport from "@/pages/CarReport";
import LeadList from "@/pages/Lead";
import LeadEdit from "@/pages/LeadEdit";
import LeadSource from "@/pages/LeadSource";
import OrderList from "@/pages/Order";
import OrderArhive from "@/pages/OrderArhive";
import OrderEdit from "@/pages/OrderEdit";
import OrderView from "@/pages/client/OrderView";
import PageFilial from "@/pages/Filial";
import PageProfile from "@/pages/Profile";
import PageCalendar from "@/pages/Calendar";
import PagePrice from "@/pages/Price";
import PageWorker from "@/pages/Worker";
import PriceView from "@/pages/PriceView";
import PriceCategories from "@/pages/PriceCategories";
import WorkerView from "@/pages/WorkerView";
import PageSalary from "@/pages/Salary";
import PageClient from "@/pages/Client";
import ClientDouble from "@/pages/ClientDouble";
import TaskList from "@/pages/Task";
import PageMarketing from "@/pages/Marketing";
import PageCheck from "@/pages/Check";
import PageMoney from "@/pages/Money";
import PageInvoiceMoney from "@/pages/InvoiceMoney";

import PageStatistic from "@/pages/Statistic";

import MyInfo from "@/pages/client/MyInfo";
import MyCompany from "@/pages/client/MyCompany";
import MyContact from "@/pages/client/MyContact";
import MyCar from "@/pages/client/MyCar";
import Agregator from "@/pages/Agregator";      
import Report from "@/pages/Report";

import Touches from "@/pages/TouchesView";
import TouchEdit from "@/pages/TouchEdit";
import BidList from "@/pages/BidList";
import BidEdit from "@/pages/BidEdit";

import InvoiceList from "@/pages/InvoiceList";
import InvoiceEdit from "@/pages/InvoiceEdit";
import ActivityPage from "@/pages/ActivityPage";
import PlansPage from "@/pages/PlansPage";
import FactsPage from "@/pages/FactsPage";
import ReportPlanStatsManager from "@/components/reports/ReportPlanStatsManager.vue"
import ManagerFactsPage from "@/pages/ManagerFactsPage";

import ActivityPageDirector from "@/pages/ActivityPageDirector";
import OrdersHosd from "@/pages/OrdersHosd";
import Briefcase from "@/pages/PortfelClient";


import Prolon from "@/pages/Prolon";
import SellChannel from "@/pages/SellChannel";

import LearningBase from "@/pages/LearningBase.vue";
import LearningBaseAddArticle from "@/pages/LearningBaseAddArticle.vue";
import LearningBaseShowArticle from "@/pages/LearningBaseShowArticle.vue";
import LearningBaseEditArticle from "@/pages/LearningBaseEditArticle.vue";

import Plan from "@/pages/Plan.vue";


const routes = [
    {
        path: "/",
        name: "MainPage",
        component: MainPage,
    },
    {
        path: "/login",
        name: "Login",
        component: MainPage,
    },
    {
        path: "/calendar",
        name: "PageCalendar",
        component: PageCalendar,
        meta: { title: "Календарь" },
    },
    {
        path: "/claim",
        name: "ClaimList",
        component: ClaimList,
        meta: { title: "Рекламации" },
    },
    {
        path: "/claim/view",
        name: "ClaimView",
        component: ClaimView,
        meta: { title: "Рекламация" },
    },
    {
        path: "/lead",
        name: "LeadList",
        component: LeadList,
        meta: { title: "Лиды" },
    },
    {
        path: "/lead/edit",
        name: "LeadEdit",
        component: LeadEdit,
        meta: { title: "Лид" },
    },
    {
        path: "/lead/source",
        name: "LeadSource",
        component: LeadSource,
        meta: { title: "Настройки лида" },
    },
    {
        path: "/order",
        name: "OrderList",
        component: OrderList,
        meta: { title: "Заказы" },
    },
    {
        path: "/car",
        name: "CarList",
        component: CarList,
        meta: { title: "ТС" },
    },
    {
        path: "/car/report",
        name: "CarReport",
        component: CarReport,
        meta: { title: "ТС Отчет" },
    },
    {
        path: "/order/arhive",
        name: "OrderArhive",
        component: OrderArhive,
        meta: { title: "Заказы" },
    },
    {
        path: "/order/edit",
        name: "OrderEdit",
        component: OrderEdit,
        meta: { title: "Заказ" },
    },
    {
        path: "/order/view",
        name: "OrderView",
        component: OrderView,
        meta: { title: "Заказ" },
    },
    {
        path: "/price",
        name: "PagePrice",
        component: PagePrice,
        meta: { title: "Услуги" },
    },
    {
        path: "/price/view",
        name: "PriceView",
        component: PriceView,
        meta: { title: "Услуга" },
    },
    {
        path: "/price/categories",
        name: "PriceCategories",
        component: PriceCategories,
        meta: { title: "Категории" },
    },
    {
        path: "/filial",
        name: "PageFilial",
        component: PageFilial,
        meta: { title: "Филиалы" },
    },
    {
        path: "/worker",
        name: "PageWorker",
        component: PageWorker,
        meta: { title: "Сотрудники" },
    },
    {
        path: "/worker/view",
        name: "WorkerView",
        component: WorkerView,
        meta: { title: "Сотрудник" },
    },
    {
        path: "/salary",
        name: "PageSalary",
        component: PageSalary,
        meta: { title: "Зарплаты" },
    },
    {
        path: "/client",
        name: "PageClient",
        component: PageClient,
        meta: { title: "Клиенты" },
    },
    {
        path: "/client/doubles",
        name: "ClientDouble",
        component: ClientDouble,
        meta: { title: "Дубли клиентов" },
    },
    {
        path: "/client/arhive",
        name: "ClientArhive",
        component: ClientArhive,
        meta: { title: "Клиенты" },
    },
    {
        path: "/client/map",
        name: "ClientMap",
        component: ClientMap,
        meta: { title: "Карта" },
    },
    {
        path: "/client/view",
        name: "ClientView",
        component: ClientView,
        meta: { title: "Клиент" },
    },
    {
        path: "/client/info",
        name: "MyInfo",
        component: MyInfo,
        meta: { title: "Клиент" },
    },
    {
        path: "/client/company",
        name: "MyCompany",
        component: MyCompany,
        meta: { title: "Компании" },
    },
    {
        path: "/client/contact",
        name: "MyContact",
        component: MyContact,
        meta: { title: "Контакты" },
    },
    {
        path: "/client/car",
        name: "MyCar",
        component: MyCar,
        meta: { title: "ТС" },
    },
    {
        path: "/task",
        name: "TaskList",
        component: TaskList,
        meta: { title: "Задачи" },
    },
    {
        path: "/marketing",
        name: "PageMarketing",
        component: PageMarketing,
        meta: { title: "Маркетинг" },
    },
    {
        path: "/check",
        name: "PageCheck",
        component: PageCheck,
        meta: { title: "Контроль" },
    },
    {
        path: "/money",
        name: "PageMoney",
        component: PageMoney,
        meta: { title: "Приход ДС" },
    },
    {
        path: "/invoice-moneys",
        name: "PageInvoiceMoney",
        component: PageInvoiceMoney,
        meta: { title: "ДС Счетов" },
    },
    {
        path: "/statistic",
        name: "PageStatistic",
        component: PageStatistic,
        meta: { title: "Статистика" },
    },
    {
        path: "/profile",
        name: "PageProfile",
        component: PageProfile,
        meta: { title: "Профиль" },
    },
    {
        path: "/agregator",
        name: "PageAgregator",
        component: Agregator,
        meta: { title: "Агрегатор" },
    },
    {
        path: "/report",
        name: "Report",
        component: Report,
        meta: { title: "Отчёты" },
    },
    {
        path: "/touch/touches",
        name: "Touches",
        component: Touches,
        meta: { title: "Касания" },
    },
    {
        path: "/touch/edit",
        name: "TouchEdit",
        component: TouchEdit,
        meta: { title: "Касание" },
    },
    {
        path: "/bid",
        name: "BidList",
        component: BidList,
        meta: { title: "Заявки" },
    },
    {
        path: "/bid/edit",
        name: "BidEdit",
        component: BidEdit,
        meta: { title: "Заявка" },
    },
    {
        path: "/invoice",
        name: "InvoiceList",
        component: InvoiceList,
        meta: { title: "Счета" },
    },
    {
        path: "/invoice/edit",
        name: "InvoiceEdit",
        component: InvoiceEdit,
        meta: { title: "Счет" },
    },
    {
        path: "/report/activity",
        name: "ActivityPage",
        component: ActivityPage,
        meta: { title: "Активность" },
    },
    {
        path: "/activity/director",
        name: "ActivityPageDirector",
        component: ActivityPageDirector,
        meta: { title: "Активность директора" },
    },
    {
        path: "/plans",
        name: "PlansPage",
        component: PlansPage,
        meta: { title: "План менеджеров" },
    },
    {
        path: "/facts",
        name: "FactsPage",
        component: FactsPage,
        meta: { title: "Факт менеджеров" },
    },
    {
        path: "/factManager",
        name: "ReportPlanStatsManager",
        component: ReportPlanStatsManager,
        meta: { title: "Факт менеджера" },
    },
    {
        path: "/manager-facts",
        name: "ManagerFactsPage",
        component: ManagerFactsPage,
        meta: { title: "Факт менеджера" },
    },
    {
        path: "/prolon",
        name: "Prolon",
        component: Prolon,
        meta: { title: "Заказы с пролонгацией" },
    },
    {
        path: "/order_hosd",
        name: "OrdersHosd",
        component: OrdersHosd,
        meta: { title: "Заказы для РОП" },
    },
    {
        path: "/briefcase",
        name: "Briefcase",
        component: Briefcase,
        meta: { title: "Клиентский портфель" },
    },
    {
        path: "/repolead",
        name: "SellChannel",
        component: SellChannel,
        meta: { title: "Отчет по продажам" },
    },
    {
        path: "/learning_base",
        name: "LearningBase",
        component: LearningBase,
        meta: { title: "Справка по CRM"},
    },
    {
        path: "/learning_base/add_article",
        name: "LearningBaseAddArticle",
        component: LearningBaseAddArticle,
        meta: { title: "Создание новой статьи" },
    },
    {
        path: "/learning_base/show_article",
        name: "LearningBaseShowArticle",
        component: LearningBaseShowArticle,
        meta: { title: "Статья" },
    },
    {
        path: "/learning_base/edit_article",
        name: "LearningBaseEditArticle",
        component: LearningBaseEditArticle,
        meta: { title: "Редактирование статьи" },
    },
    {
        path: "/plan",
        name: "PlanOrgiaChertey",
        component: Plan,
        meta: { title: "План" },
    },
];

const router = new createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to) => {
    document.title = to.meta.title || "TahoCRM";
    localStorage.removeItem('leads'); 
    localStorage.removeItem('bids'); 
    localStorage.removeItem('orders'); 
    localStorage.removeItem('invoices'); 
    localStorage.removeItem('prices_bid'); 
    localStorage.removeItem('invoiceOrders'); 
    localStorage.removeItem('leads'); 
    localStorage.removeItem('invoiceMoneys'); 
    localStorage.removeItem('prices_order'); 
    localStorage.removeItem('touches');     
    if (
        // make sure the user is authenticated
        !authService.isLogged() &&
        // Avoid an infinite redirect
        to.name !== "Login" &&
        to.name !== "MainPage"
    ) {
        // redirect the user to the login page
        // this.$router.push('/')
        return { name: "Login" };
    }
    window.scrollTo(0, 0);
});

export default router;
