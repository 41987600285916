import { createApp } from "vue";
import Toaster from "@meforma/vue-toaster";
import App from "@/App.vue";
import "@/assets/tailwind.css";
import components from "@/components";


import store from "@/store/index";
import router from "@/router/router";

import VueAwesomePaginate from "vue-awesome-paginate";

const app = createApp(App);

components.forEach((component) => {
    app.component(component.name, component);
});

app.use(store);
app.use(Toaster, { position: "top-right" });
app.use(router);
app.use(VueAwesomePaginate);
app.mount("#app");
