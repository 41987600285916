<template>
    <div class="pb-4 w-full flex flex-wrap items-center">
      <h2
        class="mr-15px h-9 min-w-[2.25rem] border border-teal-700 rounded-lg p-2 text-center my-4"
      >
        <span class="w-full text-lg leading-none text-teal-700">
          Справка по CRM 
        </span>
      </h2>
      <div class="flex-2_0 mr-15px min-w-[180px] my-4">
        <input
          type="text"
          class="h-9 w-full px-3 py-2 text-sm bg-slate-50"
          placeholder="Поиск"
          v-model="s.findme"
          @input="findArticle(s)"
        />
      </div>
      <button
        v-if="s.clear"
        class="v-btn bg-gray-500 hover:bg-gray-400 mr-4"
        @click="Clear"
      >
        <span class="v-btn_text">Сброс</span>
      </button>
      <template v-if="['Studio', 'Hosd', 'Director', 'Finance'].includes(role)">
        <div class="flex">
          <button
            class="v-btn bg-green-500 hover:bg-green-400 transition-colors duration-150"
            @click="addNote"
          >
            <svg class="fill-white w-4 h-4 mr-3 rotate-45">
              <use xlink:href="@/assets/i.svg#ico-no"></use>
            </svg>
            <span class="v-btn_text">Создать</span>
          </button>
        </div>
      </template>
    </div>
  
    <div
      class="w-full bg-white v-bgray2 mb-8 overflow-hidden rounded-xl relative overflow-x-auto"
    >
      <div class="relative h-10 min-w-full">
        <div
          class="grid grid-cols-price-table items-center h-10 v-bbgray px-4 uppercase absolute min-w-full"
        >
          <div
            class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
            name="price.iid"
          >
            №
          </div>
          <div
            class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
            name="price.name"
          >
            Название
          </div>
  
        </div>
    </div>
  
      <div id="list" v-if="articlesList?.length > 0">
        <template v-for="item in articlesList" :key="item" >
          <div
            class="bg-teal-light odd:bg-white flex gap-14 v-bbgray p-4 items-center transition duration-300 hover:shadow-v1 cursor-pointer group"
            @click="showArticle(item)"
          >
            <div class="cell id">{{ item.id }}</div>
  
            <a
              class="font-latobold text-base transition-colors duration-150 group-hover:text-green-500"
              >{{ item.name }}</a
            >
  
          </div>
        </template>
      </div>
      <div v-else class="py-2 flex justify-center">
        <div>
          <img src="https://api.tahocrm.ru/images/loader.svg" width="44" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";

//   import router from "@/router/router";
  export default {
    name: "LearningBase",
  
    data() {
    return {
      learningArticles: undefined,
    }
  },


    computed: {
      ...mapGetters([
        "s",
        "profile",
        "role",
        "roles",
        "articlesList",
        "article"
      ]),
    },
  
    methods: {
      ...mapActions([
      "getAricles",
      "setArticle",
      "findArticle"
      ]),
  
      Clear() {
        this.clearSettings();
        this.findPrice(this.s);
      },
      addNote(){
        window.open("/learning_base/add_article", '_blank')
      },
      showArticle(item){
        this.setArticle(item)
      }
    },
  
    mounted() {
      this.getAricles()
    },
  };
  </script>
  