<template>
  <AlertMsg />
  <div class="h-full relative">
    <div
      class="absolute min-w-[212px] w-15p h-full overflow-hidden overflow-y-auto mob-max:overflow-y-auto pb-4 max-l:relative max-l:h-full max-l:w-full max-l:py-2"
    >
      <img
        class=" fixed object-cover top-0 min-h-full max-w-none max-l:w-full max-l:h-full z-1"
        src="@/assets/images/bg-leftbar.jpg"
        alt=""
      />
      <NavBar class="z-3"/>
    </div>
    <div
      class="grid grid-cols-main__container h-screen w-full overflow-y-auto max-l:grid-cols-1 z-2"
    >
      <div
        class="px-4 col-start-2 bg-slate-200 overflow-auto max-l:col-start-auto z-2"
      >
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  data() {
    return {
      // socketShit: "ws://192.168.50.32:2346",
    };
  },
  methods: {
    ...mapActions(["getProfile"]),
  },
  computed: {
    ...mapGetters(["s", "profile"]),
  },
  async mounted() {
    await this.getProfile();
    // console.log("test-profile", this.profile.uid);
    // const socket = new WebSocket(this.socketShit);
    // // // Получение пользователя для определения интерфейса
    // let user_data = this.profile.uid;
    // // // let menuData = [];
    // // // Отправка юзера на сервер
    // socket.onopen = function () {
    //   console.log("Соединение открыто " + user_data);
    //   const message = JSON.stringify({ data: user_data, action: "start" });
    //   socket.send(message);
    // };
    // // Обработка ответов на полученные сообщения
    // socket.onmessage = function (event) {
    //   let data = JSON.parse(event.data);
    //   console.log("Получены данные:", data);
    // };
  }
};
</script>

<style scoped>
.mclass {
  position: relative;
  min-height: 100%;
}
</style>
