<template>
  <div
    v-if="s.popup == 'view'"
    id="popnew"
    class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
  >
    <ContactView @close="close" />
  </div>
  <div
    v-if="s.popup == 'edit'"
    id="popnew"
    class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
  >
    <ContactEdit @close="close" />
  </div>
  <div
    v-if="s.popup"
    @click="close"
    id="overlay"
    class="fixed bg-cyan-900 z-2 w-screen h-screen top-0 left-0 opacity-80"
  ></div>

  <template v-if="ClientContacts">
    <div class="bg-white rounded-lg overflow-hidden">
      <div class="p-5 flex justify-between items-center v-bbgray">
        <h3 class="font-latobold text-base">
          Контакты {{ ClientContacts.length }}
        </h3>
        <template
          v-if="
            profile.uid == client.manager_id ||
            ['Assistant', 'Studio', 'Hosd'].includes(role)
          "
        >
          <a
            class="v-btn bg-green-500 hover:bg-green-400"
            @click="addContact(), (this.s.popup = 'edit')"
          >
            <svg class="fill-white w-4 h-4 mr-3 rotate-45">
              <use xlink:href="@/assets/i.svg#ico-no"></use>
            </svg>
            <span class="v-btn_text">Создать</span>
          </a>
        </template>
      </div>

      <div
        class="grid grid-cols-[1fr_16rem_10rem_10rem_6rem_4rem] px-5 py-2 v-bbgray"
      >
        <p class="text-gray-400 uppercase text-[11px]">ФИО</p>
        <p class="text-gray-400 uppercase text-[11px]">ОТВЕТСТВЕННЫЙ</p>
        <p class="text-gray-400 uppercase text-[11px]"></p>
        <p class="text-gray-400 uppercase text-[11px]">АКТИВАЦИЯ</p>
        <p class="text-gray-400 uppercase text-[11px]">КАБИНЕТ</p>
        <p class="text-gray-400 uppercase text-[11px]"></p>
      </div>
      <div
        v-for="item in ClientContacts"
        :key="item"
        class="grid grid-cols-[1fr_16rem_10rem_10rem_6rem_4rem] px-4 py-2 relative group items-center v-bbgray transition duration-300 cursor-pointer hover:shadow-v1 bg-teal-light odd:bg-white"
      >
        <div @click="showContact(item), (this.s.popup = 'view')">
          <div class="flex items-center group cursor-pointer">
            <div
              class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4 text-lg text-white"
            >
              {{ item.name?.slice(0, 1) }}
            </div>
            <span
              class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
              >{{ item.secondname }} {{ item.name }}</span
            >
          </div>
        </div>

        <div>{{ r[item.role] }}</div>

        <div class="items-center flex">
          <template
            v-if="
              profile.uid == client.manager_id ||
              ['Manager', 'Studio', 'Hosd'].includes(role)
            "
          >
            <a
              class="v-btn bg-gray-400 hover:bg-gray-500"
              @click="NewTouch(item.id)"
            >
              <span class="v-btn_text"> Касание</span>
            </a>
          </template>
        </div>

        <div class="flex">
          <template v-if="item.act == 1">
            <template v-if="item.mhd == 1">
              <svg class="w-5 h-5 fill-green-500 mr-2">
                <use xlink:href="@/assets/i.svg#ico-ok"></use>
              </svg>
              <label for="mhd">МЧД</label>
            </template>
            <template v-else>
              <svg class="w-5 h-5 fill-orange-500 mr-2">
                <use xlink:href="@/assets/i.svg#ico-ok"></use>
              </svg>
              <label for="mhd">МЧД</label>
            </template>
          </template>
        </div>

        <div class="items-center flex">
          <template v-if="item.lk">
            <svg class="w-5 h-5 fill-green-500 mr-2">
              <use xlink:href="@/assets/i.svg#ico-ok"></use>
            </svg>
            <a
              class="v-btn bg-sky-500 hover:bg-sky-400"
              @click="Enter(item.lk)"
            >
              <span class="v-btn_text"> ВОЙТИ</span>
            </a>
          </template>
        </div>

        <template
          v-if="
            profile.uid == client.manager_id ||
            ['Assistant', 'Studio', 'Hosd'].includes(role)
          "
        >
          <div
            class="flex absolute top-1/2 -translate-y-1/2 right-4 opacity-0 group-hover:opacity-100 transition-all duration-150"
          >
            <a
              class="cursor-pointer"
              @click="editContact(item), (this.s.popup = 'edit')"
            >
              <svg
                class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-green-500 transition-colors duration-150"
              >
                <use xlink:href="@/assets/i.svg#ico-edit"></use>
              </svg>
            </a>
            <a class="cursor-pointer ml-4" @click="delContact(item)">
              <svg
                class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
              >
                <use xlink:href="@/assets/i.svg#ico-no"></use>
              </svg>
            </a>
          </div>
        </template>
      </div>
    </div>
  </template>

  <div v-else class="loader opacity1 mt30">
    <div><img src="https://api.tahocrm.ru/images/loader.svg" width="44" /></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ContactView from "../components/client/ContactView";
import ContactEdit from "../components/client/ContactEdit";

export default {
  name: "ClientContact",

  components: { ContactView, ContactEdit },

  data() {
    return {
      r: {
        1: "за тахограф",
        2: "за мониторинг",
        3: "за пропуск",
        4: "за транспорт (все)",
        5: "за карты",
        6: "бухгалтерия",
      },
    };
  },

  computed: {
    ...mapGetters([
      "s",
      "profile",
      "role",
      "roles",
      "client",
      "ClientContacts",
    ]),
  },

  methods: {
    ...mapActions([
      "Tab",
      "getClientContacts",
      "addContact",
      "showContact",
      "editContact",
      "clearContact",
      "delContact",
      "closePopup",
      "goBack",
      "Enter",
      "getClientTabsContacts",
      "addTouchContact",
    ]),

    NewTouch(contacts_id) {
      if (this.client.manager_id == null) {
        alert("Нельзя создать касание, если у клиента нет менеджера!");
      } else {
        let formData = new FormData();
        console.log("client", this.client);

        formData.append("client_id", this.client.id);
        formData.append("contact_id", contacts_id);
        this.addTouchContact(formData);
      }
    },

    close() {
      this.clearContact();
      this.goBack();
    },
  },

  async mounted() {
    // if (this.role == "Client") {
    //     await this.getClientContacts();
    // }
    await this.getClientTabsContacts();
  },
};
</script>
