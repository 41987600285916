<template>
  <div
    v-if="s.popup == 'task'"
    id="popnew"
    class="popAp w-3/4 bg-white fixed left-1/2 -translate-x-1/2 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
  >
    <TaskEdit @close="close" class="z-999" />
  </div>
  <div
    v-if="s.popup"
    @click="close"
    class="fixed bg-cyan-900 z-2 w-screen h-screen top-0 left-0 opacity-80"
    id="overlay"
  ></div>
  <div class="h-full">
    <template  v-if="backM && backM == 1">
      <a
        class="relative z-50 mx-auto mt-4 v-btn bg-sky-500"
        @click="backToManager()"
      >
        <span class="v-btn_text">В кабинет менеджера</span>
      </a>
    </template>
    <!-- <div class="flex items-center"> -->
      <div class="absolute top-[50px] z-2" ref="bell">
        <a  :class="this.alertData.length != 0 && this.showAlerts == false?'bell block p-[2px] relative ml-[15px]': 'block p-[2px] relative ml-[15px]'" @click="this.showAlerts == false?
        this.showAlerts = true :
        this.showAlerts = false
        ">
        <div  v-if="this.alertData.length != 0" class="absolute z-2 right-[-5px] top-[-5px] w-[25px] bg-red-600 text-center text-white rounded-3xl hover:cursor-pointer">{{ this.alertData.length }}</div>
          <img
        class="relative w-[30px] fill-[#fff]" 
        src="../assets/images/bell_white.svg"
          /></a>
      </div>
    <a
      class="block w-24 h-24 my-5 mx-auto rounded-full overflow-hidden relative cursor-pointer max-l:hidden z-2"
      @click="$router.push('/profile')"
    >
      <img
        v-if="profile?.picture"
        class="w-full h-full object-cover z-2"
        :src="'https://api.tahocrm.ru/uploads/users/' + profile?.picture"
      />
      <img
        v-else
        class="w-full h-full object-cover z-2"
        style="filter: invert(1)"
        src="@/assets/images/person.png"
      />
    </a>
  <!-- </div> -->
    <div v-if="showAlerts == true" ref="notification"  class="bg-white rounded-md fixed top-[85px] ml-[8px] w-[14vw] mr-[10px] overflow-y-auto h-[50vh] z-[5] flex flex-col  items-center">
      <p class="text-[18px] font-[700] font-[latobold, sans-serif] bg-white rounded-xl px-[6px] mt-[4px]">Уведомления</p>
      <div class="flex mt-[4px]  flex-col rounded-mx bg-[#E2E8F0] w-[100%] py-[4px] hover:cursor-pointer last:mb-[4px]" v-for="alert in this.alertData" :key="alert.id" @click="sendMessage(alert)">
        <div class="flex flex-raw justify-between" v-if="alert.essence =='task'">
            <div class="self-left pl-2 py-2 flex flex-col" >
            <p class=" align-start self-left text-left text-[12px] font-[latobold, sans-serif] font-[700]">Перезвонить </p>
            <p class=" text-[12px] font-[latobold, sans-serif] text-left">{{"Задача №"}}<span class="text-green-600">{{alert.iid }}</span></p>
            </div>
            <div class="self-right flex flex-col self-center pr-2">
            <p class="pl-2 pr-2 text-[12px] font-[latobold, sans-serif] text-right">{{ 
                new Date (alert.dueTime).toLocaleString().substring(0,10)}}</p>
            <p class="pl-2 pr-2 text-[12px] font-[latobold, sans-serif] text-center">{{
                new Date (alert.dueTime).toLocaleTimeString('default').substring(0, 5)}}</p>
            <!-- <p class="text-black/40 absolute right-0 bottom-0">14:30</p> -->     
          </div>
        </div>
        <div class="flex flex-raw justify-between" v-if="alert.essence =='kak_dela'">
            <div class="self-left pl-2 py-2 flex flex-col" >
            <p class=" align-start self-left text-left text-[12px] font-[latobold, sans-serif] font-[700]">Задача: "Как дела?" </p>
            <p class=" text-[12px] font-[latobold, sans-serif] text-left">{{"Задача №"}}<span class="text-green-600">{{alert.iid }}</span></p>
            </div>
            <div class="self-right flex flex-col self-center pr-2">
            <p class="pl-2 pr-2 text-[12px] font-[latobold, sans-serif] text-right">{{ 
                new Date (alert.dueTime).toLocaleString().substring(0,10)}}</p>
            <p class="pl-2 pr-2 text-[12px] font-[latobold, sans-serif] text-center">{{
                new Date (alert.dueTime).toLocaleTimeString('default').substring(0, 5)}}</p>
            <!-- <p class="text-black/40 absolute right-0 bottom-0">14:30</p> -->     
          </div>
        </div>
        <div class="flex flex-raw justify-between"  v-if="alert.essence =='lead'">
          <div class="self-left pl-2 py-2 flex flex-col">
            <p class="text-left align-middle self-center text-[12px] font-[latobold, sans-serif] font-[700]" v-if="alert.essence=='lead'">Заявка с интернета </p>
            <p class=" text-[12px] font-[latobold, sans-serif] text-left">{{"Лид №"}}<span class="text-green-600">{{alert.iid }}</span></p>
          </div>
        </div>
        <div class="flex flex-raw justify-between"  v-if="alert.essence =='violate_lead'">
          <div class="self-left pl-2 py-2 flex flex-col">
            <p class="text-left align-middle self-center text-[12px] font-[latobold, sans-serif] font-[700]" v-if="alert.essence=='violate_lead'">Нарушение в ЛИДе </p>
            <p class=" text-[12px] font-[latobold, sans-serif] text-left">{{"Лид №"}}<span class="text-green-600">{{alert.iid }}</span></p>
          </div>
          <div class="self-right pr-2 py-2 flex flex-col">
            <p class="text-left align-middle self-center text-[12px] font-[latobold, sans-serif] font-[700]">Нарушитель:</p>
            <p class="text-left align-middle self-center text-[12px] font-[latobold, sans-serif] font-[400]">{{ alert.sname }}</p>
            <p class="text-left align-middle self-center text-[12px] font-[latobold, sans-serif] font-[400]">{{ alert.fname }}</p>
          </div>
        </div>
        <div class="flex flex-raw justify-between"  v-if="alert.essence =='violate_task'">
          <div class="self-left pl-2 py-2 flex flex-col">
            <p class="text-left align-middle self-center text-[12px] font-[latobold, sans-serif] font-[700]" v-if="alert.essence=='violate_task'">Нарушение в Задаче </p>
            <p class=" text-[12px] font-[latobold, sans-serif] text-left">{{"Задача №"}}<span class="text-green-600">{{alert.iid }}</span></p>
          </div>
          <div class="self-right pr-2 py-2 flex flex-col">
            <p class="text-left align-middle self-center text-[12px] font-[latobold, sans-serif] font-[700]">Нарушитель:</p>
            <p class="text-left align-middle self-center text-[12px] font-[latobold, sans-serif] font-[400]">{{ alert.sname }}</p>
            <p class="text-left align-middle self-center text-[12px] font-[latobold, sans-serif] font-[400]">{{ alert.fname }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center relative py-4 max-l:hidden z-2">
      <a
        class="uppercase text-green-500 text-sm cursor-pointer transition-colors duration-300 hover:text-green-400 z-2"
        @click="nextRole()"
      >
        <span>{{ roleNames[role] }}</span>
      </a>
    </div>

    <ul class="px-6 max-l:flex max-l:overflow-y-hidden h-max z-2">
      <li
        v-for="item in menu"
        :key="item"
        class="mr-30px list-none group transition-colors duration-1000"
      >
        <a
          class="flex pl-9 py-3 font-latobold max-w-[200px] cursor-pointer relative anim-navbar max-l:before:hidden z-2"
          @click="$router.push(item.path)"
        >
          <svg class="menuIco" v-if="item.path == '/calendar'">
            <use xlink:href="@/assets/i.svg#nav-calendar"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/order'">
            <use xlink:href="@/assets/i.svg#nav-flow"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/order_hosd'">
            <use xlink:href="@/assets/i.svg#nav-flow"></use>
          </svg>

          <svg class="menuIco" v-if="item.path == '/prolon'">
            <use xlink:href="@/assets/i.svg#nav-flow"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/order/arhive'">
            <use xlink:href="@/assets/i.svg#ico-search"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/claim'">
            <use xlink:href="@/assets/i.svg#status-19"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/price'">
            <use xlink:href="@/assets/i.svg#nav-service"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/filial'">
            <use xlink:href="@/assets/i.svg#nav-branches"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/worker'">
            <use xlink:href="@/assets/i.svg#nav-masters"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/salary'">
            <use xlink:href="@/assets/i.svg#pay-1"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/client'">
            <use xlink:href="@/assets/i.svg#nav-clients"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/car'">
            <use xlink:href="@/assets/i.svg#truck"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/lead'">
            <use xlink:href="@/assets/i.svg#truck"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/client/arhive'">
            <use xlink:href="@/assets/i.svg#ico-search"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/client/info'">
            <use xlink:href="@/assets/i.svg#nav-clients"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/client/company'">
            <use xlink:href="@/assets/i.svg#nav-clients"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/client/contact'">
            <use xlink:href="@/assets/i.svg#nav-clients"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/client/car'">
            <use xlink:href="@/assets/i.svg#nav-clients"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/task'">
            <use xlink:href="@/assets/i.svg#nav-orders"></use>
          </svg>

          <svg class="menuIco" v-if="item.path == '/marketing'">
            <use xlink:href="@/assets/i.svg#bill-6"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/check'">
            <use xlink:href="@/assets/i.svg#status-11"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/money'">
            <use xlink:href="@/assets/i.svg#pay-1"></use>
          </svg>
          <svg
            class="menuIco"
            v-if="item.path == '/invoice-moneys' || item.path == '/repolead'"
          >
            <use xlink:href="@/assets/i.svg#pay-1"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/statistic'">
            <use xlink:href="@/assets/i.svg#stat-2"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/profile'">
            <use xlink:href="@/assets/i.svg#ico-avatar"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/ask'">
            <use xlink:href="@/assets/i.svg#ico-question"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/report/activity'">
            <use xlink:href="@/assets/i.svg#stat-2"></use>
          </svg>
          <svg class="menuIco" v-if="item.path == '/activity/director'">
            <use xlink:href="@/assets/i.svg#stat-2"></use>
          </svg>

          <img
            src="@/assets/bid.svg"
            class="menuIco"
            v-if="item.path == '/bid'"
          />
          <img
            src="@/assets/invoice.svg"
            class="menuIco"
            v-if="item.path == '/invoice'"
          />
          <img
            class="menuIco"
            v-if="item.path == '/touch/touches'"
            src="@/assets/images/touches.svg"
            alt=""
          />
          <img
            class="menuIco"
            v-if="item.path == '/car/report' || item.path == '/report'"
            src="@/assets/images/reports.svg"
            alt=""
          />

          <img
            class="menuIco"
            v-if="item.path == '/plans'"
            src="@/assets/images/plans.svg"
            alt=""
          />

          <img
            class="menuIco"
            v-if="item.path == '/facts'"
            src="@/assets/images/facts.svg"
            alt=""
          />

          <img
            class="menuIco"
            v-if="item.path == '/manager-facts'"
            src="@/assets/images/facts.svg"
            alt=""
          />

          <img
            class="menuIco"
            v-if="item.path == '/briefcase'"
            src="@/assets/briefcase.svg"
            alt=""
          />
          <img
            class="menuIco"
            v-if="item.path == '/plan'"
            src="@/assets/images/reports.svg"
            alt=""
          />

          <img class="menuIco" src="@/assets/images/learning-base.svg" v-if="item.path == '/learning_base'" />

          <span
            class="text-white group-hover:text-green-500 z-1 duration-300"
            >{{ item.name }}</span
          >
        </a>
      </li>
      <li
        v-if="
          ['Studio', 'Hosd', 'Logist', 'Outsourcer'].find((r) => r === role)
        "
        class="mr-30px list-none group transition-colors duration-1000"
      >
        <a
          class="flex pl-9 py-3 font-latobold max-w-[200px] cursor-pointer relative anim-navbar max-l:before:hidden"
          :href="newTahobanFrontLink"
        >
          <span class="text-white group-hover:text-green-500 z-1 duration-300"
            >Агрегатор</span
          >
        </a>
      </li>
    </ul>
    <p
      class="block text-gray-400 text-xs relative text-center mt-24 max-l:hidden pb-8 z-2"
    >
      © TahoCRM 2025
    </p>
    <!--<button @click="testCron">CRON TEST</button>-->
  </div>
</template>

<script>


import { markRaw } from "vue";
import { mapActions, mapGetters } from "vuex";
//import API from "../services/api";
import TaskEdit from "../components/TaskEdit";

export default {
  name: "NavBar",
  components: { TaskEdit },

  data() {
    return {
      isDrop: false,
      icons: {
        "nav-calendar":
          '<svg><use xlink:href="@/assets/i.svg#nav-calendar"></use></svg>',
      },
      showAlerts: false,
      alertData: [],
      ws: null,
      inputMessage: "",
    };
  },

  computed: {
    ...mapGetters([
      "menu",
      "profile",
      "role",
      "roleNames",
      "backM",
      "newTahobanFrontLink",
      "s"
    ]),
  },

  methods: {
    ...mapActions(["logOut", "nextRole", "backToManager", "getProfile", "showTask", "showLeadModal", "closePopup","setTask", "findTask",]),

    DropMenu() {
      this.isDrop = !this.isDrop;
    },
    localLogOut() {
      this.isDrop = false;
      this.logOut();
    },


    connectWebSocket() {
      this.ws = new WebSocket("wss://tahoapi.megaorion.ru/wss");
      // this.ws = new WebSocket("ws://192.168.50.32:2346");

      this.ws.onopen = () => {
        console.log('WebSocket connection established');
        const message = JSON.stringify({ data: this.profile.uid, action: "start" });
        this.ws.send(message);
      };

      this.ws.onmessage = (event) => {
        console.log("event", event) 
        let alertD = JSON.parse(event.data)
          if(alertD.action == "confirmed" && alertD.alert != undefined){
          this.alertData = markRaw([...alertD.alert])
          console.log("Получены данные:",this.alertData)
          }
          else if (alertD.alert == undefined){
            this.alertData = []
          }
      };

      this.ws.onclose = () => {
        console.log('WebSocket connection closed');
      };
      this.ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    },
    sendMessage(alert) {
      let confirmStatus = confirm("При подтверждении вы соглашаетесь с тем, что вы прочли данное уведомление!")
      
      if(confirmStatus == true){
        if (this.ws && this.ws.readyState === WebSocket.OPEN) { 
          this.inputMessage = JSON.stringify(
          {data:{
            time: alert.time,
            userId: this.profile.uid,
            essence: alert.essence,
            id: alert.id
        }, action: "check_noti"});
        this.ws.send(this.inputMessage);
        this.showModalAlert(alert);
        } else {
          console.error("alert not colose");
        }
         
      }
    },
    close() {
      this.setTask(null);
      this.findTask(this.s);

      this.closePopup();
    },
    async showModalAlert(alert){
      console.log("сработал show task / lead")
      if(alert.essence == 'task' || alert.essence == 'violate_task' || alert.essence == 'kak_dela'){
        await this.showTask(alert)
        console.log("БЫЛА ПОКАЗАНА ЗАДАЧА!!!!!!!")
      }
      if(alert.essence == 'lead' || alert.essence == 'violate_lead'){
        await this.showLeadModal(alert)
        console.log("БЫЛ ПОКАЗАН ЛИД!!!!!!!")

      }
    },
  },
   

 async mounted() {
    await this.getProfile()
    this.connectWebSocket();

    document.addEventListener("click", (e)=>{
      const target = e.target;
      if (
        this.showAlerts === true &&
        !this.$refs.notification.contains(target) &&
        !this.$refs.bell.contains(target)
      ) {
        this.showAlerts = false;
      }
    })
  },
};
</script>

<style>
.bell >img {
  animation: ring 1.5s; 
  animation-iteration-count: infinite;
  
}
.bell:hover >img {
  cursor: pointer;
  animation: none;
  
}
@keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }
  9% {
    -webkit-transform: rotateZ(30deg);
  }
  19% {
    -webkit-transform: rotateZ(-30deg);
  }
  26% {
    -webkit-transform: rotateZ(18deg);
  }
  32% {
    -webkit-transform: rotateZ(-16deg);
  }
  40% {
    -webkit-transform: rotateZ(14deg);
  }
  47% {
    -webkit-transform: rotateZ(-12deg);
  }
  55% {
    -webkit-transform: rotateZ(10deg);
  }
  62% {
    -webkit-transform: rotateZ(-8deg);
  }
  69% {
    -webkit-transform: rotateZ(6deg);
  }
  75% {
    -webkit-transform: rotateZ(-4deg);
  }
  80% {
    -webkit-transform: rotateZ(2deg);
  }
  85% {
    -webkit-transform: rotateZ(-1deg);
  }
  90% {
    -webkit-transform: rotateZ(1deg);
  }
  100% {
    -webkit-transform: rotateZ(0);
  }
}
.popAp{
  z-index: 999999;
}
</style>
