<template>
  <div
    v-if="s.popup"
    class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
  >
    <a
      class="flex items-center bg-gray-500 px-4 py-2 absolute top-0 right-0 rounded-close cursor-pointer hover:bg-gray-400 transition-colors duration-150"
      @click="close"
    >
      <span class="text-white mr-2">Закрыть</span>
      <svg class="fill-white w-4 h-4">
        <use xlink:href="@/assets/i.svg#ico-no"></use>
      </svg>
    </a>
    <div class="flex h-full max-l:flex-col">
      <div class="w-1/2 overflow-y-auto max-l:w-full max-l:h-1/2">
        <div class="p-5 v-bbgray">
          <div
            class="flex items-center"
            :class="{
              'status-1': money.pay_status == 1,
              'status-6': money.pay_status == 6,
            }"
          >
            <IcoRomb
              :sizes="'w-16 h-16'"
              obj="money"
              :status="money.pay_status?.toString()"
            />
            <div class="flex flex-col justify-center overflow-hidden pr-8 pl-4">
              <span
                class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                >ЗАКАЗ</span
              >
              <span class="font-latobold text-xl">#{{ money.iid }}</span>
              <span class="text-[10px] text-gray-400 leading-none uppercase"
                >ОТ {{ money.create_at }}</span
              >
            </div>
          </div>
        </div>

        <div class="p-5 v-bbgray flex items-center">
          <div
            class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center mr-4"
          >
            <svg class="w-5 h-5 fill-white">
              <use xlink:href="@/assets/i.svg#nav-flow"></use>
            </svg>
          </div>
          <div class="flex flex-col justify-center overflow-hidden pr-8">
            <span class="text-[10px] text-gray-400 leading-none uppercase mb-1"
              >КОД ЗАКАЗА в 1C</span
            >
            <span class="font-latobold text-base">{{ money.code }}</span>
          </div>
        </div>

        <div class="p-5 v-bbgray flex items-center">
          <div
            class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center mr-4"
          >
            <svg class="w-5 h-5 fill-white">
              <use xlink:href="@/assets/i.svg#pay-2"></use>
            </svg>
          </div>
          <div class="flex flex-col justify-center overflow-hidden pr-8">
            <span class="text-[10px] text-gray-400 leading-none uppercase mb-1"
              >КОД СЧЕТА в 1C</span
            >
            <span class="font-latobold text-base">{{ money.codebill }}</span>
          </div>
        </div>

        <div class="p-5 v-bbgray flex items-center">
          <div
            class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center mr-4"
          >
            <svg class="w-5 h-5 fill-white">
              <use xlink:href="@/assets/i.svg#pay-1"></use>
            </svg>
          </div>
          <div class="flex flex-col justify-center overflow-hidden pr-8">
            <span class="text-[10px] text-gray-400 leading-none uppercase mb-1"
              >Тип оплаты</span
            >
            <span class="font-latobold text-base" v-if="money.pay == 1"
              >Взять наличные</span
            >
            <span class="font-latobold text-base" v-if="money.pay == 2"
              >Наличные предоплата</span
            >
            <span class="font-latobold text-base" v-if="money.pay == 4"
              >Безнал предоплата</span
            >
            <span class="font-latobold text-base" v-if="money.pay == 7"
              >Безнал отсрочка</span
            >
          </div>
        </div>

        <div class="p-5 v-bbgray flex items-center">
          <div
            class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center mr-4"
          >
            <span class="text-xl text-white">{{
              money.client?.slice(0, 1)
            }}</span>
          </div>
          <div class="flex flex-col justify-center overflow-hidden pr-8">
            <span class="text-[10px] text-gray-400 leading-none uppercase mb-1"
              >КЛИЕНТ</span
            >
            <span class="font-latobold text-base">{{ money.client }}</span>
          </div>
        </div>

        <div class="p-5 v-bbgray flex items-center">
          <div
            class="min-w-[1.5rem] w-11 h-11 rounded-full mr-3 overflow-hidden bg-green-500"
          >
            <IcoPic :pic="money.picture" :name="money.name" />
          </div>
          <div class="flex flex-col justify-center overflow-hidden pr-8">
            <span class="text-[10px] text-gray-400 leading-none uppercase mb-1"
              >МЕНЕДЖЕР</span
            >
            <span class="font-latobold text-base"
              >{{ money.name }} {{ money.secondname }}</span
            >
          </div>
        </div>

        <div class="p-5">
          <div
            v-if="money.pay_status == 1"
            class="flex items-center max-xl:flex-col max-xl:items-center"
          >
            <Datepicker
              v-model="money.pay_date"
              format="yyyy-MM-dd HH:mm:ss"
            ></Datepicker>
            <button
              v-if="money.pay_date"
              class="v-btn bg-green-500 hover:bg-green-400 ml-auto mr-0 max-xl:mt-4 max-xl:mx-0"
              @click="doneMoney(6)"
            >
              <span class="v-btn_text">РАЗНЕСТИ Д/С</span>
            </button>
            <div
              v-else
              class="v-btn bg-gray-500 hover:bg-gray-400 ml-auto mr-0 max-xl:mt-4 max-xl:mx-0"
            >
              <span class="v-btn_text">РАЗНЕСТИ Д/С</span>
            </div>
          </div>
          <div v-else class="flex items-center">
            <div class="flex items-center">
              <div
                class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center mr-4"
              >
                <svg class="w-5 h-5 fill-white">
                  <use xlink:href="@/assets/i.svg#nav-calendar"></use>
                </svg>
              </div>
              <span class="text-base font-latobold">{{
                new Date(money.pay_date).toLocaleString()
              }}</span>
            </div>
            <button
              class="v-btn bg-red-500 hover:bg-red-400 ml-auto mr-4"
              @click="doneMoney(1)"
            >
              <span class="v-btn_text">Отмена</span>
            </button>
          </div>
        </div>
      </div>

      <div
        class="px-5 pt-12 pb-6 w-1/2 overflow-y-auto max-l:border-gray-700 max-l:border-t-2 max-l:w-full max-l:h-1/2 max-l:pt-3"
      >
        <CommentList :id="money.id" :iid="money.iid" type="order" />
      </div>
    </div>
  </div>
  <div
    v-if="s.popup"
    @click="close"
    class="fixed bg-cyan-900 z-2 w-screen h-screen top-0 left-0 opacity-80"
  ></div>

  <div
    id="count"
    class="mb-9 mt-4 grid grid-cols-order__top place-content-center gap-x-14px gap-y-24px"
  >
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(1)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#status-1"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="MoneyCounter"
        >
          {{ MoneyCounter[1] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-1 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >НОВЫЕ</span
      >
    </div>
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(6)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-ok"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="MoneyCounter"
        >
          {{ MoneyCounter[6] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-6 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >ОПЛАЧЕНЫ</span
      >
    </div>
  </div>

  <div class="px-4 flex flex-wrap mb-8">
    <div class="w-full flex items-center max-xl:flex-wrap max-xl:gap-y-2">
      <h2
        class="mr-15px h-9 min-w-[2.25rem] border border-teal-700 rounded-lg p-2 text-center"
      >
        <span class="w-full text-lg leading-none text-teal-700">{{
          this.MoneyList?.length
        }}</span>
      </h2>

      <div class="h-9 w-1/4 mr-15px min-w-[180px]">
        <input
          type="text"
          class="w-full px-10px py-8px text-sm bg-slate-50 h-full"
          placeholder="Название"
          v-model="s.findme"
          @input="findMoney(s)"
        />
      </div>

      <button
        v-if="s.clear"
        class="mx-0 clear v-btn bg-gray-500 hover:bg-gray-400"
        @click="Clear"
        type="reset"
      >
        <span class="v-btn_text"> Сброс </span>
      </button>
    </div>
  </div>

  <div class="flex items-center">
    <select v-model="itemsInPage" style="padding: 7px">
      <option value="10">10</option>
      <option value="20">20</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </select>
    <Paginator :count="pageCount" v-model="page" />
  </div>

  <div
    class="w-full bg-white v-bgray2 mb-8 overflow-hidden rounded-xl relative overflow-x-auto"
  >
    <div class="relative h-10 min-w-full">
      <div
        class="grid gap-3 grid-cols-money items-center h-10 v-bbgray px-4 uppercase absolute min-w-full"
      >
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
        >
          №
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-3"
        >
          Клиент
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-2"
        >
          Менеджер
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
        >
          Создан
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
        >
          № Заказа в 1С
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
        >
          № Счета в 1С
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
        >
          Оплата
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] text-right"
        >
          Сумма
        </div>
      </div>
    </div>

    <div v-if="MoneyList?.length > 0">
      <template v-for="item in PaginatedMoneyList" :key="item">
        <div
          class="grid gap-3 grid-cols-money px-4 py-2 v-bbgray items-center transition duration-300 cursor-pointer hover:shadow-v1 bg-teal-light odd:bg-white max-2xl:grid-cols-all-mobile max-2xl:auto-rows-[50px]"
          :class="{
            'status-1': item.pay_status == 1,
            'status-6': item.pay_status == 6,
          }"
          @click="showMoney(item), (s.popup = true)"
        >
          <div class="flex">
            <svg class="w-5 h-5 mr-4 fill-status-1" v-if="item.pay_status == 1">
              <use xlink:href="@/assets/i.svg#status-1"></use>
            </svg>
            <svg class="w-5 h-5 mr-4 fill-status-6" v-if="item.pay_status == 6">
              <use xlink:href="@/assets/i.svg#status-6"></use></svg
            >#{{ item.iid }}
          </div>

          <div class="flex items-center col-span-3 max-m:col-span-3">
            <span
              class="text-white w-11 h-11 mr-4 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px]"
              :class="{
                'bg-status-1': item.pay_status == 1,
                'bg-status-6': item.pay_status == 6,
              }"
              >{{ item.client?.slice(0, 1) }}</span
            >
            <div
              class="text-ellipsis overflow-hidden max-h-[44px] whitespace-nowrap pr-8 font-latobold text-base"
            >
              {{ item.client }}
            </div>
          </div>

          <div class="flex items-center col-span-2 max-m:col-span-2">
            <div
              class="w-11 h-11 mr-4 rounded-full"
              :class="{
                'bg-status-1': item.pay_status == 1,
                'bg-status-6': item.pay_status == 6,
              }"
            >
              <IcoPic :pic="item.picture" :name="item.name" />
            </div>

            <div class="flex flex-col justify-center overflow-hidden">
              <span class="text-[10px] text-gray-400 leading-none uppercase"
                >Менеджер</span
              >
              <span class="text-base font-semibold">{{ item.name }}</span>
            </div>
          </div>

          <div class="">
            {{ new Date(item.create_at).toLocaleDateString() }}
          </div>

          <div class="">
            {{ item.code }}
          </div>

          <div class="">
            {{ item.codebill }}
          </div>

          <div class="">
            <div v-if="item.pay == 1">Взять наличные</div>
            <div v-if="item.pay == 2">Наличные предоплата</div>
            <div v-if="item.pay == 4">Безнал предоплата</div>
            <div v-if="item.pay == 7">Безнал отсрочка</div>
          </div>

          <div class="justify-end text-base font-latobold flex">
            {{ item.sum }} ₽
          </div>
        </div>
      </template>
    </div>
    <div v-else class="mx-auto my-4 flex items-center justify-center">
      <img class="w-11 h-11" src="https://api.tahocrm.ru/images/loader.svg" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import moment from "moment";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import CommentList from "../components/CommentList.vue";
import IcoPic from "../components/ui/IcoPic.vue";
import IcoRomb from "../components/ui/IcoRomb.vue";
import Paginator from "@/components/ui/Paginator";

export default {
  name: "PageMoney",

  components: { Datepicker, CommentList, IcoPic, IcoRomb, Paginator },
  data() {
    return {
      page: 0,
      itemsInPage: 10,
    };
  },

  computed: {
    ...mapGetters([
      "s",
      "profile",
      "role",
      "roles",
      "MoneyList",
      "money",
      "MoneyCounter",
    ]),
    pageCount() {
      return Math.ceil((this.MoneyList?.length ?? 0) / this.itemsInPage);
    },
    PaginatedMoneyList() {
      return this.MoneyList.slice(
        this.page * this.itemsInPage,
        (this.page + 1) * this.itemsInPage
      );
    },
  },

  methods: {
    ...mapActions([
      "getMoneys",
      "findMoney",
      "setMoney",
      "showMoney",
      "doneMoney",
      "clearSettings",
    ]),

    filterStatus(status) {
      this.s.status = status;
      this.findMoney(this.s);
    },

    Clear() {
      this.clearSettings();
      this.findMoney(this.s);
    },

    close() {
      this.s.popup = false;
    },
  },

  mounted() {
    this.getMoneys();
    window.DEBUG_MONEY = this;
  },
};
</script>
