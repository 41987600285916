<template>
    <div class="pb-4 w-full flex justify-center items-center">
      <h2 class="mr-15px h-9 min-w-[2.25rem] border border-teal-700 rounded-lg p-2 text-center my-4">
        <span class="w-full text-lg leading-none text-teal-700">{{`${article.name}`}}</span>
      </h2>
    </div>
      <div class="flex flex-row justify-between">
      <button class="v-btn bg-gray-400 hover:bg-gray-300 transition-colors duration-150 mb-4 mr-4" @click="goBack()">
        <img class="fill-white w-4 h-4 mr-3" src="@/assets/arrow-back.svg" />
        <span class="v-btn_text">Назад</span>
      </button>
        <div class="flex flex-row">
          <button class="v-btn bg-blue-500 hover:bg-blue-400 transition-colors duration-150 mb-4 mr-4" @click="editArticle()">
            <svg class="fill-white w-4 h-4 mr-3 rotate-45">
              <use xlink:href="@/assets/i.svg#ico-edit"></use>
            </svg>
            <span class="v-btn_text">Изменить</span>
          </button>
          <button
                class="v-btn bg-rose-500 hover:bg-rose-400 flex items-center w-max mb-4"
                style="color: #fff"
                @click="deleteArticle()"
              >
                <svg class="fill-white w-16px h-16px mr-3">
                  <use xlink:href="@/assets/i.svg#ico-delete"></use>
                </svg>
                Удалить
          </button>
      </div>
    </div>
  
    <div ref="quillContainer" class="font-lato text-[18px] bg-white rounded-[10px] max-h-[80%] overflow-y-auto">
    </div>

  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import router from "@/router/router";
  import Quill from 'quill'; // Импортируем Quill
  import 'quill/dist/quill.snow.css'; // Подключаем стили Quill
  import { toRaw } from "vue";
  export default {
    name: "LearningBaseShowArticle",
  
    data() {
      return {
        quill: null, // Для хранения экземпляра Quill
      };
    },
  
    computed: {
      ...mapGetters([
        "s",
        "profile",
        "role",
        "roles",
        "article"
      ]),
    },
  
    methods: {
      ...mapActions([
        "getEditArticle",
        "delArticle",
        "getArticle"
      ]),
  
      deleteArticle() {
        let confirmDelete = confirm("Удалить данную статью?");
        confirmDelete ? this.delArticle() : undefined;
      },
  
      editArticle() {
        let confirmEdit = confirm("Редактировать данную статью?");
        confirmEdit ? router.push("/learning_base/edit_article") : undefined;
      },
  
      initializeQuill() {
        // Инициализация Quill
        this.quill = new Quill(this.$refs.quillContainer, {
          theme: 'bubble', // Тема Quill (включает базовые стили)
          readOnly: true, // Запретить редактирование
        });
  
        // Устанавливаем контент
        if (this.article.text) {
          this.quill.root.innerHTML = toRaw(this.article.text)
        }
      },
      goBack(){
        router.push("/learning_base")
      },
    },
  
    async mounted() {
      await this.getArticle(); // Загружаем статью
      this.initializeQuill(); // Инициализируем Quill после загрузки данных
    },
  };
  </script>
  
  <style scoped>
  .quill-container {
    background: white;
    border-radius: 10px;
    padding: 20px;
  }
  
  .ql-editor {
    font-family: 'Lato', sans-serif; /* Используем шрифт Lato */
    font-size: 18px; /* Размер текста */
    line-height: 1.6; /* Межстрочный интервал */
    padding: 0; /* Убираем внутренние отступы Quill */
  }
  
  .ql-container.ql-snow {
    border: none; /* Убираем границу Quill */
  }
  </style>