<template>
  <div class="relative h-screen overflow-y-auto pb-10">
    <a
      class="flex items-center bg-gray-500 px-4 py-2 absolute top-0 right-0 rounded-close cursor-pointer hover:bg-gray-400 transition-colors duration-150"
      @click="$emit('close')"
    >
      <span class="text-white mr-2">Сохранить и закрыть</span>
      <svg class="fill-white w-4 h-4">
        <use xlink:href="@/assets/i.svg#ico-no"></use>
      </svg>
    </a>
    <template v-if="order?.category == 1">
      <div class="flex bg-green-50 max-m:block">
        <div class="w-3/4 h-full flex flex-col justify-between max-m:w-full">
          <h3 class="text-center mt-5 font-latobold text-lg max-m:mt-10">
            ЗАКАЗ-НАРЯД № {{ orderValue.oper.id }}
            <span class="text-sm block">
              от
              {{ moment(orderValue.oper.create_at).format("DD.MM.YYYY") }}</span
            >
            На основании заказа № {{ orderValue.iid }}
            <span class="text-sm block"
              >от {{ moment(orderValue.create_at).format("DD.MM.YYYY") }}</span
            >
          </h3>

          <div class="p-2 flex flex-wrap v-bbgray mt-4">
            <div class="w-1/4 flex flex-col my-2 px-4 max-m:w-1/2">
              <label>Модель ТС </label>
              <!-- <input
							v-if="role == 'Master'"
							type="text"
							v-model="order.oper.tsmark"
							@change="saveOper(order.oper)"
						/> -->
              <h3 class="font-latobold">{{ orderValue.oper.tsmark }}</h3>
            </div>
            <div class="w-1/4 flex flex-col my-2 px-4 max-m:w-1/2">
              <label>Рег. номер</label>
              <!-- <input
							v-if="role == 'Master'"
							type="text"
							v-model="order.oper.tsnum"
							@change="saveOper(order.oper)"
						/> -->
              <h3 class="font-latobold">{{ orderValue.oper.tsnum }}</h3>
            </div>
            <div class="w-1/4 flex flex-col my-2 px-4 max-m:w-full">
              <label>Тахограф</label>
              <input
                v-if="role == 'Master'"
                type="text"
                v-model="orderValue.oper.blockmark"
                @change="saveOper(orderValue.oper)"
                class="px-4 py-2 v-bgray"
              />
              <h3 class="my-4" v-else>{{ orderValue.oper.blockmark }}</h3>
            </div>
            <div class="w-1/4 flex flex-col my-2 px-4 max-m:w-full">
              <label>С/Н тахографа</label>
              <input
                v-if="role == 'Master'"
                type="text"
                v-model="orderValue.oper.sn"
                @change="saveOper(order.oper)"
                class="px-4 py-2 v-bgray"
              />
              <h3 class="my-4" v-else>{{ orderValue.oper.sn }}</h3>
            </div>
          </div>

          <div class="w-full flex flex-wrap my-2 v-bbgray max-m:block">
            <div class="w-1/3 flex flex-col px-4 my-2 max-m:w-full">
              <label>Датчик скорости</label>
              <input
                v-if="role == 'Master'"
                type="text"
                v-model="orderValue.oper.speedsensor"
                @change="saveOper(orderValue.oper)"
                class="px-4 py-2 v-bgray"
              />
              <h3 class="my-4" v-else>{{ orderValue.oper.speedsensor }}</h3>
            </div>
            <div class="w-1/3 flex flex-col px-4 my-2 max-m:w-full">
              <label>Установка спидометра</label>
              <input
                v-if="role == 'Master'"
                type="text"
                v-model="orderValue.oper.speedometer"
                @change="saveOper(orderValue.oper)"
                class="px-4 py-2 v-bgray"
              />
              <h3 class="my-4" v-else>{{ orderValue.oper.speedometer }}</h3>
            </div>
            <div class="w-1/3 flex flex-col px-4 my-2 max-m:w-full">
              <label>IMEI</label>
              <input
                v-if="role == 'Master'"
                type="text"
                v-model="orderValue.oper.cable"
                @change="saveOper(orderValue.oper)"
                class="px-4 py-2 v-bgray"
              />
              <h3 class="my-4" v-else>{{ orderValue.oper.cable }}</h3>
            </div>
          </div>

          <div class="w-full flex flex-wrap my-2 v-bbgray">
            <div class="w-1/4 flex flex-col px-4 my-2 max-m:w-full">
              <label>W</label>
              <input
                v-if="role == 'Master'"
                type="text"
                v-model="orderValue.oper.w"
                @change="saveOper(orderValue.oper)"
                class="px-4 py-2 v-bgray"
              />
              <h3 class="my-4" v-else>{{ orderValue.oper.w }}</h3>
            </div>
            <div class="w-1/4 flex flex-col px-4 my-2 max-m:w-full">
              <label>K</label>
              <input
                v-if="role == 'Master'"
                type="text"
                v-model="orderValue.oper.k"
                @change="saveOper(orderValue.oper)"
                class="px-4 py-2 v-bgray"
              />
              <h3 class="my-4" v-else>{{ orderValue.oper.k }}</h3>
            </div>
            <div class="w-1/4 flex flex-col px-4 my-2 max-m:w-full">
              <label>L</label>
              <input
                v-if="role == 'Master'"
                type="text"
                v-model="orderValue.oper.l"
                @change="saveOper(orderValue.oper)"
                class="px-4 py-2 v-bgray"
              />
              <h3 class="my-4" v-else>{{ orderValue.oper.l }}</h3>
            </div>
            <div class="w-1/4 flex flex-col px-4 my-2 max-m:w-full">
              <label>Размер шин</label>
              <input
                v-if="role == 'Master'"
                type="text"
                v-model="orderValue.oper.s"
                @change="saveOper(orderValue.oper)"
                class="px-4 py-2 v-bgray"
              />
              <h3 class="my-4" v-else>{{ orderValue.oper.s }}</h3>
            </div>
          </div>

          <div class="flex flex-col w-full p-5 v-bbgray">
            <h3 class="mb-5 font-latobold">Опломбированы элементы</h3>
            <div class="w-full flex flex-wrap my-2">
              <div class="w-1/3 flex flex-col px-4 my-2 max-m:w-full">
                <input
                  class="mychbox"
                  type="checkbox"
                  id="sltaho"
                  :disabled="role != 'Master'"
                  v-model="orderValue.oper.sltaho"
                  @change="saveOper(orderValue.oper)"
                />
                <label for="sltaho">Тахограф</label>
              </div>
              <div class="w-1/3 flex flex-col px-4 my-2 max-m:w-full">
                <input
                  class="mychbox"
                  type="checkbox"
                  id="slspeed"
                  :disabled="role != 'Master'"
                  v-model="orderValue.oper.slspeed"
                  @change="saveOper(orderValue.oper)"
                />
                <label for="slspeed">Спидометр</label>
              </div>
              <div class="w-1/3 flex flex-col px-4 my-2 max-m:w-full">
                <input
                  class="mychbox"
                  type="checkbox"
                  id="slsensor"
                  :disabled="role != 'Master'"
                  v-model="orderValue.oper.slsensor"
                  @change="saveOper(orderValue.oper)"
                />
                <label for="slsensor">Датчик скорости</label>
              </div>
            </div>
          </div>

          <div class="flex flex-col w-full p-5 v-bbgray">
            <h3 class="mb-5 font-latobold">Документы</h3>
            <div class="w-full flex flex-wrap my-2">
              <div class="w-1/4 flex flex-col px-4 my-2 max-m:w-full">
                <input
                  class="mychbox"
                  type="checkbox"
                  id="docs"
                  :disabled="role != 'Master'"
                  v-model="orderValue.oper.docs"
                  @change="saveOper(orderValue.oper)"
                />
                <label for="docs">Сертификат калибровки</label>
              </div>
              <div class="w-1/4 flex flex-col px-4 my-2 max-m:w-full">
                <input
                  class="mychbox"
                  type="checkbox"
                  id="doce"
                  :disabled="role != 'Master'"
                  v-model="orderValue.oper.doce"
                  @change="saveOper(orderValue.oper)"
                />
                <label for="doce">Отметка о вводе в эксплуатацию</label>
              </div>
              <div class="w-1/4 flex flex-col px-4 my-2 max-m:w-full">
                <input
                  class="mychbox"
                  type="checkbox"
                  id="docp"
                  :disabled="role != 'Master'"
                  v-model="orderValue.oper.docp"
                  @change="saveOper(orderValue.oper)"
                />
                <label for="docp">Паспорт тахографа</label>
              </div>
              <div class="w-1/4 flex flex-col px-4 my-2 max-m:w-full">
                <input
                  class="mychbox"
                  type="checkbox"
                  id="docc"
                  :disabled="role != 'Master'"
                  v-model="orderValue.oper.docc"
                  @change="saveOper(orderValue.oper)"
                />
                <label for="docc">Документы переданы заказчику</label>
              </div>
            </div>
          </div>
        </div>
        <div class="w-1/4 h-full flex flex-col mt-10 max-m:w-full">
          <div class="p-2 text-black font-latobold text-lg v-bbgray">
            <h2>Данные ТС</h2>
            <div class="flex flex-wrap text-center">
              <template v-for="i in orderValue.oper.images" :key="i">
                <div
                  v-if="[2, 3, 4].includes(parseInt(i.status))"
                  class="relative m-2 w-16"
                >
                  <a
                    class="absolute -right-2 -top-2 group"
                    v-if="profile.uid == i.uid"
                    @click="delImg({ img: i, item: orderValue.oper })"
                  >
                    <svg
                      class="bg-white rounded-full w-5 h-5 group-hover:fill-rose-500 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a>
                  <a
                    class="flex flex-col w-full text-center"
                    :href="imagePrefix + 'oper/orig/' + i.img"
                    target="_blank"
                  >
                    <span class="w-16 h-16 rounded-md overflow-hidden mb-1">
                      <img
                        v-if="
                          i.img.includes('png') ||
                          i.img.includes('jpg') ||
                          i.img.includes('jpeg') ||
                          i.img.includes('bmp')
                        "
                        class="w-full h-full object-cover"
                        :src="imagePrefix + 'oper/mini/' + i.img"
                        alt=""
                      />
                      <img
                        v-else-if="i.img.includes('pdf')"
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/pdf-file.png"
                        alt=""
                      />
                      <img
                        v-else
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/txt-file.png"
                        alt=""
                      />
                    </span>
                    <p>{{ imgName[i.status] }}</p>
                  </a>
                </div>
              </template>
            </div>
          </div>

          <div v-if="['Logist', 'Master'].includes(role)" class="mb-2">
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="2"
                :name="imgName[2]"
              />
            </div>
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="3"
                :name="imgName[3]"
              />
            </div>
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="4"
                :name="imgName[4]"
              />
            </div>
          </div>

          <div class="p-2 text-black font-latobold text-lg v-bbgray">
            <h2>Терминал</h2>
            <div class="flex flex-wrap text-center">
              <template v-for="i in orderValue.oper.images" :key="i">
                <div
                  v-if="[5, 6, 7, 8, 9].includes(parseInt(i.status))"
                  class="relative m-2 w-16"
                >
                  <a
                    class="absolute -right-2 -top-2 group"
                    v-if="profile.uid == i.uid"
                    @click="delImg({ img: i, item: orderValue.oper })"
                  >
                    <svg
                      class="bg-white rounded-full w-5 h-5 group-hover:fill-rose-500 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a>
                  <a
                    class="flex flex-col w-full text-center"
                    :href="imagePrefix + 'oper/orig/' + i.img"
                    target="_blank"
                  >
                    <span class="w-16 h-16 rounded-md overflow-hidden mb-1">
                      <img
                        v-if="
                          i.img.includes('png') ||
                          i.img.includes('jpg') ||
                          i.img.includes('jpeg') ||
                          i.img.includes('bmp')
                        "
                        class="w-full h-full object-cover"
                        :src="imagePrefix + 'oper/mini/' + i.img"
                        alt=""
                      />
                      <img
                        v-else-if="i.img.includes('pdf')"
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/pdf-file.png"
                        alt=""
                      />
                      <img
                        v-else
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/txt-file.png"
                        alt=""
                      />
                    </span>
                    <p>{{ imgName[i.status] }}</p>
                  </a>
                </div>
              </template>
            </div>
          </div>

          <div v-if="['Logist', 'Master'].includes(role)" class="mb-2">
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="5"
                :name="imgName[5]"
              />
            </div>
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="6"
                :name="imgName[6]"
              />
            </div>
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="7"
                :name="imgName[7]"
              />
            </div>
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="8"
                :name="imgName[8]"
              />
            </div>
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="9"
                :name="imgName[9]"
              />
            </div>
          </div>

          <div class="p-2 text-black font-latobold text-lg v-bbgray">
            <h2>Датчик уровня топлива</h2>
            <div class="flex flex-wrap text-center">
              <template v-for="i in orderValue.oper.images" :key="i">
                <div
                  v-if="[10, 11, 12, 13].includes(parseInt(i.status))"
                  class="relative m-2 w-16"
                >
                  <a
                    class="absolute -right-2 -top-2 group"
                    v-if="profile.uid == i.uid"
                    @click="delImg({ img: i, item: orderValue.oper })"
                  >
                    <svg
                      class="bg-white rounded-full w-5 h-5 group-hover:fill-rose-500 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a>
                  <a
                    class="flex flex-col w-full text-center"
                    :href="imagePrefix + '/oper/' + i.img"
                    target="_blank"
                  >
                    <span class="w-16 h-16 rounded-md overflow-hidden mb-1">
                      <img
                        v-if="
                          i.img.includes('png') ||
                          i.img.includes('jpg') ||
                          i.img.includes('jpeg') ||
                          i.img.includes('bmp')
                        "
                        class="w-full h-full object-cover"
                        :src="imagePrefix + 'oper/mini/' + i.img"
                        alt=""
                      />
                      <img
                        v-else-if="i.img.includes('pdf')"
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/pdf-file.png"
                        alt=""
                      />
                      <img
                        v-else
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/txt-file.png"
                        alt=""
                      />
                    </span>
                    <p>{{ imgName[i.status] }}</p>
                  </a>
                </div>
              </template>
            </div>
          </div>

          <div v-if="['Logist', 'Master'].includes(role)" class="mb-2">
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="10"
                :name="imgName[10]"
              />
            </div>
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="11"
                :name="imgName[11]"
              />
            </div>
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="12"
                :name="imgName[12]"
              />
            </div>
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="13"
                :name="imgName[13]"
              />
            </div>
          </div>

          <div class="p-2 text-black font-latobold text-lg v-bbgray">
            <h2>Доп.оборудование</h2>
            <div class="flex flex-wrap text-center">
              <template v-for="i in orderValue.oper.images" :key="i">
                <div
                  v-if="[14, 15].includes(parseInt(i.status))"
                  class="relative m-2 w-16"
                >
                  <a
                    class="absolute -right-2 -top-2 group"
                    v-if="profile.uid == i.uid"
                    @click="delImg({ img: i, item: order.oper })"
                  >
                    <svg
                      class="bg-white rounded-full w-5 h-5 group-hover:fill-rose-500 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a>
                  <a
                    class="flex flex-col w-full text-center"
                    :href="imagePrefix + 'oper/orig/' + i.img"
                    target="_blank"
                  >
                    <span class="w-16 h-16 rounded-md overflow-hidden mb-1">
                      <img
                        v-if="
                          i.img.includes('png') ||
                          i.img.includes('jpg') ||
                          i.img.includes('jpeg') ||
                          i.img.includes('bmp')
                        "
                        class="w-full h-full object-cover"
                        :src="imagePrefix + 'oper/mini/' + i.img"
                        alt=""
                      />
                      <img
                        v-else-if="i.img.includes('pdf')"
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/pdf-file.png"
                        alt=""
                      />
                      <img
                        v-else
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/txt-file.png"
                        alt=""
                      />
                    </span>
                    <p>{{ imgName[i.status] }}</p>
                  </a>
                </div>
              </template>
            </div>
          </div>

          <div v-if="['Logist', 'Master'].includes(role)" class="mb-2">
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="14"
                :name="imgName[14]"
              />
            </div>
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="15"
                :name="imgName[15]"
              />
            </div>
          </div>

          <div class="p-2 text-black font-latobold text-lg v-bbgray">
            <h2>Другое</h2>
            <div class="flex flex-wrap text-center">
              <template v-for="i in orderValue.oper.images" :key="i">
                <div
                  v-if="[1].includes(parseInt(i.status))"
                  class="relative m-2 w-16"
                >
                  <a
                    class="absolute -right-2 -top-2 group"
                    v-if="profile.uid == i.uid"
                    @click="delImg({ img: i, item: orderValue.oper })"
                  >
                    <svg
                      class="bg-white rounded-full w-5 h-5 group-hover:fill-rose-500 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a>
                  <a
                    class="flex flex-col w-full text-center"
                    :href="imagePrefix + 'oper/orig/' + i.img"
                    target="_blank"
                  >
                    <span class="w-16 h-16 rounded-md overflow-hidden mb-1">
                      <img
                        v-if="
                          i.img.includes('png') ||
                          i.img.includes('jpg') ||
                          i.img.includes('jpeg') ||
                          i.img.includes('bmp')
                        "
                        class="w-full h-full object-cover"
                        :src="imagePrefix + 'oper/mini/' + i.img"
                        alt=""
                      />
                      <img
                        v-else-if="i.img.includes('pdf')"
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/pdf-file.png"
                        alt=""
                      />
                      <img
                        v-else
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/txt-file.png"
                        alt=""
                      />
                    </span>
                    <p>{{ imgName[i.status] }}</p>
                  </a>
                </div>
              </template>
            </div>
          </div>

          <div v-if="['Logist', 'Master'].includes(role)" class="mb-2">
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="1"
                :name="imgName[1]"
              />
            </div>
          </div>

          <div class="p-2 text-black font-latobold text-lg v-bbgray">
            <h2>Заявление</h2>
            <div class="flex flex-wrap text-center">
              <template v-for="i in orderValue.oper.images" :key="i">
                <div
                  v-if="[55].includes(parseInt(i.status))"
                  class="relative m-2 w-16"
                >
                  <a
                    class="absolute -right-2 -top-2 group"
                    v-if="profile.uid == i.uid"
                    @click="delImg({ img: i, item: orderValue.oper })"
                  >
                    <svg
                      class="bg-white rounded-full w-5 h-5 group-hover:fill-rose-500 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a>
                  <a
                    class="flex flex-col w-full text-center"
                    :href="imagePrefix + 'oper/orig/' + i.img"
                    target="_blank"
                  >
                    <span class="w-16 h-16 rounded-md overflow-hidden mb-1">
                      <img
                        v-if="
                          i.img.includes('png') ||
                          i.img.includes('jpg') ||
                          i.img.includes('jpeg') ||
                          i.img.includes('bmp')
                        "
                        class="w-full h-full object-cover"
                        :src="imagePrefix + 'oper/mini/' + i.img"
                        alt=""
                      />
                      <img
                        v-else-if="i.img.includes('pdf')"
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/pdf-file.png"
                        alt=""
                      />
                      <img
                        v-else
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/txt-file.png"
                        alt=""
                      />
                    </span>
                    <p>{{ imgName[i.status] }}</p>
                  </a>
                </div>
              </template>
            </div>
          </div>

          <div v-if="['Logist', 'Master'].includes(role)" class="mb-2">
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="55"
                :name="imgName[55]"
              />
            </div>
          </div>

          <div class="p-2 text-black font-latobold text-lg v-bbgray">
            <h2>Расписка</h2>
            <div class="flex flex-wrap text-center">
              <template v-for="i in orderValue.oper.images" :key="i">
                <div
                  v-if="[60].includes(parseInt(i.status))"
                  class="relative m-2 w-16"
                >
                  <a
                    class="absolute -right-2 -top-2 group"
                    v-if="profile.uid == i.uid"
                    @click="delImg({ img: i, item: orderValue.oper })"
                  >
                    <svg
                      class="bg-white rounded-full w-5 h-5 group-hover:fill-rose-500 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a>
                  <a
                    class="flex flex-col w-full text-center"
                    :href="imagePrefix + 'oper/orig/' + i.img"
                    target="_blank"
                  >
                    <span class="w-16 h-16 rounded-md overflow-hidden mb-1">
                      <img
                        v-if="
                          i.img.includes('png') ||
                          i.img.includes('jpg') ||
                          i.img.includes('jpeg') ||
                          i.img.includes('bmp')
                        "
                        class="w-full h-full object-cover"
                        :src="imagePrefix + 'oper/mini/' + i.img"
                        alt=""
                      />
                      <img
                        v-else-if="i.img.includes('pdf')"
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/pdf-file.png"
                        alt=""
                      />
                      <img
                        v-else
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/txt-file.png"
                        alt=""
                      />
                    </span>
                    <p>{{ imgName[i.status] }}</p>
                  </a>
                </div>
              </template>
            </div>
          </div>

          <div v-if="['Logist', 'Master'].includes(role)" class="mb-2">
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="60"
                :name="imgName[60]"
              />
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="orderValue.category == 2">
      <div class="bg-yellow-50 flex max-m:block">
        <div class="w-3/4 h-full flex flex-col justify-between max-m:w-full">
          <h3 class="text-center mt-5 font-latobold text-lg max-m:mt-10">
            ЗАКАЗ-НАРЯД № {{ orderValue.oper.id }}
            <span class="text-sm block">
              от
              {{ moment(orderValue.oper.create_at).format("DD.MM.YYYY") }}</span
            >
            На основании заказа № {{ orderValue.iid }}
            <span class="text-sm block"
              >от {{ moment(orderValue.create_at).format("DD.MM.YYYY") }}</span
            >
          </h3>

          <div class="p-2 flex v-bbgray mt-4">
            <div class="w-1/2 flex flex-col my-2 px-4">
              <label>Модель ТС </label>
              <!-- <input
							v-if="role == 'Master'"
							type="text"
							v-model="order.oper.tsmark"
							@change="saveOper(order.oper)"
						/> -->
              <h3 class="font-latobold">{{ orderValue.oper.tsmark }}</h3>
            </div>
            <div class="w-1/2 flex flex-col my-2 px-4">
              <label>Рег. номер</label>
              <!-- <input
							v-if="role == 'Master'"
							type="text"
							v-model="order.oper.tsnum"
							@change="saveOper(order.oper)"
						/> -->
              <h3 class="font-latobold">{{ orderValue.oper.tsnum }}</h3>
            </div>
          </div>

          <div class="p-2 v-bbgray flex max-m:block">
            <div class="w-1/2 my-2 max-m:w-full">
              <div class="flex flex-col px-4 my-2">
                <label>Марка блока мониторинга</label>
                <input
                  class="px-4 py-2 v-bgray"
                  v-if="role == 'Master'"
                  type="text"
                  v-model="orderValue.oper.blockmark"
                  @change="saveOper(orderValue.oper)"
                />
                <h3 v-else>{{ orderValue.oper.blockmark }}</h3>
              </div>

              <div class="flex flex-col my-2 px-4">
                <label>IMEI</label>
                <input
                  class="px-4 py-2 v-bgray"
                  v-if="role == 'Master'"
                  type="text"
                  v-model="orderValue.oper.imei"
                  @change="saveOper(order.oper)"
                />
                <h3 v-else>{{ orderValue.oper.imei }}</h3>
              </div>
              <div class="flex flex-col my-2 px-4">
                <label>S/N</label>
                <input
                  class="px-4 py-2 v-bgray"
                  v-if="role == 'Master'"
                  type="text"
                  v-model="orderValue.oper.sn"
                  @change="saveOper(orderValue.oper)"
                />
                <h3 v-else>{{ orderValue.oper.sn }}</h3>
              </div>
              <div class="flex flex-col my-2 px-4">
                <label>Номер сим-карты</label>
                <input
                  class="px-4 py-2 v-bgray"
                  v-if="role == 'Master'"
                  type="text"
                  v-model="orderValue.oper.sim"
                  @change="saveOper(orderValue.oper)"
                />
                <h3 v-else>{{ orderValue.oper.sim }}</h3>
              </div>
            </div>
            <div class="w-1/2 flex flex-col my-2 max-m:w-full">
              <div class="flex flex-col px-4 my-2">
                <label>S/N пломбы 1</label>
                <input
                  class="px-4 py-2 v-bgray"
                  v-if="role == 'Master'"
                  type="text"
                  v-model="orderValue.oper.snplomb1"
                  @change="saveOper(orderValue.oper)"
                />
                <h3 v-else>{{ orderValue.oper.snplomb1 }}</h3>
              </div>
              <div class="flex flex-col px-4 my-2">
                <label>S/N пломбы 2</label>
                <input
                  class="px-4 py-2 v-bgray"
                  v-if="role == 'Master'"
                  type="text"
                  v-model="orderValue.oper.snplomb2"
                  @change="saveOper(orderValue.oper)"
                />
                <h3 v-else>{{ orderValue.oper.snplomb2 }}</h3>
              </div>
              <div class="flex flex-col px-4 my-2">
                <label>S/N пломбы 3</label>
                <input
                  class="px-4 py-2 v-bgray"
                  v-if="role == 'Master'"
                  type="text"
                  v-model="orderValue.oper.snplomb3"
                  @change="saveOper(orderValue.oper)"
                />
                <h3 v-else>{{ orderValue.oper.snplomb3 }}</h3>
              </div>
              <div class="flex flex-col mt-9 px-4 max-m:mt-4">
                <input
                  class="mychbox"
                  type="checkbox"
                  id="corpus"
                  :disabled="role != 'Master'"
                  v-model="orderValue.oper.corpus"
                  @change="saveOper(orderValue.oper)"
                />
                <label for="corpus">Защитный корпус</label>
              </div>
            </div>
          </div>

          <div class="p-2 v-bbgray">
            <h3 class="text-center mt-4">Контроль расхода топлива</h3>

            <div class="flex flex-wrap">
              <div class="w-1/2 flex flex-col my-2 max-m:w-full">
                <div class="flex flex-col px-4 my-2">
                  <label>Датчик уровня топлива</label>
                  <input
                    class="px-4 py-2 v-bgray"
                    v-if="role == 'Master'"
                    type="text"
                    v-model="orderValue.oper.fuelsensor"
                    @change="saveOper(orderValue.oper)"
                  />
                  <h3 v-else>{{ orderValue.oper.fuelsensor }}</h3>
                </div>
                <div class="flex flex-col px-4 my-2">
                  <label>S/N</label>
                  <input
                    class="px-4 py-2 v-bgray"
                    v-if="role == 'Master'"
                    type="text"
                    v-model="orderValue.oper.snfuel"
                    @change="saveOper(orderValue.oper)"
                  />
                  <h3 v-else>{{ orderValue.oper.snfuel }}</h3>
                </div>
              </div>

              <div class="w-1/2 flex flex-col my-2">
                <div class="flex">
                  <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4">
                    <label>L, начальная</label>
                    <input
                      class="px-4 py-2 v-bgray"
                      v-if="role == 'Master'"
                      type="text"
                      v-model="orderValue.oper.l"
                      @change="saveOper(order.oper)"
                    />
                    <h3 v-else>{{ orderValue.oper.l }}</h3>
                  </div>
                  <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4">
                    <label>L, обрезная</label>
                    <input
                      class="px-4 py-2 v-bgray"
                      v-if="role == 'Master'"
                      type="text"
                      v-model="orderValue.oper.s"
                      @change="saveOper(order.oper)"
                    />
                    <h3 v-else>{{ orderValue.oper.s }}</h3>
                  </div>
                </div>

                <div class="flex">
                  <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4">
                    <label>S/N пломбы датчика</label>
                    <input
                      class="px-4 py-2 v-bgray"
                      v-if="role == 'Master'"
                      type="text"
                      v-model="orderValue.oper.slfuel"
                      @change="saveOper(orderValue.oper)"
                    />
                    <h3 v-else>{{ orderValue.oper.slfuel }}</h3>
                  </div>
                  <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4">
                    <label>S/N пломбы разъема</label>
                    <input
                      class="px-4 py-2 v-bgray"
                      v-if="role == 'Master'"
                      type="text"
                      v-model="orderValue.oper.sljack"
                      @change="saveOper(orderValue.oper)"
                    />
                    <h3 v-else>{{ orderValue.oper.sljack }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-2 v-bbgray">
            <h3 class="text-center mt-4">Тарировка топливного бака</h3>
            <div class="flex flex-wrap">
              <div class="w-1/3 flex flex-col my-2 px-4">
                <label>Бак 1/ёмкость, л</label>
                <input
                  class="px-4 py-2 v-bgray"
                  v-if="role == 'Master'"
                  type="text"
                  v-model="orderValue.oper.tank1"
                  @change="saveOper(orderValue.oper)"
                />
                <template v-if="orderValue.oper.tank1">
                  <div v-for="i in 20" :key="i" class="px-2 v-bbgray flex">
                    <div class="w-1/3">{{ i }}:</div>
                    <div class="w-2/3 text-right font-latobold">
                      {{ i * (orderValue.oper.tank1 / 20) }} л
                    </div>
                  </div>
                </template>
              </div>
              <div class="w-1/3 flex flex-col my-2 px-4">
                <label>Бак 2/ёмкость, л</label>
                <input
                  class="px-4 py-2 v-bgray"
                  v-if="role == 'Master'"
                  type="text"
                  v-model="orderValue.oper.tank2"
                  @change="saveOper(orderValue.oper)"
                />
                <template v-if="orderValue.oper.tank2">
                  <div v-for="i in 20" :key="i" class="px-2 v-bbgray flex">
                    <div class="w-1/3">{{ i }}:</div>
                    <div class="w-2/3 text-right font-latobold">
                      {{ i * (orderValue.oper.tank2 / 20) }} л
                    </div>
                  </div>
                </template>
              </div>
              <div class="w-1/3 flex flex-col my-2 px-4">
                <label>Бак 3/ёмкость, л</label>
                <input
                  class="px-4 py-2 v-bgray"
                  v-if="role == 'Master'"
                  type="text"
                  v-model="orderValue.oper.tank3"
                  @change="saveOper(orderValue.oper)"
                />
                <template v-if="orderValue.oper.tank3">
                  <div v-for="i in 20" :key="i" class="px-2 v-bbgray flex">
                    <div class="w-1/3">{{ i }}:</div>
                    <div class="w-2/3 text-right font-latobold">
                      {{ i * (orderValue.oper.tank3 / 20) }} л
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <div class="p-2 v-bbgray">
            <h3 class="text-center mt-4">Дополнительные услуги</h3>
            <div class="flex flex-wrap">
              <div class="w-1/3 flex flex-col my-2 px-4 max-m:w-full">
                <div class="flex my-2">
                  <input
                    class="mychbox"
                    type="checkbox"
                    id="can"
                    :disabled="role != 'Master'"
                    v-model="orderValue.oper.can"
                    @change="saveOper(orderValue.oper)"
                  />
                  <label for="can">CAN Прямое подключение</label>
                </div>
                <div class="flex my-2">
                  <input
                    class="mychbox"
                    type="checkbox"
                    id="canlog"
                    :disabled="role != 'Master'"
                    v-model="orderValue.oper.canlog"
                    @change="saveOper(orderValue.oper)"
                  />
                  <label for="canlog">CANLOG</label>
                </div>
                <div class="flex my-2">
                  <input
                    class="mychbox"
                    type="checkbox"
                    id="nican"
                    :disabled="role != 'Master'"
                    v-model="orderValue.oper.nican"
                    @change="saveOper(orderValue.oper)"
                  />
                  <label for="nican">niCAN</label>
                </div>
              </div>
              <div class="w-1/3 flex flex-col my-2 px-4 max-m:w-full">
                <div class="flex my-2">
                  <input
                    class="mychbox"
                    type="checkbox"
                    id="canc"
                    :disabled="role != 'Master'"
                    v-model="orderValue.oper.canc"
                    @change="saveOper(orderValue.oper)"
                  />
                  <label for="canc">CANCrocodile</label>
                </div>
                <div class="flex my-2">
                  <input
                    class="mychbox"
                    type="checkbox"
                    id="up"
                    :disabled="role != 'Master'"
                    v-model="orderValue.oper.up"
                    @change="saveOper(orderValue.oper)"
                  />
                  <label for="up">Контроль подъёма кузова</label>
                </div>
                <div class="flex my-2">
                  <input
                    class="mychbox"
                    type="checkbox"
                    id="door"
                    :disabled="role != 'Master'"
                    v-model="orderValue.oper.door"
                    @change="saveOper(orderValue.oper)"
                  />
                  <label for="door">Датчик открывания дверей</label>
                </div>
              </div>
              <div class="w-1/3 flex flex-col my-2 px-4 max-m:w-full">
                <div class="flex my-2">
                  <input
                    class="mychbox"
                    type="checkbox"
                    id="rotate"
                    :disabled="role != 'Master'"
                    v-model="orderValue.oper.rotate"
                    @change="saveOper(orderValue.oper)"
                  />
                  <label for="rotate">Датчик вращения</label>
                </div>
                <div class="flex my-2">
                  <input
                    class="mychbox"
                    type="checkbox"
                    id="engine"
                    :disabled="role != 'Master'"
                    v-model="orderValue.oper.engine"
                    @change="saveOper(orderValue.oper)"
                  />
                  <label for="engine">Контроль оборотов двигателя </label>
                </div>
                <div class="flex my-2">
                  <input
                    class="mychbox"
                    type="checkbox"
                    id="dop"
                    :disabled="role != 'Master'"
                    v-model="orderValue.oper.dop"
                    @change="saveOper(orderValue.oper)"
                  />
                  <label for="dop">Контроль моточасов допоборудования</label>
                </div>
              </div>
            </div>
          </div>

          <div class="p-2 v-bbgray">
            <h3 class="text-center mt-4">Питание бортового оборудования</h3>
            <div class="flex flex-wrap">
              <div class="w-1/3 flex flex-col my-2 px-4 max-m:w-full">
                <input
                  class="mychbox"
                  type="checkbox"
                  id="power"
                  :disabled="role != 'Master'"
                  v-model="orderValue.oper.power"
                  @change="saveOper(orderValue.oper)"
                />
                <label for="power">Постоянное</label>
              </div>
              <div class="w-1/3 flex flex-col my-2 px-4 max-m:w-full">
                <input
                  class="mychbox"
                  type="checkbox"
                  id="plus"
                  :disabled="role != 'Master'"
                  v-model="orderValue.oper.plus"
                  @change="saveOper(orderValue.oper)"
                />
                <label for="plus">Отключаемый плюс</label>
              </div>
              <div class="w-1/3 flex flex-col my-2 px-4 max-m:w-full">
                <input
                  class="mychbox"
                  type="checkbox"
                  id="minus"
                  :disabled="role != 'Master'"
                  v-model="orderValue.oper.minus"
                  @change="saveOper(orderValue.oper)"
                />
                <label for="minus">Отключаемый минус</label>
              </div>
            </div>
          </div>
        </div>
        <div class="w-1/4 h-full flex flex-col mt-10 max-m:w-full">
          <div class="p-2 text-black font-latobold text-lg v-bbgray">
            <h2>Данные ТС</h2>
            <div class="flex flex-wrap">
              <template v-for="i in orderValue.oper.images" :key="i">
                <div
                  v-if="[2, 3, 4].includes(parseInt(i.status))"
                  class="relative m-2 w-16"
                >
                  <a
                    class="absolute -right-2 -top-2 group"
                    v-if="profile.uid == i.uid"
                    @click="delImg({ img: i, item: orderValue.oper })"
                  >
                    <svg
                      class="bg-white rounded-full w-5 h-5 group-hover:fill-rose-500 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a>
                  <a
                    class="flex flex-col w-full text-center"
                    :href="imagePrefix + 'oper/orig/' + i.img"
                    target="_blank"
                  >
                    <span class="w-16 h-16 rounded-md overflow-hidden mb-1">
                      <img
                        v-if="
                          i.img.includes('png') ||
                          i.img.includes('jpg') ||
                          i.img.includes('jpeg') ||
                          i.img.includes('bmp')
                        "
                        class="w-full h-full object-cover"
                        :src="imagePrefix + 'oper/mini/' + i.img"
                        alt=""
                      />
                      <img
                        v-else-if="i.img.includes('pdf')"
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/pdf-file.png"
                        alt=""
                      />
                      <img
                        v-else
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/txt-file.png"
                        alt=""
                      />
                    </span>
                    <p>{{ imgName[i.status] }}</p>
                  </a>
                </div>
              </template>
            </div>
          </div>

          <div v-if="['Logist', 'Master'].includes(role)" class="mb-2">
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="2"
                :name="imgName[2]"
              />
            </div>
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="3"
                :name="imgName[3]"
              />
            </div>
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="4"
                :name="imgName[4]"
              />
            </div>
          </div>

          <div class="p-2 text-black font-latobold text-lg v-bbgray">
            <h2>Терминал</h2>
            <div class="flex flex-wrap text-center">
              <template v-for="i in orderValue.oper.images" :key="i">
                <div
                  v-if="[5, 6, 7, 8, 9].includes(parseInt(i.status))"
                  class="relative m-2 w-16"
                >
                  <a
                    class="absolute -right-2 -top-2 group"
                    v-if="profile.uid == i.uid"
                    @click="delImg({ img: i, item: orderValue.oper })"
                  >
                    <svg
                      class="bg-white rounded-full w-5 h-5 group-hover:fill-rose-500 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a>
                  <a
                    class="flex flex-col w-full text-center"
                    :href="imagePrefix + 'oper/orig/' + i.img"
                    target="_blank"
                  >
                    <span class="w-16 h-16 rounded-md overflow-hidden mb-1">
                      <img
                        v-if="
                          i.img.includes('png') ||
                          i.img.includes('jpg') ||
                          i.img.includes('jpeg') ||
                          i.img.includes('bmp')
                        "
                        class="w-full h-full object-cover"
                        :src="imagePrefix + 'oper/mini/' + i.img"
                        alt=""
                      />
                      <img
                        v-else-if="i.img.includes('pdf')"
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/pdf-file.png"
                        alt=""
                      />
                      <img
                        v-else
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/txt-file.png"
                        alt=""
                      />
                    </span>
                    <p>{{ imgName[i.status] }}</p>
                  </a>
                </div>
              </template>
            </div>
          </div>

          <div v-if="['Logist', 'Master'].includes(role)" class="mb-2">
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="5"
                :name="imgName[5]"
              />
            </div>
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="6"
                :name="imgName[6]"
              />
            </div>
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="7"
                :name="imgName[7]"
              />
            </div>
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="8"
                :name="imgName[8]"
              />
            </div>
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="9"
                :name="imgName[9]"
              />
            </div>
          </div>

          <div class="p-2 text-black font-latobold text-lg v-bbgray">
            <h2>Датчик уровня топлива</h2>
            <div class="flex flex-wrap text-center">
              <template v-for="i in orderValue.oper.images" :key="i">
                <div
                  v-if="[10, 11, 12, 13].includes(parseInt(i.status))"
                  class="relative m-2 w-16"
                >
                  <a
                    class="absolute -right-2 -top-2 group"
                    v-if="profile.uid == i.uid"
                    @click="delImg({ img: i, item: orderValue.oper })"
                  >
                    <svg
                      class="bg-white rounded-full w-5 h-5 group-hover:fill-rose-500 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a>
                  <a
                    class="flex flex-col w-full text-center"
                    :href="imagePrefix + 'oper/orig/' + i.img"
                    target="_blank"
                  >
                    <span class="w-16 h-16 rounded-md overflow-hidden mb-1">
                      <img
                        v-if="
                          i.img.includes('png') ||
                          i.img.includes('jpg') ||
                          i.img.includes('jpeg') ||
                          i.img.includes('bmp')
                        "
                        class="w-full h-full object-cover"
                        :src="imagePrefix + 'oper/mini/' + i.img"
                        alt=""
                      />
                      <img
                        v-else-if="i.img.includes('pdf')"
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/pdf-file.png"
                        alt=""
                      />
                      <img
                        v-else
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/txt-file.png"
                        alt=""
                      />
                    </span>
                    <p>{{ imgName[i.status] }}</p>
                  </a>
                </div>
              </template>
            </div>
          </div>

          <div v-if="['Logist', 'Master'].includes(role)" class="mb-2">
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="10"
                :name="imgName[10]"
              />
            </div>
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="11"
                :name="imgName[11]"
              />
            </div>
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="12"
                :name="imgName[12]"
              />
            </div>
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="13"
                :name="imgName[13]"
              />
            </div>
          </div>

          <div class="p-2 text-black font-latobold text-lg v-bbgray">
            <h2>Доп.оборудование</h2>
            <div class="flex flex-wrap text-center">
              <template v-for="i in orderValue.oper.images" :key="i">
                <div
                  v-if="[14, 15].includes(parseInt(i.status))"
                  class="relative m-2 w-16"
                >
                  <a
                    class="absolute -right-2 -top-2 group"
                    v-if="profile.uid == i.uid"
                    @click="delImg({ img: i, item: orderValue.oper })"
                  >
                    <svg
                      class="bg-white rounded-full w-5 h-5 group-hover:fill-rose-500 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a>
                  <a
                    class="flex flex-col w-full text-center"
                    :href="imagePrefix + 'oper/orig/' + i.img"
                    target="_blank"
                  >
                    <span class="w-16 h-16 rounded-md overflow-hidden mb-1">
                      <img
                        v-if="
                          i.img.includes('png') ||
                          i.img.includes('jpg') ||
                          i.img.includes('jpeg') ||
                          i.img.includes('bmp')
                        "
                        class="w-full h-full object-cover"
                        :src="imagePrefix + 'oper/mini/' + i.img"
                        alt=""
                      />
                      <img
                        v-else-if="i.img.includes('pdf')"
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/pdf-file.png"
                        alt=""
                      />
                      <img
                        v-else
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/txt-file.png"
                        alt=""
                      />
                    </span>
                    <p>{{ imgName[i.status] }}</p>
                  </a>
                </div>
              </template>
            </div>
          </div>

          <div v-if="['Logist', 'Master'].includes(role)" class="mb-2">
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="14"
                :name="imgName[14]"
              />
            </div>
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="15"
                :name="imgName[15]"
              />
            </div>
          </div>

          <div class="p-2 text-black font-latobold text-lg v-bbgray">
            <h2>Другое</h2>
            <div class="flex flex-wrap text-center">
              <template v-for="i in orderValue.oper.images" :key="i">
                <div
                  v-if="[1].includes(parseInt(i.status))"
                  class="relative m-2 w-16"
                >
                  <a
                    class="absolute -right-2 -top-2 group"
                    v-if="profile.uid == i.uid"
                    @click="delImg({ img: i, item: orderValue.oper })"
                  >
                    <svg
                      class="bg-white rounded-full w-5 h-5 group-hover:fill-rose-500 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a>
                  <a
                    class="flex flex-col w-full text-center"
                    :href="imagePrefix + 'oper/orig/' + i.img"
                    target="_blank"
                  >
                    <span class="w-16 h-16 rounded-md overflow-hidden mb-1">
                      <img
                        v-if="
                          i.img.includes('png') ||
                          i.img.includes('jpg') ||
                          i.img.includes('jpeg') ||
                          i.img.includes('bmp')
                        "
                        class="w-full h-full object-cover"
                        :src="imagePrefix + 'oper/mini/' + i.img"
                        alt=""
                      />
                      <img
                        v-else-if="i.img.includes('pdf')"
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/pdf-file.png"
                        alt=""
                      />
                      <img
                        v-else
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/txt-file.png"
                        alt=""
                      />
                    </span>
                    <p>{{ imgName[i.status] }}</p>
                  </a>
                </div>
              </template>
            </div>
          </div>

          <div v-if="['Logist', 'Master'].includes(role)" class="mb-2">
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="1"
                :name="imgName[1]"
              />
            </div>
          </div>

          <div class="p-2 text-black font-latobold text-lg v-bbgray">
            <h2>Заявление</h2>
            <div class="flex flex-wrap text-center">
              <template v-for="i in orderValue.oper.images" :key="i">
                <div
                  v-if="[55].includes(parseInt(i.status))"
                  class="relative m-2 w-16"
                >
                  <a
                    class="absolute -right-2 -top-2 group"
                    v-if="profile.uid == i.uid"
                    @click="delImg({ img: i, item: orderValue.oper })"
                  >
                    <svg
                      class="bg-white rounded-full w-5 h-5 group-hover:fill-rose-500 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a>
                  <a
                    class="flex flex-col w-full text-center"
                    :href="imagePrefix + 'oper/orig/' + i.img"
                    target="_blank"
                  >
                    <span class="w-16 h-16 rounded-md overflow-hidden mb-1">
                      <img
                        v-if="
                          i.img.includes('png') ||
                          i.img.includes('jpg') ||
                          i.img.includes('jpeg') ||
                          i.img.includes('bmp')
                        "
                        class="w-full h-full object-cover"
                        :src="imagePrefix + 'oper/mini/' + i.img"
                        alt=""
                      />
                      <img
                        v-else-if="i.img.includes('pdf')"
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/pdf-file.png"
                        alt=""
                      />
                      <img
                        v-else
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/txt-file.png"
                        alt=""
                      />
                    </span>
                    <p>{{ imgName[i.status] }}</p>
                  </a>
                </div>
              </template>
            </div>
          </div>

          <div v-if="['Logist', 'Master'].includes(role)" class="mb-2">
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="55"
                :name="imgName[55]"
              />
            </div>
          </div>

          <div class="p-2 text-black font-latobold text-lg v-bbgray">
            <h2>Расписка</h2>
            <div class="flex flex-wrap text-center">
              <template v-for="i in orderValue.oper.images" :key="i">
                <div
                  v-if="[60].includes(parseInt(i.status))"
                  class="relative m-2 w-16"
                >
                  <a
                    class="absolute -right-2 -top-2 group"
                    v-if="profile.uid == i.uid"
                    @click="delImg({ img: i, item: orderValue.oper })"
                  >
                    <svg
                      class="bg-white rounded-full w-5 h-5 group-hover:fill-rose-500 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a>
                  <a
                    class="flex flex-col w-full text-center"
                    :href="imagePrefix + 'oper/orig/' + i.img"
                    target="_blank"
                  >
                    <span class="w-16 h-16 rounded-md overflow-hidden mb-1">
                      <img
                        v-if="
                          i.img.includes('png') ||
                          i.img.includes('jpg') ||
                          i.img.includes('jpeg') ||
                          i.img.includes('bmp')
                        "
                        class="w-full h-full object-cover"
                        :src="imagePrefix + 'oper/mini/' + i.img"
                        alt=""
                      />
                      <img
                        v-else-if="i.img.includes('pdf')"
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/pdf-file.png"
                        alt=""
                      />
                      <img
                        v-else
                        class="w-full h-full object-cover"
                        src="https://api.tahocrm.ru/images/txt-file.png"
                        alt=""
                      />
                    </span>
                    <p>{{ imgName[i.status] }}</p>
                  </a>
                </div>
              </template>
            </div>
          </div>

          <div v-if="['Logist', 'Master'].includes(role)" class="mb-2">
            <div class="p-2">
              <UploadImg
                :item="orderValue.oper"
                :id="orderValue.oper.id"
                type="oper"
                status="60"
                :name="imgName[60]"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import UploadImg from "../ui/UploadImg.vue";
import moment from "moment";
import API from "@/services/api";

export default {
  name: "OrderOper",

  props: ["order", "role", "profile", "saveOper", "delImg"],

  components: { UploadImg },

  emits: ["close"],

  data() {
    return {
      moment: moment,
      loadForm: true,
      imgName: {
        1: "Другое",
        2: "Гос.номер",
        3: "До монтажа",
        4: "После монтажа",
        5: "S/N терминала",
        6: "S/N пломб",
        7: "Общий вид",
        8: "Сим-карта",
        9: "Спидометр-одометр",
        10: "До монтажа ДУТ",
        11: "Установленный ДУТ",
        12: "Закрепленный кабель ДУТ",
        13: "Шляпка ДУТ с пломбой и S/N",
        14: "Доп. оборудование с S/N",
        15: "Пломба на месте подключения",
        55: "Заявление",
        60: "Расписка"
      },
      imagePrefix: API.IMG,
    };
  },

  computed: {
    ...mapGetters([
      /*"profile", "role", "order"*/
    ]),
    orderValue() {
      return this.order;
    },
  },

  methods: {
    ...mapActions([
      /*"saveOper", "delImg"*/
    ]),

    bool() {
      this.orderValue.oper.corpus = Boolean(this.orderValue.oper.corpus);
      this.orderValue.oper.nican = Boolean(this.orderValue.oper.nican);
      this.orderValue.oper.power = Boolean(this.orderValue.oper.power);
      this.orderValue.oper.plus = Boolean(this.orderValue.oper.plus);
      this.orderValue.oper.minus = Boolean(this.orderValue.oper.minus);
      this.orderValue.oper.can = Boolean(this.orderValue.oper.can);
      this.orderValue.oper.canlog = Boolean(this.orderValue.oper.canlog);
      this.orderValue.oper.canc = Boolean(this.orderValue.oper.canc);
      this.orderValue.oper.up = Boolean(this.orderValue.oper.up);
      this.orderValue.oper.door = Boolean(this.orderValue.oper.door);
      this.orderValue.oper.trailer = Boolean(this.orderValue.oper.trailer);
      this.orderValue.oper.rotate = Boolean(this.orderValue.oper.rotate);
      this.orderValue.oper.engine = Boolean(this.orderValue.oper.engine);
      this.orderValue.oper.dop = Boolean(this.orderValue.oper.dop);
      this.orderValue.oper.sltaho = Boolean(this.orderValue.oper.sltaho);
      this.orderValue.oper.slspeed = Boolean(this.orderValue.oper.slspeed);
      this.orderValue.oper.slsensor = Boolean(this.orderValue.oper.slsensor);

      this.orderValue.oper.docs = Boolean(this.orderValue.oper.docs);
      this.orderValue.oper.doce = Boolean(this.orderValue.oper.doce);
      this.orderValue.oper.docp = Boolean(this.orderValue.oper.docp);
      this.orderValue.oper.docc = Boolean(this.orderValue.oper.docc);
    },
  },
  mounted() {
    this.bool();
  },
};
</script>
