<template>
  <div class="flex items-center mt-5 w-full">
    <input type="month" class="month-report-top mr-5" v-model="dateTop" />

    <button @click="getTableMark()" class="button-form-report">
      Сформировать
    </button>

    <p
      v-if="showMarketing"
      class="ml-auto mr-5 text-base font-semibold break-all"
      style="line-height: 100%"
    >
      Не вошли в отчет - {{ marketing.leads_ids.length }} шт.
    </p>

    <button
      v-if="showMarketing"
      @click="getLids(marketing.leads_ids)"
      class="button-form-report"
    >
      Показать
    </button>
  </div>
   <!-- ВЫБОР ДАТ -->
   <div class="flex flex-row mt-2 w-full " >
          <div class="flex flex-row items-center ">
            <p class="change-mounth-chart-text mr-2">C</p>
            <input           
            type="date"
            class="month-report-top mr-5"
            v-model="marDateStart"
            />
            <p class="change-mounth-chart-text mr-2">ПО</p>
            <input           
            type="date"
            class="month-report-top mr-5"
            v-model="marDateEnd"
            />
          </div>
          <button class="button-form-report mr-4" @click="getMarketingAllPer()">
          Сформировать
        </button>
    </div>
      <!-- ПОЛУБУБЛИКИ -->
  <div v-if="showMarketing"
      class="flex mt-4 flex-col"
    >
<!-- ПОЛУБУБЛИК1 -->
    <div class="flex mt-4">
      <div class="bg-white flex flex-row w-1/3 py-4 mr-4 mt-4 relative rounded-[8px] justify-center items-center">
        <div class="">
          <div class="noPlanText relative" v-if="marketing.bulbick.all_lead.plan == 0">
            <div class="line100"></div>

            <div
              :class="{
                gray: 1,
              }"
              class=""
              role="progressbar"
              :aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
              :style="'--value:' + 0"
            ></div>
          </div>
          <div v-else class="relative">
            <div class="line100"></div>

            <div
              :class="{
                yellow:
                  Math.round(
                    (marketing.bulbick.all_lead.fact /
                      marketing.bulbick.all_lead.plan) *
                      100
                  ) < 100 &&
                  Math.round(
                    (marketing.bulbick.all_lead.fact   /
                      marketing.bulbick.all_lead.plan) *
                      100
                  ) >= 90,
                red:
                  Math.round(
                    (marketing.bulbick.all_lead.fact   /
                      marketing.bulbick.all_lead.plan) *
                      100
                  ) < 90,
                green:
                  Math.round(
                    (marketing.bulbick.all_lead.fact   /
                      marketing.bulbick.all_lead.plan) *
                      100
                  ) >= 100,
              }"
              class=""
              role="progressbar"
              :aria-valuenow="
                Math.round(
                  (marketing.bulbick.all_lead.fact   /
                    marketing.bulbick.all_lead.plan) *
                    100
                )
              "
              aria-valuemin="0"
              aria-valuemax="100"
              :style="
                '--value:' +
                Math.round(
                  (marketing.bulbick.all_lead.fact   /
                    marketing.bulbick.all_lead.plan) *
                    100
                )
              "
            ></div>
          </div>
        </div>

        <div class="ml-2">
          <p class="numberToday pt-6">
            {{ addSpace(marketing.bulbick.all_lead.fact  ) }}
          </p>
          <p class="numberLabelToday pt-2">Все лиды</p>

          <p class="planText pt-6">
            план на месяц:
            <span>{{
              addSpace(marketing.bulbick.all_lead.plan)
            }}</span>
          </p>
          <!-- <p class="planText pt-2">
            остаток плана на месяц:
            <span>{{
              addSpace(
                marketing.bulbick.all_lead.plan -
                  marketing.bulbick.all_lead.fact  
              )
            }}</span>
          </p> -->
          <p></p>
        </div>
      </div>

      <!-- ПОЛУБУБЛИК2 -->
      <div class="bg-white flex flex-row w-1/3 py-4 mr-4 mt-4 relative rounded-[8px] justify-center items-center">
        <div class="">
          <div class="noPlanText relative" v-if="marketing.bulbick.all_lead.fact == 0">
            <div class="line100"></div>

            <div
              :class="{
                gray: 1,
              }"
              class=""
              role="progressbar"
              :aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
              :style="'--value:' + 0"
            ></div>
          </div>
          <div v-else class="relative">
            <div class="line100"></div>

            <div
              :class="{
                yellow:
                  Math.round(
                    (marketing.bulbick.first_lead.fact /
                      marketing.bulbick.all_lead.fact) *
                      100
                  ) < 100 &&
                  Math.round(
                    (marketing.bulbick.first_lead.fact   /
                      marketing.bulbick.all_lead.fact) *
                      100
                  ) >= 90,
                red:
                  Math.round(
                    (marketing.bulbick.first_lead.fact   /
                      marketing.bulbick.all_lead.fact) *
                      100
                  ) < 90,
                green:
                  Math.round(
                    (marketing.bulbick.first_lead.fact   /
                      marketing.bulbick.all_lead.fact) *
                      100
                  ) >= 100,
              }"
              class=""
              role="progressbar"
              :aria-valuenow="
                Math.round(
                  (marketing.bulbick.first_lead.fact   /
                    marketing.bulbick.all_lead.fact) *
                    100
                )
              "
              aria-valuemin="0"
              aria-valuemax="100"
              :style="
                '--value:' +
                Math.round(
                  (marketing.bulbick.first_lead.fact   /
                    marketing.bulbick.all_lead.fact) *
                    100
                )
              "
            ></div>
          </div>
        </div>

        <div class="ml-2">
          <p class="numberToday pt-6">
            {{ addSpace(marketing.bulbick.first_lead.fact  ) }}
          </p>
          <p class="numberLabelToday pt-2">Первичные</p>
          <!-- <p class="planText pt-2">
            остаток плана на месяц:
            <span>{{
              addSpace(
                marketing.bulbick.all_lead.fact -
                  marketing.bulbick.first_lead.fact  
              )
            }}</span>
          </p> -->
          <p></p>
        </div>
      </div>
            <!-- ПОЛУБУБЛИК3 -->
            <div class="bg-white flex flex-row w-1/3 py-4 mt-4 relative rounded-[8px] justify-center items-center">
        <div class="">
          <div class="noPlanText relative" v-if="marketing.bulbick.all_lead.fact == 0">
            <div class="line100"></div>

            <div
              :class="{
                gray: 1,
              }"
              class=""
              role="progressbar"
              :aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
              :style="'--value:' + 0"
            ></div>
          </div>
          <div v-else class="relative">
            <div class="line100"></div>

            <div
              :class="{
                yellow:
                  Math.round(
                    (marketing.bulbick.second_lead.fact /
                      marketing.bulbick.all_lead.fact) *
                      100
                  ) < 100 &&
                  Math.round(
                    (marketing.bulbick.second_lead.fact   /
                      marketing.bulbick.all_lead.fact) *
                      100
                  ) >= 90,
                red:
                  Math.round(
                    (marketing.bulbick.second_lead.fact   /
                      marketing.bulbick.all_lead.fact) *
                      100
                  ) < 90,
                green:
                  Math.round(
                    (marketing.bulbick.second_lead.fact   /
                      marketing.bulbick.all_lead.fact) *
                      100
                  ) >= 100,
              }"
              class=""
              role="progressbar"
              :aria-valuenow="
                Math.round(
                  (marketing.bulbick.second_lead.fact   /
                    marketing.bulbick.all_lead.fact) *
                    100
                )
              "
              aria-valuemin="0"
              aria-valuemax="100"
              :style="
                '--value:' +
                Math.round(
                  (marketing.bulbick.second_lead.fact   /
                    marketing.bulbick.all_lead.fact) *
                    100
                )
              "
            ></div>
          </div>
        </div>
        
        <div class="ml-2">
          <p class="numberToday pt-6">
            {{ addSpace(marketing.bulbick.second_lead.fact  ) }}
          </p>
          <p class="numberLabelToday pt-2">вторичные</p>

          <!-- <p class="planText pt-2">
            остаток плана на месяц:
            <span>{{
              addSpace(
                marketing.bulbick.all_lead.fact -
                  marketing.bulbick.second_lead.fact  
              )
            }}</span>
          </p> -->
          <p></p>
        </div>
      </div>
    </div>

  </div>
  <div v-if="showMarketing" class="mt-10">
    <div class="plan-table-wrap rounded-[8px] w-full ">
      <div class="plan-table-title bg-white border-tr-8">
        <h2>статистика каналов продаж по лидам</h2>
      </div>
      <div class="wrap-pt-grid  bg-green-200 w-full">
        <div class="wrap-pt-gtid-div1 py-2"><span>Направление</span></div>
        <div class="wrap-pt-gtid-div2 py-2"><span>Бизнес-процесс</span></div>
        <div class="wrap-pt-gtid-div3 py-2"><span>Канал привлечения</span></div>
        <div class="wrap-pt-gtid-div4 py-2"><span>Вид коммуникаций</span></div>
        <div class="wrap-pt-gtid-div5 py-2"><span>Первичный</span></div>
        <div class="wrap-pt-gtid-div6 py-2"><span>Вторичный</span></div>
        <div class="wrap-pt-gtid-div7 py-2"><span>Общий</span></div>
      </div>
      <div class="parent w-full" >
        <div v-for="(item, index) in marketing.divs" :key="index" :class="[item.ids != undefined ? `${index} cursor-pointer hover:shadow-md transition-all duration-300 hover:!bg-green-100`: '', `${index}`]"  @click="item.ids.length ? getLids(item.ids) : null">
           <span v-if="item.kolvo != undefined">{{ item.kolvo }}</span> 
           <span v-if="item.percent != undefined" class="text-[12px] text-gray-400">{{ " ("+item.percent + "%)" }}</span>
           <span v-else-if="!item.kolvo && !item.percent">{{ item }}</span>  
        </div>
      </div>
      <!-- <div
        v-for="row in marketing"
        :key="row"
        class="wrap-pt-grid data-padding-pt bg-white border-br-8"
      >
        <p>
          <span v-if="row.direction== 2">
            <span>Исходящий</span>
          </span>
          <span v-else>
            <span>Входящий</span>
          </span>
        </p>

        <p>
          <span v-if="row.business_proc == 1">Пролонгация тахографы (СКЗИ)</span>
          <span v-if="row.business_proc == 2">Пролонгация настройки ЕСТР</span>
          <span v-if="row.business_proc == 3">Пролонгация пропуска</span>
          <span v-if="row.business_proc == 4">Пролонгация карты</span>
          <span v-if="row.business_proc == 5">Контроль качества</span>
          <span v-if="row.business_proc == 6">Реанимация неактивных клиентов</span>
          <span v-if="row.business_proc == 7">Реанимация клиентов без заказов</span>
          <span v-if="row.business_proc == 8">Рекламации</span>
          <span v-if="row.business_proc == 9">Как дела</span>
          <span v-if="row.business_proc == 10">Общий</span>
          <span v-if="row.business_proc == 11">АБ Мониторинг</span>
          <span v-if="row.business_proc == 12">Агент</span>
          <span v-if="row.business_proc == 13">Рекомендация</span>
          <span v-if="row.business_proc == 14">Интернет</span>
          <span v-if="row.business_proc == 15">Холодная база</span>
        </p>

        <p>
          <span v-if="row.channel == 22">
            <span>
              Рассылка
            </span>
          </span>
          <span v-if="row.channel == 21">
            <span>
              Вторичная клиентская база
            </span>
          </span>
          <span v-if="row.channel == 2">
            <span>
              Рекомендация
            </span>
          </span>
          <span v-if="row.channel == 1">
            <span>
              Интернет
            </span>
          </span>
          <span v-if="row.channel == 15">
            <span>
              Обзвон новой базы
            </span>
          </span>
          <span v-if="row.channel == 3">
            <span>
              Агент
            </span>
          </span>
          <span v-if="row.channel == 23">
            <span>
              Агент сотрудник
            </span>
          </span>
        </p>

        <p>
          <span v-if="row.communication_type == 1"
            ><b> Интернет контекст звонок </b> <br
          /></span>
          <span v-if="row.communication_type == 2"
            ><b> Интернет контекст заявка с сайта </b> <br
          /></span>
          <span v-if="row.communication_type == 3"><b> Интернет СЕО звонок </b> <br /></span>
          <span v-if="row.communication_type == 4"
            ><b> Интернет СЕО заявка с сайта </b><br />
          </span>
          <span v-if="row.communication_type == 5"><b> Звонок Исходящий </b></span>
          <span v-if="row.communication_type == 6"><b> Письмо </b> <br /></span>
          <span v-if="row.communication_type == 7"><b> Пришел в офис</b><br /> </span>
          <span v-if="row.communication_type == 8"
            ><b> Заявка через ЛК агента СРМ </b><br />
          </span>
          <span v-if="row.communication_type == 9"><b> Рассылка/Звонок </b> <br /></span>
          <span v-if="row.communication_type == 10"
            ><b> Заявка через ЛК клиента в СРМ </b><br />
          </span>
          <span v-if="row.communication_type == 11"
            ><b> Заявка через ЛК агента-сотрудника </b>
          </span>
          <span v-if="row.communication_type == 13"><b> Заявка с сайта </b> </span>
          <span v-if="row.communication_type == 15"><b> Звонок Входящий </b> </span>
        </p>

        <p
          @click="getLids(row.first_ids)"
          class="price-text cursor-pointer hover:shadow-md transition-all duration-300 hover:!bg-green-100"
        >
          {{ row.first }} <span>{{ BlockNew(row.first) }}</span>
        </p>
        <p
          @click="getLids(row.second_ids)"
          class="price-text cursor-pointer hover:shadow-md transition-all duration-300 hover:!bg-green-100"
        >
          {{ row.second }} <span>{{ BlockSecond(row.second) }}</span>
        </p>
        <p
          @click="getLids(row.quantity_ids)"
          class="price-text cursor-pointer hover:shadow-md transition-all duration-300 hover:!bg-green-100"
        >
          {{ row.quantity }} <span>{{ BlockTotal(row.quantity) }}</span>
        </p>
      </div>
      <div
        class="wrap-pt-grid data-padding-pt bg-white border-br-8 border-top-b"
      >
        <p>Всего</p>
        <p></p>
        <p></p>
        <p></p>
        <p class="price-text">
          {{ sum_first }} <span>{{ pers_first.toFixed(2) }}%</span>
        </p>
        <p class="price-text">
          {{ sum_second }} <span>{{ pers_second.toFixed(2) }}%</span>
        </p>
        <p class="price-text">{{ sum_quantity }} <span>100%</span></p>
      </div>
    </div> -->
   </div>
  </div>
  <div
    v-if="showLeadsModal"
    id="popnew2"
    class="w-10/12 bg-slate-200 fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-y-auto overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
  >
    <LeadsListModal :LeadList="factLeadList" />
  </div>
  <div
    v-if="showLeadsModal"
    @click="showLeadsModal = false"
    id="overlay"
    class="fixed bg-cyan-800 z-10 w-full h-full top-0 left-0 opacity-80"
  ></div> 
  <div v-if="showMarketing" class="mt-10 mb-2 flex flex-row justify-between" >
    <div
        class="flex"
      >
        <input
          type="month"
          class="month-report-top mr-5"
          v-model="newDateChart"
        />
        <button @click="reformDatShit()" class="button-form-report">
          Пересчитать график
        </button>
      </div>

    <div
        class="orders-tabs px-5 w-fit bg-white rounded-lg flex flex-wrap justify-around items-center"
      >
        <a
          :class="{ checkedTab: tab == 'type' }"
          class="link py-3 px-4 w-[150px] uppercase"
          @click="tab = 'type'"
          >Виды</a
        >
        <a
          :class="{ checkedTab: tab == 'direction' }"
          class="link py-3 px-4 w-[150px] uppercase"
          @click="tab = 'direction'"
          >Направления</a
        >
      </div>
  </div>
    <div v-if="showMarketing && tab == 'type'"
        class="bg-white flex flex-col w-full pt-4 pb-6 relative rounded-[8px] justify-center items-center max-h-[550px]"
      >
        <p class="font-latobold text-base uppercase mb-4">
          ВИДЫ
        </p>
        <Line
          class="max-h-[350px] min-h-[350px]"
          :data="typeLineData"
          :options="optionsLine"
        />
      </div>
      <div v-if="showMarketing && tab == 'type'" class="flex flex-row mt-2 mb-6 w-full" >
          <div class="flex flex-row items-center ">
            <p class="change-mounth-chart-text ml-4 mr-2">ОТ</p>
            <input           
            type="month"
            class="month-report-top mr-5"
            v-model="firstDateChart"
            />
            <p class="change-mounth-chart-text mr-2">ПО</p>
            <input           
            type="month"
            class="month-report-top mr-5"
            v-model="secondDateChart"
            />
          </div>
          <button @click="changeDateChartTypes()" class="button-form-report mr-4">
          Применить
        </button>
        </div>
      <div v-if="showMarketing && tab == 'direction'"
        class="bg-white flex flex-col w-full pt-4 pb-6 relative rounded-[8px] justify-center items-center max-h-[550px]"
      >
        <p class="font-latobold text-base uppercase mb-4">
          НАПАРАВЛЕНИЯ
        </p>
        <Line
          class="max-h-[350px] min-h-[350px]"
          :data="directionLineData"
          :options="optionsLine"
        />
      </div>
      <div v-if="showMarketing && tab == 'direction'" class="flex flex-row mt-2 mb-6 w-full " >
          <div class="flex flex-row items-center ">
            <p class="change-mounth-chart-text ml-4 mr-2">C</p>
            <input           
            type="month"
            class="month-report-top mr-5"
            v-model="firstDateChart"
            />
            <p class="change-mounth-chart-text mr-2">ПО</p>
            <input           
            type="month"
            class="month-report-top mr-5"
            v-model="secondDateChart"
            />
          </div>
          <button @click="changeDateChartDirections()" class="button-form-report mr-4">
          Применить
        </button>
        </div>

</template>

<script>
import LeadsListModal from "@/components/report/LeadsListModal.vue";
import { mapActions, mapGetters } from "vuex";
import { Line } from "vue-chartjs";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  ChartJsPluginDataLabels,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default {
  name: "SellChannel",
  components: {
    LeadsListModal,
    Line,
  },
  data() {
    return {
      showLeadsModal: false,
      showMarketing: false,

      sum_first: 0,
      sum_second: 0,
      sum_quantity: 0,
      pers_first: 0,
      pers_second: 0,
      dateTop: null,
      tab: 'type',

      marDateStart: "2025-01-01",
      marDateEnd:"",

      typeLineData: {},
      directionLineData: {},

      newDateChart: null,
      loadedChart: false,
      firstDateChart: null,
      secondDateChart: null,

      optionsLine: {
        responsive: true,
        maintainAspectRatio: false,
        tension: 0.4,

        plugins: {
          datalabels: {
            align: "top",
            color: function (ctx) {
              // use the same color as the border
              return ctx.dataset.borderColor;
            },
            font: {
              weight: "bold",
              size: 12,
            },
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters([
      "s",
      "profile",
      "role",
      "roles",
      "marketing",
      "factLeadList",
      "marketingChart",
    ]),
  },

  methods: {
    ...mapActions(["getMarketingAll", "getFactLeadList", "getMarketingChart", "regetMarketingChart", "getMarketingAllPeriod"]),

    async reformDatShit() {
      await this.regetMarketingChart ({ date: this.newDateChart });

      this.formingChart = true;
      this.loadedChart = false;
      window.DEBUG_REPORT = this;
      this.loadedChart = true;
    },

    async getLids(ids_row) {
      await this.getFactLeadList({ ids: ids_row });

      this.showLeadsModal = true;
    },

    addSpace(strNumber) {
      return String(strNumber).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");
    },
    BlockNew(value) {
      if (value == 0) {
        return "0%";
      } else {
        let result = ((value / this.sum_first) * 100).toFixed(2);
        return +result + "%";
      }
      // return value;
    },

    BlockSecond(value) {
      if (value == 0) {
        return "0%";
      } else {
        let result = ((value / this.sum_second) * 100).toFixed(2);
        return +result + "%";
      }
      // return value;
    },

    BlockTotal(value) {
      if (value == 0) {
        return "0%";
      } else {
        let result = ((value / this.sum_quantity) * 100).toFixed(2);
        return +result + "%";
      }
      // return value;
    },


  

    async getTableMark() {

      await this.getMarketingAll({ date: this.dateTop });

      // for (let i = 0; i < this.marketing.length; i++) {
      //   this.sum_first += this.marketing[i].first;
      //   this.sum_second += this.marketing[i].second;
      //   this.sum_quantity += this.marketing[i].quantity;
      // }

      // this.pers_first = (this.sum_first / this.sum_quantity) * 100;
      // this.pers_second = (this.sum_second / this.sum_quantity) * 100;


      await this.getMarketingChart();
      this.DataChartTypes()
      this.DataChartDirections()
      this.showMarketing = true;
    },
    DataChartTypes() {
      let labelName = [];
      let dataMax = [];
      let dataFirst = [];
      let dataSecond = [];

      for (let i = 0; i < this.marketingChart.chart.length; i++) {

        labelName.push(this.marketingChart.chart[i].date);
        dataMax.push(this.marketingChart.chart[i].type[0]);
        dataFirst.push(this.marketingChart.chart[i].type[1]);
        dataSecond.push(this.marketingChart.chart[i].type[2]);
      }

      
      for (let i = 0; i < labelName.length; i++) {
        if (labelName[i].substring(5, 7) == "01") {
          labelName[i] = "Январь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "02") {
          labelName[i] = "Февраль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "03") {
          labelName[i] = "Март " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "04") {
          labelName[i] = "Апрель " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "05") {
          labelName[i] = "Май " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "06") {
          labelName[i] = "Июнь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "07") {
          labelName[i] = "Июль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "08") {
          labelName[i] = "Август " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "09") {
          labelName[i] = "Сентябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "10") {
          labelName[i] = "Октябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "11") {
          labelName[i] = "Ноябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "12") {
          labelName[i] = "Декабрь " + labelName[i].substring(0, 4);
        }
      }

      let result = {
        labels: labelName,
        datasets: [
          {
            label: "Общие",
            backgroundColor: "#B8EDF9",
            borderColor: "#B8EDF9",
            data: dataMax,
          },
          {
            label: "Первичные",
            backgroundColor: "#229DB7",
            borderColor: "#229DB7",

            data: dataFirst,
          },
          {
            label: "Вторичные",
            borderColor: "#90B0E0",
            backgroundColor: "#90B0E0",
            data: dataSecond,
          },
        ],
      };
      return this.typeLineData = result;
    },
    DataChartDirections() {
      let labelName = [];
      let dataMax = [];
      let dataFirst = [];
      let dataSecond = [];


      for (let i = 0; i < this.marketingChart.chart.length; i++) {
        labelName.push(this.marketingChart.chart[i].date);

        dataMax.push(this.marketingChart.chart[i].direction[0]);
        dataFirst.push(this.marketingChart.chart[i].direction[1]);
        dataSecond.push(this.marketingChart.chart[i].direction[2]);
      }
      for (let i = 0; i < labelName.length; i++) {
        if (labelName[i].substring(5, 7) == "01") {
          labelName[i] = "Январь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "02") {
          labelName[i] = "Февраль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "03") {
          labelName[i] = "Март " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "04") {
          labelName[i] = "Апрель " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "05") {
          labelName[i] = "Май " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "06") {
          labelName[i] = "Июнь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "07") {
          labelName[i] = "Июль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "08") {
          labelName[i] = "Август " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "09") {
          labelName[i] = "Сентябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "10") {
          labelName[i] = "Октябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "11") {
          labelName[i] = "Ноябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "12") {
          labelName[i] = "Декабрь " + labelName[i].substring(0, 4);
        }
      }

      let result = {
        labels: labelName,
        datasets: [
          {
            label: "Общие",
            backgroundColor: "#B8EDF9",
            borderColor: "#B8EDF9",
            data: dataMax,
          },
          {
            label: "Исходящие",
            backgroundColor: "#229DB7",
            borderColor: "#229DB7",

            data: dataFirst,
          },
          {
            label: "Входящие",
            borderColor: "#90B0E0",
            backgroundColor: "#90B0E0",
            data: dataSecond,
          },
        ],
      };
      return this.directionLineData = result;
    },
    changeDateChartTypes(){
      let labelName = [];
      let dataMax = [];
      let dataFirst = [];
      let dataSecond = [];
      let dateFrontFirst = new Date(this.firstDateChart + "-01")
      let dateFrontSecond = new Date(this.secondDateChart + "-01")

      for (let i = 0; i < this.marketingChart.chart.length; i++) {

        if (this.firstDateChart != null  && 
            this.secondDateChart == null &&
            dateFrontFirst <= new Date(this.marketingChart.chart[i].date + "-01")
        ){
          labelName.push(this.marketingChart.chart[i].date);
          dataMax.push(this.marketingChart.chart[i].type[0]);
          dataFirst.push(this.marketingChart.chart[i].type[1]);
          dataSecond.push(this.marketingChart.chart[i].type[2]);
          console.log("победа")
          for (let i = 0; i < labelName.length; i++) {
        if (labelName[i].substring(5, 7) == "01") {
          labelName[i] = "Январь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "02") {
          labelName[i] = "Февраль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "03") {
          labelName[i] = "Март " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "04") {
          labelName[i] = "Апрель " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "05") {
          labelName[i] = "Май " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "06") {
          labelName[i] = "Июнь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "07") {
          labelName[i] = "Июль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "08") {
          labelName[i] = "Август " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "09") {
          labelName[i] = "Сентябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "10") {
          labelName[i] = "Октябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "11") {
          labelName[i] = "Ноябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "12") {
          labelName[i] = "Декабрь " + labelName[i].substring(0, 4);
        }
      }

      let result = {
        labels: labelName,
        datasets: [
          {
            label: "Общие",
            backgroundColor: "#B8EDF9",
            borderColor: "#B8EDF9",
            data: dataMax,
          },
          {
            label: "Первичные",
            backgroundColor: "#229DB7",
            borderColor: "#229DB7",

            data: dataFirst,
          },
          {
            label: "Вторичные",
            borderColor: "#90B0E0",
            backgroundColor: "#90B0E0",
            data: dataSecond,
          },
        ],
      };
      console.log("result",result)
      this.typeLineData = result;
        }
        if (this.firstDateChart === null &&
            this.secondDateChart != null &&
            dateFrontSecond >= new Date(this.marketingChart.chart[i].date + "-01")
        ){
          labelName.push(this.marketingChart.chart[i].date);
          dataMax.push(this.marketingChart.chart[i].type[0]);
          dataFirst.push(this.marketingChart.chart[i].type[1]);
          dataSecond.push(this.marketingChart.chart[i].type[2]);
          console.log("победа")
          for (let i = 0; i < labelName.length; i++) {
        if (labelName[i].substring(5, 7) == "01") {
          labelName[i] = "Январь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "02") {
          labelName[i] = "Февраль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "03") {
          labelName[i] = "Март " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "04") {
          labelName[i] = "Апрель " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "05") {
          labelName[i] = "Май " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "06") {
          labelName[i] = "Июнь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "07") {
          labelName[i] = "Июль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "08") {
          labelName[i] = "Август " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "09") {
          labelName[i] = "Сентябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "10") {
          labelName[i] = "Октябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "11") {
          labelName[i] = "Ноябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "12") {
          labelName[i] = "Декабрь " + labelName[i].substring(0, 4);
        }
      }

      let result = {
        labels: labelName,
        datasets: [
          {
            label: "Общие",
            backgroundColor: "#B8EDF9",
            borderColor: "#B8EDF9",
            data: dataMax,
          },
          {
            label: "Первичные",
            backgroundColor: "#229DB7",
            borderColor: "#229DB7",

            data: dataFirst,
          },
          {
            label: "Вторичные",
            borderColor: "#90B0E0",
            backgroundColor: "#90B0E0",
            data: dataSecond,
          },
        ],
      };
      this.typeLineData = result;
        }
        if (this.firstDateChart!= null && this.secondDateChart!= null &&
            dateFrontSecond >= new Date(this.marketingChart.chart[i].date + "-01") && 
            dateFrontFirst <= new Date(this.marketingChart.chart[i].date + "-01")
        ){
          labelName.push(this.marketingChart.chart[i].date);
          dataMax.push(this.marketingChart.chart[i].type[0]);
          dataFirst.push(this.marketingChart.chart[i].type[1]);
          dataSecond.push(this.marketingChart.chart[i].type[2]);
          console.log("победа")
          for (let i = 0; i < labelName.length; i++) {
        if (labelName[i].substring(5, 7) == "01") {
          labelName[i] = "Январь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "02") {
          labelName[i] = "Февраль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "03") {
          labelName[i] = "Март " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "04") {
          labelName[i] = "Апрель " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "05") {
          labelName[i] = "Май " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "06") {
          labelName[i] = "Июнь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "07") {
          labelName[i] = "Июль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "08") {
          labelName[i] = "Август " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "09") {
          labelName[i] = "Сентябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "10") {
          labelName[i] = "Октябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "11") {
          labelName[i] = "Ноябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "12") {
          labelName[i] = "Декабрь " + labelName[i].substring(0, 4);
        }
      }

      let result = {
        labels: labelName,
        datasets: [
          {
            label: "Общие",
            backgroundColor: "#B8EDF9",
            borderColor: "#B8EDF9",
            data: dataMax,
          },
          {
            label: "Первичные",
            backgroundColor: "#229DB7",
            borderColor: "#229DB7",

            data: dataFirst,
          },
          {
            label: "Вторичные",
            borderColor: "#90B0E0",
            backgroundColor: "#90B0E0",
            data: dataSecond,
          },
        ],
      };
      this.typeLineData = result;
        }

      }
      

     },
     changeDateChartDirections(){
      let labelName = [];
      let dataMax = [];
      let dataFirst = [];
      let dataSecond = [];
      let dateFrontFirst = new Date(this.firstDateChart + "-01")
      let dateFrontSecond = new Date(this.secondDateChart + "-01")

      for (let i = 0; i < this.marketingChart.chart.length; i++) {

        if (this.firstDateChart != null  && 
            this.secondDateChart == null &&
            dateFrontFirst <= new Date(this.marketingChart.chart[i].date + "-01")
        ){
          labelName.push(this.marketingChart.chart[i].date);
          dataMax.push(this.marketingChart.chart[i].direction[0]);
          dataFirst.push(this.marketingChart.chart[i].direction[1]);
          dataSecond.push(this.marketingChart.chart[i].direction[2]);
          console.log("победа")
          for (let i = 0; i < labelName.length; i++) {
        if (labelName[i].substring(5, 7) == "01") {
          labelName[i] = "Январь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "02") {
          labelName[i] = "Февраль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "03") {
          labelName[i] = "Март " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "04") {
          labelName[i] = "Апрель " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "05") {
          labelName[i] = "Май " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "06") {
          labelName[i] = "Июнь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "07") {
          labelName[i] = "Июль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "08") {
          labelName[i] = "Август " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "09") {
          labelName[i] = "Сентябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "10") {
          labelName[i] = "Октябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "11") {
          labelName[i] = "Ноябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "12") {
          labelName[i] = "Декабрь " + labelName[i].substring(0, 4);
        }
      }

      let result = {
        labels: labelName,
        datasets: [
          {
            label: "Общие",
            backgroundColor: "#B8EDF9",
            borderColor: "#B8EDF9",
            data: dataMax,
          },
          {
            label: "Первичные",
            backgroundColor: "#229DB7",
            borderColor: "#229DB7",

            data: dataFirst,
          },
          {
            label: "Вторичные",
            borderColor: "#90B0E0",
            backgroundColor: "#90B0E0",
            data: dataSecond,
          },
        ],
      };
      console.log("result",result)
      this.directionLineData = result;
        }
        if (this.firstDateChart === null &&
            this.secondDateChart != null &&
            dateFrontSecond >= new Date(this.marketingChart.chart[i].date + "-01")
        ){
          labelName.push(this.marketingChart.chart[i].date);
          dataMax.push(this.marketingChart.chart[i].direction[0]);
          dataFirst.push(this.marketingChart.chart[i].direction[1]);
          dataSecond.push(this.marketingChart.chart[i].direction[2]);
          console.log("победа")
          for (let i = 0; i < labelName.length; i++) {
        if (labelName[i].substring(5, 7) == "01") {
          labelName[i] = "Январь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "02") {
          labelName[i] = "Февраль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "03") {
          labelName[i] = "Март " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "04") {
          labelName[i] = "Апрель " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "05") {
          labelName[i] = "Май " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "06") {
          labelName[i] = "Июнь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "07") {
          labelName[i] = "Июль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "08") {
          labelName[i] = "Август " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "09") {
          labelName[i] = "Сентябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "10") {
          labelName[i] = "Октябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "11") {
          labelName[i] = "Ноябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "12") {
          labelName[i] = "Декабрь " + labelName[i].substring(0, 4);
        }
      }

      let result = {
        labels: labelName,
        datasets: [
          {
            label: "Общие",
            backgroundColor: "#B8EDF9",
            borderColor: "#B8EDF9",
            data: dataMax,
          },
          {
            label: "Первичные",
            backgroundColor: "#229DB7",
            borderColor: "#229DB7",

            data: dataFirst,
          },
          {
            label: "Вторичные",
            borderColor: "#90B0E0",
            backgroundColor: "#90B0E0",
            data: dataSecond,
          },
        ],
      };
      this.directionLineData = result;
        }
        if (this.firstDateChart!= null && this.secondDateChart!= null &&
            dateFrontSecond >= new Date(this.marketingChart.chart[i].date + "-01") && 
            dateFrontFirst <= new Date(this.marketingChart.chart[i].date + "-01")
        ){
          labelName.push(this.marketingChart.chart[i].date);
          dataMax.push(this.marketingChart.chart[i].direction[0]);
          dataFirst.push(this.marketingChart.chart[i].direction[1]);
          dataSecond.push(this.marketingChart.chart[i].direction[2]);
          console.log("победа")
          for (let i = 0; i < labelName.length; i++) {
        if (labelName[i].substring(5, 7) == "01") {
          labelName[i] = "Январь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "02") {
          labelName[i] = "Февраль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "03") {
          labelName[i] = "Март " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "04") {
          labelName[i] = "Апрель " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "05") {
          labelName[i] = "Май " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "06") {
          labelName[i] = "Июнь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "07") {
          labelName[i] = "Июль " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "08") {
          labelName[i] = "Август " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "09") {
          labelName[i] = "Сентябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "10") {
          labelName[i] = "Октябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "11") {
          labelName[i] = "Ноябрь " + labelName[i].substring(0, 4);
        }
        if (labelName[i].substring(5, 7) == "12") {
          labelName[i] = "Декабрь " + labelName[i].substring(0, 4);
        }
      }

      let result = {
        labels: labelName,
        datasets: [
          {
            label: "Общие",
            backgroundColor: "#B8EDF9",
            borderColor: "#B8EDF9",
            data: dataMax,
          },
          {
            label: "Первичные",
            backgroundColor: "#229DB7",
            borderColor: "#229DB7",

            data: dataFirst,
          },
          {
            label: "Вторичные",
            borderColor: "#90B0E0",
            backgroundColor: "#90B0E0",
            data: dataSecond,
          },
        ],
      };
      this.directionLineData = result;
        }

      }
      

     },

     async getMarketingAllPer(){
      await this.getMarketingAllPeriod({date_start: this.marDateStart , date_end: this.marDateEnd});
      await this.getMarketingChart();
      this.DataChartTypes()
      this.DataChartDirections()
      this.showMarketing = true;
    },
    },
  
  async mounted() {
    var currentYear = new Date().getFullYear();
    var currentMonth = new Date().getMonth();
    // this.dateTop = currentYear;
    currentMonth = currentMonth + 1;
    // console.log('Текущий год - ', currentYear);
    // console.log('Текущий месяц - ', currentMonth);

    if (currentMonth < 10) {
      this.dateTop = "" + currentYear + "-0" + currentMonth;
    } else {
      this.dateTop = "" + currentYear + "-" + currentMonth;
    }
    console.log("Дата на обработку - ", this.dateTop);
  },
  }

</script>

<style scoped>
.orders-tabs a {
  transition: 0.4s ease;
  color: #9ca3c0;
  text-align: center;
  font-size: 13.5px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.orders-tabs a:first-child {
  margin-right: 10px;
}

.change-mounth-chart-text{
  color: #262d29;
  text-align: center;
  font-size: 13.5px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.checkedTab {
  border-radius: 3px;
  background: #22c55e;
  color: #fff !important;
}


.button-form-report {
  border-radius: 3px;
  border: none;
  color: #fff;
  text-align: center;
  font-size: 13.5px;
  font-weight: 700;
  line-height: 16.5px;
  text-transform: uppercase;
  padding: 10px 38px;
}
.wrap-pt-grid {
  display: grid;
  grid-template-columns: 1fr 20% 1fr 17% 1fr 15% 1fr 1fr 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.wrap-pt-gtid-div1{
  grid-area: 1 / 1 / 2 / 2;
  border-left:1px solid #e8ecec;
  border-right:1px solid #e8ecec;
  align-content: center;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    text-align: center;
}
.wrap-pt-gtid-div2{
  grid-area: 1 / 2 / 2 / 4;
  border-left:1px solid #e8ecec;
  border-right:1px solid #e8ecec;
  align-content: center;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    text-align: center;
}
.wrap-pt-gtid-div3{
  grid-area: 1 / 4 / 2 / 6;
  border-left:1px solid #e8ecec;
  border-right:1px solid #e8ecec;
  align-content: center;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    text-align: center;
}
.wrap-pt-gtid-div4{
  grid-area: 1 / 6 / 2 / 7;
  border-left:1px solid #e8ecec;
  border-right:1px solid #e8ecec;
  align-content: center;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    text-align: center;
}
.wrap-pt-gtid-div5{
  grid-area: 1 / 7 / 2 / 8;
  border-left:1px solid #e8ecec;
  border-right:1px solid #e8ecec;
  align-content: center;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    text-align: center;
}
.wrap-pt-gtid-div6{
  grid-area: 1 / 8 / 2 / 9;
  border-left:1px solid #e8ecec;
  border-right:1px solid #e8ecec;
  align-content: center;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    text-align: center;
}
.wrap-pt-gtid-div7{
  grid-area: 1 / 9 / 2 / 10;
  border-left:1px solid #e8ecec;
  border-right:1px solid #e8ecec;
  align-content: center;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    text-align: center;
}

.wrap-plant-grid {
  display: grid;
  grid-template-columns: 30% 30% 13.333% 13.333% 13.333%;
}
.plan-table-title {
  padding: 25px 0;
  color: rgb(38, 45, 41);
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}
.border-br-8 {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.border-tr-8 {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.plan-table-wrap > .data-padding-pt:nth-child(odd) > * {
  background: #fefefe;
}
.plan-table-wrap > .data-padding-pt:nth-child(even) > * {
  background: #f7fbfc;
}
.data-padding-pt > * {
  border-bottom: 1px solid #e8ecec;
  align-items: center;
  padding: 12px 0;
}
.images-row > img {
  height: 13px;
  width: auto;
}
.user-row > img {
  height: 20px;
  width: auto;
  margin-top: 3px;
}
.top-th {
  color: rgb(38, 45, 41);
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0%;
  text-align: center;
  border-right: 1px solid rgb(232, 236, 236);
  border-bottom: 1px solid rgb(232, 236, 236);
  padding: 10px 0;
}
.bottom-th,
.row-pt-data {
  display: flex;
  align-items: center;
}
.bottom-th > p {
  padding: 5px 0;
}
.bottom-th > *,
.row-pt-data > * {
  color: rgb(38, 45, 41);
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0%;
  text-align: center;
  border-right: 1px solid rgb(232, 236, 236);
  width: 33.3%;
}
.title-col {
  border-right: 1px solid rgb(232, 236, 236);
  border-bottom: 1px solid rgb(232, 236, 236);

  display: flex;
  align-items: center;
}
.title-col > *:first-child {
  width: 68px;
}
.title-col > *:nth-child(2) {
  width: 185px;
}
.title-col > *:last-child {
  width: 125px;
}
.title-col > p {
  color: rgb(38, 45, 41);
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: capitalize;
}
.money-row > p {
  color: rgb(38, 45, 41);
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: capitalize;
}
.row-pt-data > span {
  padding-top: 20px;
  padding-bottom: 20px;
}
.row-pt-data > input,
.row-pt-data > p {
  padding-top: 20px;
  padding-bottom: 20px;
  background: rgba(217, 217, 217, 0.3);
}

.row-pt-data > input {
  text-align: center;
}
.border-top-b {
  border-top: 1px solid #000;
}
.border-top-b > * {
  border-bottom: 1px solid transparent;
  background-color: #fefefe !important;
}

.price-text {
  font-size: 14px;
  line-height: 17px;
  padding: 4px 0px;
  color: #262d29;
  font-weight: 600;
}
.price-text span {
  font-size: 14px;
  color: #8e8e8e;
}
.button-form-report {
  border-radius: 3px;
  background: #22c55e;
  border: none;
  color: #fff;
  text-align: center;
  font-size: 13.5px;
  font-weight: 700;
  line-height: 13.5px;
  text-transform: uppercase;
  padding: 12px 88px;
}
.month-report-top {
  padding: 10px 15px !important;
  border-radius: 10px;
}


/* ПОЛУБУБЛИКИ */
@keyframes progress {
  0% {
    --percentage: 0;
  }
  100% {
    --percentage: var(--value);
  }
}

.numberLabelToday {
  color: rgb(38, 45, 41);

  font-size: 12.8px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}

.noPlanText{
  color: rgb(116, 36, 36);

  font-size: 12.8px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}

.numberToday {
  color: rgb(142, 142, 142);
  font-size: 36.57px;
  font-weight: 900;
  line-height: 44px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}

@property --percentage {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}

.line100 {
  position: absolute;
  border: 1.09px solid rgb(0, 0, 0);
  width: 25px;
  top: 39px;
  right: 29px;
  z-index: 5;
  display: block;
  transform: rotate(-45deg);
}

.progress-bar-vtr[role="progressopen"] {
  width: calc(var(--valueOD) * 2px);
  max-width: 200px;
}

[role="progressbar"] {
  --percentage: var(--value);
  --primary: #369;
  --secondary: rgb(232, 232, 232);
  --size: 220px;
  animation: progress 2s 0.5s forwards;
  width: var(--size);
  aspect-ratio: 2 / 1;
  border-radius: 50% / 100% 100% 0 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.planText {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
  color: rgb(171, 171, 171);
}
.planText > span {
  color: rgb(80, 80, 80);
}
.green[role="progressbar"] {
  --primary: rgb(34, 197, 94);
}
.yellow[role="progressbar"] {
  --primary: rgb(254, 171, 26);
}
.red[role="progressbar"] {
  --primary: rgb(250, 52, 76);
}
.gray[role="progressbar"] {
  --primary: rgb(142, 142, 142);
}

[role="progressbar"]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: conic-gradient(
    from 0.75turn at 50% 100%,
    var(--primary) calc((var(--percentage) - 25) * 1% / 2),
    var(--secondary) calc((var(--percentage) - 25) * 1% / 2 + 0.1%)
  );
  mask: radial-gradient(at 50% 100%, white 55%, transparent 55.5%);
  mask-mode: alpha;
  -webkit-mask: radial-gradient(at 50% 100%, #0000 55%, #000 55.5%);
  -webkit-mask-mode: alpha;
}

[role="progressbar"]::after {
  counter-reset: percentage calc(var(--value));
  content: counter(percentage) "%";
  font-family: Helvetica, Arial, sans-serif;
  font-size: 45px;
  color: var(--primary);
  line-height: 80%;
  font-weight: bolder;
}

/* ПОШЛИ ГРИДЫ */
.parent {
display: grid;
grid-template-columns: 1fr 20% 1fr 17% 1fr 15% 1fr 1fr 1fr;
grid-template-rows: repeat(31, 1fr);
grid-column-gap: 0px;
grid-row-gap: 0px;
}
.parent div {
  color: #262d29;
  height: auto;
  max-height: auto;
}

.div1 {
    background-color: #c4deff;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    padding: 4px 0px;
    grid-area: 11 / 2 / 13 / 3;
}
 .div2 {
    background: #cbfce5;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 13 / 2 / 27 / 3;
}
 .div3 {
    background-color: #ffd7d7;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 27 / 2 / 29 / 3;
}
 .div4 {
    background-color: #c4deff;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 11 / 3 / 13 / 4;
}
 .div5 {
    background: #cbfce5;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 13 / 3 / 27 / 4;
}
 .div6 {
    background-color: #ffd7d7;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 27 / 3 / 29 / 4;
}
 .div7 {
    background: #fbfcc9;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 2 / 4 / 16 / 5;
}
 .div8 {
    background-color: #ffe6c9;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 16 / 4 / 20 / 5;
}
 .div9 {
    background: #edd7ff;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 20 / 4 / 24 / 5;
}
 .div10 {
    background-color: #a7cfda;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 24 / 4 / 27 / 5;
}
 .div11 {
    background: #fbfcc9;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 2 / 5 / 16 / 6;
}
 .div12 {
    background-color: #ffe6c9;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 16 / 5 / 20 / 6;
}
 .div13 {
    background: #edd7ff;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 20 / 5 / 24 / 6;
}
 .div14 {
    background-color: #a7cfda;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 24 / 5 / 27 / 6;
}
 .div15 {
  background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 31 / 2 / 32 / 7;
}
 .div16 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 1 / 1 / 2 / 2;
}
 .div17 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 2 / 1 / 3 / 2;
}
 .div18 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 3 / 1 / 4 / 2;
}
 .div19 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 4 / 1 / 5 / 2;
}
 .div20 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 5 / 1 / 6 / 2;
}
 .div21 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 6 / 1 / 7 / 2;
}
 .div22 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 7 / 1 / 8 / 2;
}
 .div23 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 8 / 1 / 9 / 2;
}
 .div24 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 9 / 1 / 10 / 2;
}
 .div25 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 10 / 1 / 11 / 2;
}
 .div26 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 11 / 1 / 12 / 2;
}
 .div27 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 12 / 1 / 13 / 2;
}
 .div28 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 13 / 1 / 14 / 2;
}
 .div29 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 14 / 1 / 15 / 2;
}
 .div30 {
    background-color: #fefefe;  
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 15 / 1 / 16 / 2;
}
 .div31 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 16 / 1 / 17 / 2;
}
 .div32 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 17 / 1 / 18 / 2;
}
 .div33 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 18 / 1 / 19 / 2;
}
 .div34 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 19 / 1 / 20 / 2;
}
 .div35 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 20 / 1 / 21 / 2;
}
 .div36 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 21 / 1 / 22 / 2;
}
 .div37 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 22 / 1 / 23 / 2;
}
 .div38 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 23 / 1 / 24 / 2;
}
 .div39 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 24 / 1 / 25 / 2;
}
 .div40 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 25 / 1 / 26 / 2;
}
 .div41 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 26 / 1 / 27 / 2;
}
 .div42 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 27 / 1 / 28 / 2;
}
 .div43 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 28 / 1 / 29 / 2;
}
 .div44 {
    background-color: #fefefe;  
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 29 / 1 / 30 / 2;
}
 .div45 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 30 / 1 / 31 / 2;
}
 .div46 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 31 / 1 / 32 / 2;
}
 .div47 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 1 / 2 / 2 / 3;
}
 .div48 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 2 / 2 / 3 / 3;
}
 .div49 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 3 / 2 / 4 / 3;
}
 .div50 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 4 / 2 / 5 / 3;
}
 .div51 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 5 / 2 / 6 / 3;
}
 .div52 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 6 / 2 / 7 / 3;
}
 .div53 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 7 / 2 / 8 / 3;
}
 .div54 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 8 / 2 / 9 / 3;
}
 .div55 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 9 / 2 / 10 / 3;
}
 .div56 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 10 / 2 / 11 / 3;
}
 .div57 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 29 / 2 / 30 / 3;
}
 .div58 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 30 / 2 / 31 / 3;
}
 .div59 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 1 / 3 / 2 / 4;
}
 .div60 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 2 / 3 / 3 / 4;
}
 .div61 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 3 / 3 / 4 / 4;
}
 .div62 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 4 / 3 / 5 / 4;
}
 .div63 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 5 / 3 / 6 / 4;
}
 .div64 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 6 / 3 / 7 / 4;
}
 .div65 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 7 / 3 / 8 / 4;
}
 .div66 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 8 / 3 / 9 / 4;
}
 .div67 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 9 / 3 / 10 / 4;
}
 .div68 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 10 / 3 / 11 / 4;
}
 .div69 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 29 / 3 / 30 / 4;
}
 .div70 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 30 / 3 / 31 / 4;
}
 .div71 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 1 / 4 / 2 / 5;
}
 .div72 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 27 / 4 / 28 / 5;
}
 .div73 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 28 / 4 / 29 / 5;
}
 .div74 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 29 / 4 / 30 / 5;
}
 .div75 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 30 / 4 / 31 / 5;
}
 .div76 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 1 / 5 / 2 / 6;
}
 .div77 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 27 / 5 / 28 / 6;
}
 .div78 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 28 / 5 / 29 / 6;
}
 .div79 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 29 / 5 / 30 / 6;
}
 .div80 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 30 / 5 / 31 / 6;
}
 .div81 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 1 / 6 / 2 / 7;
}
 .div82 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 2 / 6 / 3 / 7;
}
 .div83 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 3 / 6 / 4 / 7;
}
 .div84 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 4 / 6 / 5 / 7;
}
 .div85 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 5 / 6 / 6 / 7;
}
 .div86 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 6 / 6 / 7 / 7;
}
 .div87 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 7 / 6 / 8 / 7;
}
 .div88 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 8 / 6 / 9 / 7;
}
 .div89 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 9 / 6 / 10 / 7;
}
 .div90 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 10 / 6 / 11 / 7;
}
 .div91 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 11 / 6 / 12 / 7;
}
 .div92 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 12 / 6 / 13 / 7;
}
 .div93 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 13 / 6 / 14 / 7;
}
 .div94 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 14 / 6 / 15 / 7;
}
 .div95 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 15 / 6 / 16 / 7;
}
 .div96 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 16 / 6 / 17 / 7;
}
 .div97 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 17 / 6 / 18 / 7;
}
 .div98 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 18 / 6 / 19 / 7;
}
 .div99 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 19 / 6 / 20 / 7;
}
 .div100 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 20 / 6 / 21 / 7;
}
 .div101 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 21 / 6 / 22 / 7;
}
 .div102 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 22 / 6 / 23 / 7;
}
 .div103 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 23 / 6 / 24 / 7;
}
 .div104 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 24 / 6 / 25 / 7;
}
 .div105 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 25 / 6 / 26 / 7;
}
 .div106 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 26 / 6 / 27 / 7;
}
 .div107 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 27 / 6 / 28 / 7;
}
 .div108 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 28 / 6 / 29 / 7;
}
 .div109 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 29 / 6 / 30 / 7;
}
 .div110 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 30 / 6 / 31 / 7;
}
 .div111 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 1 / 7 / 2 / 8;
}
 .div112 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 2 / 7 / 3 / 8;
}
 .div113 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 3 / 7 / 4 / 8;
}
 .div114 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 4 / 7 / 5 / 8;
}
 .div115 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 5 / 7 / 6 / 8;
}
 .div116 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 6 / 7 / 7 / 8;
}
 .div117 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 7 / 7 / 8 / 8;
}
 .div118 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 8 / 7 / 9 / 8;
}
 .div119 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 9 / 7 / 10 / 8;
}
 .div120 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 10 / 7 / 11 / 8;
}
 .div121 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 11 / 7 / 12 / 8;
}
 .div122 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 12 / 7 / 13 / 8;
}
 .div123 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 13 / 7 / 14 / 8;
}
 .div124 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 14 / 7 / 15 / 8;
}
 .div125 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 15 / 7 / 16 / 8;
}
 .div126 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 16 / 7 / 17 / 8;
}
 .div127 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 17 / 7 / 18 / 8;
}
 .div128 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 18 / 7 / 19 / 8;
}
 .div129 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 19 / 7 / 20 / 8;
}
 .div130 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 20 / 7 / 21 / 8;
}
 .div131 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 21 / 7 / 22 / 8;
}
 .div132 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 22 / 7 / 23 / 8;
}
 .div133 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 23 / 7 / 24 / 8;
}
 .div134 {
    background: #f7fbfc; 
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 24 / 7 / 25 / 8;
}
 .div135 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 25 / 7 / 26 / 8;
}
 .div136 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 26 / 7 / 27 / 8;
}
 .div137 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 27 / 7 / 28 / 8;
}
 .div138 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 28 / 7 / 29 / 8;
}
 .div139 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 29 / 7 / 30 / 8;
}
 .div140 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 30 / 7 / 31 / 8;
}
 .div141 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 31 / 7 / 32 / 8;
}
 .div142 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 1 / 8 / 2 / 9;
}
 .div143 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 2 / 8 / 3 / 9;
}
 .div144 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 3 / 8 / 4 / 9;
}
 .div145 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 4 / 8 / 5 / 9;
}
 .div146 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 5 / 8 / 6 / 9;
}
 .div147 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 6 / 8 / 7 / 9;
}
 .div148 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 7 / 8 / 8 / 9;
}
 .div149 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 8 / 8 / 9 / 9;
}
 .div150 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 9 / 8 / 10 / 9;
}
 .div151 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 10 / 8 / 11 / 9;
}
 .div152 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 11 / 8 / 12 / 9;
}
 .div153 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 12 / 8 / 13 / 9;
}
 .div154 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 13 / 8 / 14 / 9;
}
 .div155 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 14 / 8 / 15 / 9;
}
 .div156 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 15 / 8 / 16 / 9;
}
 .div157 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 16 / 8 / 17 / 9;
}
 .div158 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 17 / 8 / 18 / 9;
}
 .div159 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 18 / 8 / 19 / 9;
}
 .div160 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 19 / 8 / 20 / 9;
}
 .div161 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 20 / 8 / 21 / 9;
}
 .div162 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 21 / 8 / 22 / 9;
}
 .div163 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 22 / 8 / 23 / 9;
}
 .div164 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 23 / 8 / 24 / 9;
}
 .div165 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 24 / 8 / 25 / 9;
}
 .div166 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 25 / 8 / 26 / 9;
}
 .div167 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 26 / 8 / 27 / 9;
}
 .div168 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 27 / 8 / 28 / 9;
}
 .div169 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 28 / 8 / 29 / 9;
}
 .div170 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 29 / 8 / 30 / 9;
}
 .div171 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 30 / 8 / 31 / 9;
}
 .div172 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 31 / 8 / 32 / 9;
}
 .div173 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 1 / 9 / 2 / 10;
}
 .div174 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 2 / 9 / 3 / 10;
}
 .div175 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 3 / 9 / 4 / 10;
}
 .div176 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 4 / 9 / 5 / 10;
}
 .div177 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 5 / 9 / 6 / 10;
}
 .div178 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 6 / 9 / 7 / 10;
}
 .div179 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 7 / 9 / 8 / 10;
}
 .div180 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 8 / 9 / 9 / 10;
}
 .div181 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 9 / 9 / 10 / 10;
}
 .div182 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 10 / 9 / 11 / 10;
}
 .div183 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 11 / 9 / 12 / 10;
}
 .div184 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 12 / 9 / 13 / 10;
}
 .div185 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 13 / 9 / 14 / 10;
}
 .div186 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 14 / 9 / 15 / 10;
}
 .div187 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 15 / 9 / 16 / 10;
}
 .div188 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 16 / 9 / 17 / 10;
}
 .div189 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 17 / 9 / 18 / 10;
}
 .div190 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 18 / 9 / 19 / 10;
}
 .div191 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 19 / 9 / 20 / 10;
}
 .div192 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 20 / 9 / 21 / 10;
}
 .div193 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 21 / 9 / 22 / 10;
}
 .div194 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 22 / 9 / 23 / 10;
}
 .div195 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 23 / 9 / 24 / 10;
}
 .div196 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 24 / 9 / 25 / 10;
}
 .div197 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 25 / 9 / 26 / 10;
}
 .div198 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 26 / 9 / 27 / 10;
}
 .div199 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 27 / 9 / 28 / 10;
}
 .div200 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 28 / 9 / 29 / 10;
}
 .div201 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 29 / 9 / 30 / 10;
}
 .div202 {
    background: #f7fbfc;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 30 / 9 / 31 / 10;
}
 .div203 {
    background-color: #fefefe;
    align-content: center;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 0px;
    font-weight: 600;
    text-align: center;
    border:1px solid #e8ecec;
    grid-area: 31 / 9 / 32 / 10;
}
</style>
